import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';
import * as moment from 'moment';

import { CustomerService } from '../../../common/services/customer.service';
import { LicenseState } from '../../services/licensing.interfaces';

import { AuthService } from '../../../../core/modules/auth/services/auth.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LicensingRenewalDialogComponent } from '../licensing-renewal-dialog/licensing-renewal-dialog.component';
import { LocalStorageService } from '../../../../core/Services/local-storage.service';
import { environment } from '../../../../../environments/environment';
import { EntitlementLicensingService } from '../../services/entitlement-licensing.service';
import { Moment } from 'moment';


@Component({
  selector: 'app-licensing-bar',
  templateUrl: './licensing-bar.component.html',
  styleUrls: [ './licensing-bar.component.scss' ]
})
export class LicensingBarComponent implements OnInit, OnDestroy {

  public expiryMessage: string = '';
  public iconColor: string = 'white';
  public alertLevel: number = 1;
  public showRenew: boolean;
  public showLicenseTestPanel: boolean = environment.showLicenseTestPanel;
  public license$: Observable<{ licenseState: LicenseState, isSuperAdmin: boolean }>;


  private onDestroy$: Subject<void> = new Subject<void>();
  private RENEW_DIALOG_KEY_30DAYS: string = 'mute_renew_dialog_30DAYS';
  private RENEW_DIALOG_KEY_14DAYS: string = 'mute_renew_dialog_14DAYS';
  private MUTE_FREE_DIALOG_PROMPT: string = 'mute_free_dialog_prompt';
  private currentRenewDialogRef: MatDialogRef<LicensingRenewalDialogComponent>;

  constructor(
    private _authSvc: AuthService,
    private _customerSvc: CustomerService,
    private _licensingSvc: EntitlementLicensingService,
    private localStorageService: LocalStorageService,
    private _datePipe: DatePipe,
    private matDialog: MatDialog) {
    this.showRenew = false;
  }

  public openLicensingRenewalDialog(): void {

    if (this.currentRenewDialogRef) {
      this.currentRenewDialogRef.close();
    }

    this.currentRenewDialogRef = this.matDialog.open(LicensingRenewalDialogComponent, {
      width: '750px',
      height: '700px',
      panelClass: 'renew-modal'
    });

  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  public getExpiryDate(license: LicenseState): string {
    return this._datePipe.transform(license.license.subscriptions[license.primarySubscription].expiry, 'MM/dd/yyyy');
  }

  public ngOnInit(): void {
    this.license$ = combineLatest([ this._licensingSvc.checkLicense().pipe(
      debounceTime(1000),
      tap((license: LicenseState): void => {
        if (license.loaded) {
          this.refreshExpiryMessage(license);
          this.checkForRenewalDialog(license);
        }
      })),
      this._authSvc.isSuperAdmin$
    ]).pipe(
      takeUntil(this.onDestroy$),
      map(([ licenseState, isSuperAdmin ]: [LicenseState, boolean]): { licenseState: LicenseState, isSuperAdmin: boolean } => ({
        licenseState,
        isSuperAdmin
      }))
    );
  }

  private refreshExpiryMessage(licenseState: LicenseState): void {
    this.expiryMessage = `License for ${licenseState.license.domain} `;
    this.showRenew = false;

    if (licenseState.tier === 'lapsed') {
      this.iconColor = 'red';
      this.expiryMessage = `The ${licenseState.lastTierExpiration.type} license for ${licenseState.license.domain} has lapsed.`;
      this.showRenew = true;
    } else if (licenseState.tier === 'free') {
      this.iconColor = 'red';
      this.expiryMessage = `License for ${licenseState.license.domain} has expired and you are currently on a Free tier with limited actions`;
      this.showRenew = true;
    } else if (licenseState.expiresIn >= 60) {
      this.expiryMessage += `expires on ${this.getExpiryDate(licenseState)}`;
      this.iconColor = 'none';
    } else if (licenseState.expiresIn < 60 && licenseState.expiresIn >= 30) {
      this.expiryMessage += `expires on ${this.getExpiryDate(licenseState)}`;
      this.iconColor = 'white';
    } else if (licenseState.expiresIn >= 15 && licenseState.expiresIn < 30) {
      this.iconColor = 'amber';
      this.expiryMessage += `expires in ${licenseState.expiresIn} days (${this.getExpiryDate(licenseState)})`;
    } else if (licenseState.expiresIn >= 8 && licenseState.expiresIn < 15) {
      this.iconColor = 'red';
      this.expiryMessage += `expires in ${licenseState.expiresIn} days (${this.getExpiryDate(licenseState)})`;
    } else if (licenseState.expiresIn >= 0 && licenseState.expiresIn < 8) {
      this.iconColor = 'red';
      this.expiryMessage += `expires in ${licenseState.expiresIn} days (${this.getExpiryDate(licenseState)})`;
    }
  }


  private checkForRenewalDialog(licenseState: LicenseState): void {
    if (licenseState.tier === 'lapsed' || licenseState.tier === 'unlicensed') {
      this.openLicensingRenewalDialog();
    } else if (licenseState.tier === 'free') {
      // Check mute until time
      const muteUntil: string = this.localStorageService.get(this.MUTE_FREE_DIALOG_PROMPT);

      if (muteUntil) {
        const now: Moment = moment();
        const muteUntilMoment: Moment = moment(muteUntil);
        if (now.isAfter(muteUntilMoment)) {
          this.openLicensingRenewalDialog();
        }
      } else {
        this.openLicensingRenewalDialog();
      }
    } else if (licenseState.expiresIn >= 15 && licenseState.expiresIn <= 30) {
      if (this.localStorageService.get(this.RENEW_DIALOG_KEY_30DAYS) !== 'true') {
        this.openLicensingRenewalDialog();
      }
    } else if (licenseState.expiresIn >= 8 && licenseState.expiresIn < 15) {
      if (this.localStorageService.get(this.RENEW_DIALOG_KEY_14DAYS) !== 'true') {
        this.openLicensingRenewalDialog();
      }
    } else if (licenseState.expiresIn >= 0 && licenseState.expiresIn < 8) {
      this.openLicensingRenewalDialog();
    }
  }
}
