
export type FeatureScopeList = Record<string, string[] | Record<string, string[]>>
export interface OauthScopes {
  requiredScopes: string[];
  featureScopes: FeatureScopeList;
}

export const oauthScopes: OauthScopes = {
  requiredScopes: [
    'https://www.googleapis.com/auth/admin.directory.orgunit',
    'https://www.googleapis.com/auth/spreadsheets',
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/admin.directory.device.chromeos',
    'https://www.googleapis.com/auth/admin.directory.user.readonly',
  ],
  featureScopes: {
    automatedChromeProfileWipe: [ 'https://www.googleapis.com/auth/admin.reports.audit.readonly' ],
    telemetry: [ 'https://www.googleapis.com/auth/chrome.management.telemetry.readonly' ],
    telemetryAnalysis: [],
    deviceSearchActions: [],
    adminRoleLookup:[ 'https://www.googleapis.com/auth/admin.directory.rolemanagement.readonly' ],
    bulkActions: [],
    gridExports: {
      deviceSearch: []
  },
    deviceCacheSearch: [],
  }
};
