/* eslint-disable @typescript-eslint/typedef */
import { CdwSearchGridComponentState } from '@cdw-ae/common-ui-components-search-grid';
import { createAction, props } from '@ngrx/store';
import { DeviceQueryResponseRecord } from '../../modules/device/interfaces/deviceInfo.interfaces';
import { ComponentId } from './interface/component-id.interface';
// export const AppComponentStateActions = createActionGroup({
//   source: 'AppComponentState',
//   events: {
//     'Load AppComponentStates': emptyProps(),
    
    
//   }
// });

export const loadAppComponentStates = createAction('[AppComponentState] Load AppComponentStates');

export const saveComponentState = createAction(
  '[AppComponentState] saveComponentState',
  props<{ componentId: ComponentId; componentState: CdwSearchGridComponentState<DeviceQueryResponseRecord> }>()
);
