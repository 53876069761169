<div mat-dialog-content class="gap-[24px] flex flex-col justify-start items-center">

  <img src="{{dialogData.tool.logo}}" alt="{{dialogData.tool.name}} Logo" height="120px">

  <div class="license-info-panel flex flex-col justify-start items-center" [ngClass]="{'alert-red' : dialogData.alertLevel === 2, 'alert-strong' : dialogData.alertLevel === 2}">
    <div [ngClass]="{'alert-strong' : dialogData.alertLevel === 2}" class="flex flex-row justify-center items-center gap-[12px]">
      <mat-icon *ngIf="dialogData.showIcon" class="icon-20" [ngClass]="{'alert-red' : dialogData.alertLevel === 2}">warning</mat-icon>
      <div [innerHTML]="dialogData?.expiryMessage | safeHtml" class="text-align-center mb-4"></div>
    </div>
    <div *ngIf="dialogData.displayDate">{{dialogData?.expireDate}}</div>
  </div>

  <div class="license-info-block flex flex-col justify-start items-stretch">
    <p>Did you have questions about your quote? <span *ngIf="dialogData.showUpgrade">Did you want to upgrade to Gopher for Chrome Premium?</span>
      If so, get in touch with someone below...</p>
    <div class="flex flex-row justify-center items-center">
      <button class="renew-button" [ngClass]="{disabled: !(isAdmin$ | async)}" [disabled]="!(isAdmin$ | async)" mat-raised-button (click)="contactAccounts(dialogData.domain)">
        <span [matTooltip]="!(isAdmin$ | async)?'This is only available to Super admins':null">CONTACT US</span>
      </button>
    </div>
  </div>

  <div class="license-info-block flex flex-col justify-start items-stretch">
    <p>
      Please note, if your trial does not move to a paid license on or before the above expiration date, your data will
      be deleted and your trial will move to a FREE Gopher for Chrome license.
      If you would like to delete your data sooner, please contact support.</p>
    <div class="flex flex-row justify-center items-center">
      <button class="renew-button" [ngClass]="{disabled: !(isAdmin$ | async)}" [disabled]="!(isAdmin$ | async)" mat-raised-button (click)="requestSupport(dialogData.domain)">
        <span [matTooltip]="!(isAdmin$ | async)?'This is only available to Super admins':null">CONTACT SUPPORT</span>
      </button>
    </div>
  </div>

</div>

<div [ngClass]="{'flex justify-between items-center': dialogData.footerAlign === 'space-between center', 'flex justify-end items-center': dialogData.footerAlign === 'end center'}" mat-dialog-actions>
  <mat-checkbox *ngIf="dialogData.controls.showReminderCheckbox" (change)="toggleReminder($event)" [(ngModel)]="muteNotification">Do not show me this again</mat-checkbox>
  <div *ngIf="dialogData.showDataDeletionMessage">
    <span class="small-strong">** Please note, data is deleted 90 days after the license expiration date.</span>
  </div>
  <button *ngIf="!dialogData.controls.showLogout" mat-raised-button class="grey-button" (click)="closeDialog()">{{muteNotification ? 'SAVE & CLOSE' : 'CLOSE'}}</button>
  <button *ngIf="dialogData.controls.showLogout" mat-raised-button class="grey-button" (click)="logout()">LOG OUT</button>
</div>
