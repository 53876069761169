import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';

import { Subject, takeUntil } from 'rxjs';
import { DeviceInfoService } from 'src/app/modules/device/services/device-info/device-info.service';
import { GbSupportService } from 'src/app/modules/gopher-buddy-extension/services/gb-support.service';

import { DeviceInfo } from '../../../models/device-info.model';

@Component({
  selector: 'g4c-device-usage',
  templateUrl: './device-usage.component.html',
  styleUrls: [ './device-usage.component.scss' ]
})
export class DeviceUsageComponent implements OnInit, OnDestroy {
  public selectedDevice : DeviceInfo;
  public activeTimeColumns: string[] = [ 'date', 'activeTime' ];
  public recentUserColumns: string[] = [ 'type', 'email' ];
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private gbSupportService: GbSupportService,
    private deviceInfoService: DeviceInfoService) {}

    public ngOnDestroy(): void {
      this.onDestroy$.next();
    }

  public ngOnInit(): void {
    this.deviceInfoService.selectedDevice$.pipe(takeUntil(this.onDestroy$)).subscribe((device) => {
      if (device) {
        device.deviceLookup.activeTimeRanges = device.deviceLookup.activeTimeRanges.sort((a,b): number => {
          if (a.date < b.date) return 1;
          return -1;
        });
      this.selectedDevice = device;
      }
    })
  }

  public getActiveTime(activeTime : number): undefined | number {
    if (!activeTime){
      return;
    }
    return Math.floor(activeTime / 60000);
  }

  public validateDate(date : string) : string {
    if (date === '1970-01-01T00:00:00.000Z'){
      return null;
    }
    return date;
  }
}
