<div mat-dialog-content class="gap-[24px] flex flex-col justify-start items-center">

  <img src="{{dialogData.tool.logo}}" alt="{{dialogData.tool.name}} Logo" height="120px">

  <div class="license-info-panel flex flex-col justify-start items-center" [ngClass]="{'alert-red' : dialogData.alertLevel === 2, 'alert-strong' : dialogData.alertLevel === 2}">
    <div [ngClass]="{'alert-strong' : dialogData.alertLevel === 2}" class="flex flex-row justify-center items-center gap-[12px]">
      <mat-icon *ngIf="dialogData.showIcon" class="icon-20" [ngClass]="{'alert-red' : dialogData.alertLevel === 2}" >warning</mat-icon>
      <div [innerHTML]="dialogData?.expiryMessage | safeHtml" class="text-align-center mb-4"></div>
    </div>
    <div *ngIf="dialogData.displayDate">{{dialogData?.expireDate}}</div>
  </div>

  <div *ngIf="dialogData.showUpgrade" class="license-info-block flex flex-col justify-start items-center">
    <p>Did you want more reporting powers that include user sessions that capture username, local and public IP address, start time, and session duration in minutes? If so, upgrade now to Gopher for Chrome Premium!</p>
    <button class="renew-button"  [ngClass]="{disabled: !(isAdmin$ | async)}" [disabled]="!(isAdmin$ | async)" mat-raised-button (click)="requestUpgrade()">
      <span [matTooltip]="!(isAdmin$ | async)?'This is only available to Super admins':null">UPGRADE NOW</span>
    </button>
  </div>

  <div *ngIf="dialogData.displayInfoBlock" class="license-info-block gap-[5px] flex flex-col justify-start items-center" [ngClass]="{'alert-red' : dialogData.alertLevel > 0}">
    <div class="flex flex-row justify-start items-center gap-[12px]">
      <mat-icon class="icon-20 alert-red">warning</mat-icon>
      <span class="mb8">If your license is not renewed within 90 days from the above expiration date your license will revert to a FREE Gopher for Chrome license</span>
    </div>
    <button class="renew-button" mat-raised-button (click)="goTo('learnMore')">
      <span>LEARN MORE</span>
    </button>
  </div>

  <div class="button-bar flex flex-row justify-around">
    <button *ngIf="dialogData.controls.showSupportButton" [ngClass]="{disabled: !(isAdmin$ | async)}" [disabled]="!(isAdmin$ | async)" class="renew-button" mat-raised-button (click)="requestSupport(dialogData.domain)">
      <span [matTooltip]="!(isAdmin$ | async)?'This is only available to Super admins':null">CONTACT SUPPORT</span>
    </button>
  </div>

</div>

<div [class]="dialogData.showFooterLeft ? 'flex justify-between items-center' : 'flex justify-end items-center'" mat-dialog-actions>
  <mat-checkbox *ngIf="dialogData.controls.showReminderCheckbox" (change)="toggleReminder($event)" [(ngModel)]="muteNotification">Do not show me this again</mat-checkbox>
  <div *ngIf="dialogData.showDataDeletionMessage">
    <span class="small-strong">** Please note, data is deleted 90 days after the license expiration date.</span>
  </div>
  <div>
    <button *ngIf="dialogData.controls.showRenewButton"  [ngClass]="{disabled: !(isAdmin$ | async)}" [disabled]="!(isAdmin$ | async)" class="renew-button" mat-raised-button (click)="requestRenewal()">
      <span [matTooltip]="!(isAdmin$ | async)?'This is only available to Super admins':null">START YOUR RENEWAL</span>
    </button>
    <button *ngIf="!dialogData.controls.showLogout" mat-raised-button class="grey-button" (click)="closeDialog()">{{muteNotification ? 'SAVE & CLOSE' : 'CLOSE'}}</button>
  </div>
</div>
