import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { replaceValues } from 'src/app/core/utilities/utilities';

import { environment } from '../../../../environments/environment';
import {
  DeviceInfo,
  GBSessionResponse,
} from '../../common/models/device-info.model';
import { DeviceTelemetry } from '../../common/models/device-telemetry.model';
import { DelegatedDeviceTelemetryResponse } from '../../device/interfaces/deviceInfo.interfaces';
import { DeviceInfoRequest } from '../models/supportRequests.models';

@Injectable({
  providedIn: 'root'
})
export class GbSupportService {

  private deviceInfoRequest: DeviceInfoRequest;

  constructor(private httpClient: HttpClient) {}

  public getDeviceInfo(): Observable<DeviceInfo> {
    const url: string = replaceValues(environment.gopher_buddy_api.routes.deviceInfo, {
      id: this.deviceInfoRequest.device,
      apiToken: environment.gopher_buddy_api.apiKey,
      domain: this.deviceInfoRequest.domain,
      userEmail: this.deviceInfoRequest.email
    });

    return this.httpClient.get<DeviceInfo>(url);
  }

  public getDeviceTelemetry(): Observable<DeviceTelemetry> {
    const url: string = replaceValues(environment.gopher_buddy_api.routes.deviceTelemetry, {
      id: this.deviceInfoRequest.device,
      apiToken: environment.gopher_buddy_api.apiKey,
      domain: this.deviceInfoRequest.domain,
      userEmail: this.deviceInfoRequest.email
    });

    return this.httpClient.get<DelegatedDeviceTelemetryResponse>(url).pipe(map((raw: DelegatedDeviceTelemetryResponse): DeviceTelemetry => new DeviceTelemetry(raw.result.deviceTelemetry)));
  }

  public getGopherBuddySessionData(): Observable<GBSessionResponse> {
    const url: string = replaceValues(environment.gopher_buddy_api.routes.sessionInfo, {
      id: this.deviceInfoRequest.device,
      apiToken: environment.gopher_buddy_api.apiKey,
      domain: this.deviceInfoRequest.domain,
      userEmail: this.deviceInfoRequest.email
    });
    return this.httpClient.get<GBSessionResponse>(url);
  }

  public setDeviceInfoRequest(sessionRequest: DeviceInfoRequest): void {
    this.deviceInfoRequest = sessionRequest;
  }
}
