import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LocalStorageService } from '../../../../../../core/Services/local-storage.service';
import { DatePipe } from '@angular/common';
import { AuthService } from '../../../../../../core/modules/auth/services/auth.service';
import { LicensingBaseDialogComponent } from '../base/licensing-base-dialog.component';
import { DialogData, LicenseState } from '../../../../services/licensing.interfaces';
import { LicensingRenewalDialogComponent } from '../../licensing-renewal-dialog.component';
import { CustomAnalyticEventsService } from '../../../../../../core/Services/customAnalyticEvents.service';
import { EntitlementLicensingService } from '../../../../services/entitlement-licensing.service';

@Component({
  selector: 'app-licensing-consultant-dialog',
  templateUrl: './licensing-consultant-dialog.component.html',
  styleUrls: [ '../base/licensing-base-dialog.component.scss' ]
})
export class LicensingConsultantDialogComponent extends LicensingBaseDialogComponent implements OnInit, OnDestroy {

  constructor( public _firebaseAnalytics: CustomAnalyticEventsService,
    public _authSvc: AuthService,
    public licensingService: EntitlementLicensingService,
    public localStorageService: LocalStorageService,
    public _datePipe: DatePipe)  {
    super(_firebaseAnalytics,  _authSvc, licensingService, localStorageService,  _datePipe);
  }

  public forceLicenseRecheck(): void {
    this.recheckLicense();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.checkMuteStatus();
    this.buildTemplateData(this.data.licenseState);
  }

  private buildTemplateData(licenseState: LicenseState): void {
    const expiryMessage: string = this.updateExpiryMessage(licenseState, `Your license for ${licenseState.license.domain}`);

    const alertLevel: number = (licenseState.expiresIn > 7 && licenseState.expiresIn <= 30) ? 1 : 2;
    const showDataDeletionMessage: boolean = (licenseState.expiresIn < 0);
    const showFooterLeft: boolean = (licenseState.expiresIn > 7 || showDataDeletionMessage);
    const showSupportButton: boolean = (licenseState.expiresIn <= 14);
    const showRenewButton: boolean = (licenseState.expiresIn <= 30);
    const displayInfoBlock: boolean = (licenseState.expiresIn <= 30);
    const showReminderCheckbox: boolean = (licenseState.expiresIn <= 30 && licenseState.expiresIn > 7)

    this.dialogData = {
      domain : licenseState.license.domain,
      expireDays : licenseState.expiresIn,
      expireDate : this.getExpiryDate(licenseState),
      displayDate: true,
      expiryMessage,
      showIcon: (alertLevel > 0),
      controls: {
        showSupportButton,
        showRenewButton,
        showReminderCheckbox,
        showRecheckLicenseButton: false,
        showLogout: false
      },
      displayInfoBlock,
      showDataDeletionMessage,
      alertLevel,
      showFooterLeft,
      showUpgrade: false,
      tool: this.getTool(licenseState.primarySubscription),
    };
  }
}
