import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import * as moment from 'moment';
import {
  BehaviorSubject,
  Observable,
  Observer,
} from 'rxjs';
import {
  filter,
  switchMap,
  tap,
} from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import {
  AuthService,  
} from '../../../core/modules/auth/services/auth.service';
import {  
  DBUser,
} from '../../../core/modules/auth/services/auth.interfaces';
import { BatteryHealthSummaryResponse } from '../components/battery-health-summary.model';
import {
  Customer,
  Features,
  State,
  Status,
} from '../status';
import { DeviceStates } from '../components/device-states';

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  public cacheStatus$: Observable<Customer>;
  public deviceStates$: BehaviorSubject<DeviceStates[]> = new BehaviorSubject<DeviceStates[]>([]);
  public cacheState: State;
  public telemetryState: State;
  public devicesPreAggregationState: Status;
  public features: Features;

  private customerId: string;

  constructor(
    public userAuth: AuthService,
    public afCF: AngularFireFunctions,
    private afs: AngularFirestore,
    private http: HttpClient
  ) {
    // when the db user changes i.e. the auth changes, lets re get the user info
    this.userAuth.dbUser$
      .subscribe(
        (user: DBUser): void => {
          if (user) {
            this.cacheStatus$ = this.afs
              .doc(`customers/${user.profile?.customerId}`)
              .valueChanges()
              .pipe(
                tap((doc: Customer): void => {
                  if (doc) {
                    this.customerId = doc.customerId;
                    if (
                      !this.cacheState ||
                      (this.cacheState?.lastUpdated?.toMillis() ?? 0) < (doc.cacheState?.lastUpdated?.toMillis() ?? 0) ||
                      (!this.cacheState?.end && doc.cacheState?.end) ||
                      (this.cacheState?.end?.toMillis() ?? 0) < (doc.cacheState?.end?.toMillis() ?? 0)
                    )  {
                      this.cacheState = doc.cacheState;
                    }
                    this.telemetryState = doc.telemetryState;
                    this.devicesPreAggregationState = doc?.devicesPreAggregationState?.status || Status.DISABLED;
                    this.features = doc.features;
                  }
                })
              );
          }
        }
      );
  }

  public triggerSync(): void {
    if (!this.cacheState) {
      this.cacheState = {} as any; // Initialise properly
    }

    this.cacheState.status = Status.RUNNING;

    const url: string = `${environment.functions_base_url}/queue-refresh`;

    const httpOptions: { headers: HttpHeaders } = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http.post(url, { customerId: this.customerId }, httpOptions).subscribe();
  }

  public async cancelRefresh(): Promise<void> {
    await this.afCF.httpsCallable('queue-cancelDomainSync')({ customerId: this.customerId }).toPromise();
  }

  public async disable(): Promise<void> {
    await this.afCF.httpsCallable('queue-disable')({ email: this.userAuth.user.email, customerId: this.customerId }).toPromise();
  }


  public getBatteryHealthSumary(): Observable<BatteryHealthSummaryResponse> {
    return this.userAuth.dbUser$
      .pipe(
        filter((dbUser: DBUser): boolean => !!dbUser?.profile),
        switchMap((): Observable<BatteryHealthSummaryResponse> => this.afCF.httpsCallable('insights-getBatteryHealthSummary')({})
        )
      );
  }

  public generatePoorBatteryHealthReport(): Observable<any> {
    return this.userAuth.dbUser$
      .pipe(
        filter((dbUser: DBUser): boolean => !!dbUser?.profile),
        switchMap((user: DBUser): Observable<any> => {
          const now: string = moment().format('YYYY-MM-DD HH:mm:ss');
          const result: Observable<any> = this.afCF.httpsCallable('devices-createReportSheets')({
            customerId: user?.profile?.customerId,
            email: user?.profile?.email,
            spreadsheetFileName: `Gopher for Chrome Report - ${now}`,
            reportType: 'POOR_BATTERY_HEALTH',
            reports: [ 'GOPHER_FOR_CHROME_REPORTS/POOR_BATTERY_HEALTH', 'GOPHER_FOR_CHROME_REPORTS/OU_BATTERY_HEALTH_REPORT' ],
          });
          return result;
        })
      )
  }

  public getDeviceStateAnalysis(burstCache: boolean = false): Observable<any> {
    return  this.userAuth.dbUser$
      .pipe(
        switchMap((user: DBUser): Observable<any> => {
          if (user && user.profile) {
            return  this.afCF.httpsCallable('devices-getDeviceStateAnalysis')
            ({ customerId: user.profile.customerId, userId: user.profile.id, burstCache });
          }
          return new Observable(
            (observer: Observer<any>): void => observer.next(false)
          );
        })
      );
  }


}
