import firebase from 'firebase/compat/app';

export enum Status {
  RUNNING = 'running',
  DISABLED = 'disabled',
  COMPLETE = 'complete'
}

export interface State {
  status: Status;
  deviceCount: number;
  end: firebase.firestore.Timestamp;
  lastDeviceCount: number;
  lastUpdated: firebase.firestore.Timestamp;
  maxDeviceCount: number;
  start: firebase.firestore.Timestamp;
}

export interface Features {
  automatedChromeProfileWipe?: boolean;
  deviceCacheSearch?: boolean;
  deviceSearchActions?: boolean;
  bulkActions?: boolean;
  gridExports?: {
    deviceSearch?: boolean;
  };
  telemetry?: boolean;
  adminRoleLookup?: boolean;
}

export interface Customer  {
  domain: string;
  cacheState: State;
  isLicensed: boolean;
  designatedAdmin: string;
  customerId: string;
  licenseState: {
    [key: string]: {
      id: number;
      key: string;
      expiry: string;
      bundle: string;
      handle: string;
      plan: {
        name: string;
        timestamp: string;
      }
    }
  };
  TOS: string;
  features?: Features;
  telemetryState: State;
  devicesPreAggregationState?: { status: Status, lastUpdated?: firebase.firestore.Timestamp };
  refreshTokenLastUpdate: firebase.firestore.Timestamp
}

