import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, filter, map, Observable, switchMap, } from 'rxjs';
import { AuthService} from '../../../core/modules/auth/services/auth.service';
import { DBUser } from '../../../core/modules/auth/services/auth.interfaces';
import { IOrganizationUnit, IOrganizationUnitsResponse } from '../models/organizational-unit.model';
import firebase from 'firebase/compat/app';
import { environment } from 'src/environments/environment';

// It appears this has some overlap with the Customer interface definition in src/app/modules/status/status.ts - should these be merged? Looks like the only place this definition is used outside of this file is src/app/modules/automations-admin/components/profile-wipe-table/profile-wipe-table.component.ts
export interface Customer {
  features?: {
    automatedChromeProfileWipe?: boolean;
    deviceSearchActions?: boolean;
    telemetry?: boolean;
    telemetryAnalysis?: boolean;
  };
  cacheState?: {
    lastUpdated?: firebase.firestore.Timestamp;
  }
  id?: number;
  customerId?: string;
  name?: string;
  designatedAdmin?: string;
  domain?: string;
  loading: boolean;
}

@Injectable()
export class CustomerService {

  private httpOptions: { headers: HttpHeaders };
  private REQUIRED_SCOPES: string[] = [
    'https://www.googleapis.com/auth/admin.directory.orgunit.readonly',
  ]

  private customer: Customer = {
    loading: true
  }

  private customer$: BehaviorSubject<Customer>;
  public customerOUs$: BehaviorSubject<IOrganizationUnit[]> = new BehaviorSubject<IOrganizationUnit[]>(null);

  constructor(
    private http: HttpClient,
    private firestore: AngularFirestore,
    private router: Router,
    private authService: AuthService,
    private firebaseFunctions: AngularFireFunctions) {

    this.customer$ = new BehaviorSubject(this.customer);

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    this.getAllCustomerOUs();
  }

  public getCurrentCustomer(): Observable<Customer> {
    return this.customer$.asObservable();
  }

  public getActiveUserCustomer(): Observable<Customer> {
    return this.authService.getActiveUser().pipe(switchMap(activeUser => {
      return this.firestore.doc(`customers/${activeUser.profile.customerId}`).get();
    }), map((documentSnapshot): Customer => {
      return documentSnapshot.data() as Customer;
    }))
  }

  public getAllCustomerOUs() : Observable<IOrganizationUnitsResponse> {
    try {
    return combineLatest([
      this.authService.getActiveUser(),
    ]).pipe(
      filter(([user]: [DBUser]): boolean => {
        return (user.isLoaded)}),
      switchMap(([user]: [DBUser]): Observable<any> => { 
        return this.firebaseFunctions.httpsCallable('customer-getOUs')( 
        {
            'customerId': user.profile.customerId,
            'email': user.profile.email,
          }
          ).pipe(map((response : IOrganizationUnitsResponse) : any => {
              this.customerOUs$.next(response.orgUnits);
              return response;
            })
      )})
    )}
    catch(error) {
      console.log(error);
    }
  }

  public getCustomerOusFromApi(): Observable<object> {
    return this.http.get(`${environment.urls.services.customerApi}/api/orgunit`)
  }
}
