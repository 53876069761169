import { CdwSearchGridComponentState } from '@cdw-ae/common-ui-components-search-grid';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { DeviceQueryResponseRecord } from '../../modules/device/interfaces/deviceInfo.interfaces';
import * as AppComponentStateActions from './app-component-state.actions';
import { ComponentId } from './interface/component-id.interface';

export const appComponentStateFeatureKey: string = 'appComponentState';

export interface AppComponentState {
  deviceCacheSearch?: CdwSearchGridComponentState<DeviceQueryResponseRecord>;
  apiSearch?: CdwSearchGridComponentState<DeviceQueryResponseRecord>;
}

export const initialState: AppComponentState = {

};

export const reducer: ActionReducer<AppComponentState, Action> = createReducer(
  initialState,
  on(AppComponentStateActions.loadAppComponentStates, (state: AppComponentState): AppComponentState => state),
  on(AppComponentStateActions.saveComponentState, (state: AppComponentState, { componentId, componentState }: {componentId: ComponentId, componentState: CdwSearchGridComponentState<DeviceQueryResponseRecord>}): AppComponentState => ({
    ...state,
    [componentId]: { ...componentState, initialPageLoad: true },
  }))
);

export const appComponentStateReducer = (state: AppComponentState | undefined, action: Action): AppComponentState => reducer(state, action);


// export const appComponentStateFeature = createFeature({
//   name: appComponentStateFeatureKey,
//   reducer,
// });

