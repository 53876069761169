import { CdwSearchGridComponentState } from '@cdw-ae/common-ui-components-search-grid';
import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { DeviceQueryResponseRecord } from '../../modules/device/interfaces/deviceInfo.interfaces';
import * as fromAppComponentState from './app-component-state.reducer';
import { ComponentId } from './interface/component-id.interface';

// eslint-disable-next-line @typescript-eslint/typedef
export const selectAppComponentState = createFeatureSelector<fromAppComponentState.AppComponentState>(
  fromAppComponentState.appComponentStateFeatureKey
);


export const selectComponentState = (props: {
  componentId: ComponentId;
}): MemoizedSelector<
  fromAppComponentState.AppComponentState,
  CdwSearchGridComponentState<DeviceQueryResponseRecord> | undefined,
  (currentState: fromAppComponentState.AppComponentState) => CdwSearchGridComponentState<DeviceQueryResponseRecord> | undefined
> =>
  createSelector(
    selectAppComponentState,
    (state: fromAppComponentState.AppComponentState): CdwSearchGridComponentState<DeviceQueryResponseRecord> | undefined =>
      state[props.componentId]
  );