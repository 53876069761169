import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserEncodingService {
  private encodeUserCallable: (data: any) => Observable<string>;  

  constructor(private acf: AngularFireFunctions) {
    this.encodeUserCallable = this.acf.httpsCallable('app-encodeUser');
  }

  public encodeUser(email: string): Observable<string> {
    return this.encodeUserCallable({ data: email });
  }
}
