  import { Injectable } from '@angular/core';
  import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
  import {
    Observable,
  } from 'rxjs';
  
  @Injectable({
    providedIn: 'root'
  })
  export class JobStatusService {  
    constructor(
      private afs: AngularFirestore,
    ) {}

    public getActiveJobs(customerId: string): Observable<unknown[]> {
        return this.afs.collection('generic-jobs', ((query: Query): Query => query.where('customerId', '==', customerId).orderBy('lastUpdate', 'desc'))).valueChanges({ idField: 'jobId' })
    }

    public dismissJob(jobId: string): Promise<void> {
      return this.afs.doc(`generic-jobs/${jobId}`).delete();
    }
  }