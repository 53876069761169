import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/core/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'g4c-confirm-deprovision-dialog',
  templateUrl: './confirm-deprovision-dialog.component.html',
  styleUrls: ['./confirm-deprovision-dialog.component.scss'],
})
export class ConfirmDeprovisionDialogComponent implements OnInit {
  public selectReasonForm: FormGroup = new FormGroup({
    selectedReason: new FormControl(null),
  });

  constructor(public dialogRef: MatDialogRef<ConfirmDeprovisionDialogComponent>, private matDialog: MatDialog) {}

  public confirmReason(): void {
    this.matDialog
      .open(ConfirmationDialogComponent, {
        data: {
          message:
            '<p>Depending on the reason for deprovisioning, the ChromeOS license attached to this device may also be retired. <br><br><strong>Are you sure you want to continue?</strong></p>',
        },
        panelClass: 'ait-confirmation',
        maxHeight: '100%',
        width: '540px',
        maxWidth: '100%',
        disableClose: true,
        hasBackdrop: true,
      })
      .afterClosed()
      .pipe(first())
      .subscribe((confirmed: boolean): void => {
        if (confirmed) {
          this.dialogRef.close(this.selectReasonForm.controls.selectedReason.getRawValue());
        }
      });
  }

  public cancelEdit(): void {
    this.dialogRef.close();
  }
  public ngOnInit(): void {}
}
