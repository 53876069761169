import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LocalStorageService } from '../../../../../../core/Services/local-storage.service';
import { DatePipe } from '@angular/common';
import { AuthService } from '../../../../../../core/modules/auth/services/auth.service';
import { LicensingBaseDialogComponent, ToolOptions } from '../base/licensing-base-dialog.component';
import { DialogData, LicenseState } from '../../../../services/licensing.interfaces';
import { LicensingRenewalDialogComponent } from '../../licensing-renewal-dialog.component';
import { CustomAnalyticEventsService } from '../../../../../../core/Services/customAnalyticEvents.service';
import { EntitlementLicensingService } from '../../../../services/entitlement-licensing.service';

@Component({
  selector: 'app-licensing-trial-dialog',
  templateUrl: './licensing-trial-dialog.component.html',
  styleUrls: [ '../base/licensing-base-dialog.component.scss' ]
})
export class LicensingTrialDialogComponent extends LicensingBaseDialogComponent implements OnInit, OnDestroy {

  constructor( public _firebaseAnalytics: CustomAnalyticEventsService,
    public _authSvc: AuthService,
    public licensingService: EntitlementLicensingService,
    public localStorageService: LocalStorageService,
    public _datePipe: DatePipe)  {
    super(_firebaseAnalytics,  _authSvc, licensingService, localStorageService,  _datePipe);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.checkMuteStatus();
    this.buildTemplateData(this.data.licenseState);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private buildTemplateData(licenseState: LicenseState): void {

    const expiryMessage: string = this.updateExpiryMessage(licenseState,`Your trial license for ${licenseState.license.domain}`);
    const alertLevel: number = this.getAlertLevel(licenseState);

    const tool: ToolOptions = this.getTool(licenseState.primarySubscription);

    this.dialogData = {
      domain: licenseState.license.domain,
      expireDays: licenseState.expiresIn,
      expireDate: this.getExpiryDate(licenseState),
      displayDate: licenseState.details === 'ACTIVE',
      expiryMessage,
      alertLevel,
      showIcon: (alertLevel > 0),
      controls: {
        showSupportButton: false,
        showRenewButton: true,
        showReminderCheckbox: (licenseState.expiresIn <= 30 && licenseState.expiresIn > 7),
        showRecheckLicenseButton: true,
        showLogout: false
      },
      tool,
      showUpgrade: tool.name === 'Gopher for Chrome',
      displayInfoBlock: true,
      showDataDeletionMessage: (licenseState.expiresIn < 0),
      footerAlign: ((licenseState.expiresIn <= 30 && licenseState.expiresIn > 7) || licenseState.expiresIn < 0) ? 'space-between center' : 'end center'
    };
  }
}
