import { AgGridModule } from '@ag-grid-community/angular';
import { LayoutModule } from '@angular/cdk/layout';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FirebaseApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import {
  AngularFirestoreModule,
  SETTINGS,
} from '@angular/fire/compat/firestore';
import {
  AngularFireFunctionsModule,
  ORIGIN as FUNCTIONS_ORIGIN,
  USE_EMULATOR,
} from '@angular/fire/compat/functions';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { Action, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Firestore } from 'firebase/firestore';

import { CDW_SAVE_COMPONENT_STATE_SERVICE_TOKEN } from '@cdw-ae/common-ui-components-search-grid';

import { environment } from '../environments/environment';
import { appComponentStateReducer } from './+state/app-component-state/app-component-state.reducer';
import { AppComponentStateService } from './+state/app-component-state/service/app-component-state.service';
import { G4CAppState } from './+state/app-state.interface';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorModule } from './core/error/error.module';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { FirebaseAuthInterceptor } from './core/interceptors/firebase-auth-interceptor/firebase-auth-interceptor';
import { AuthModule } from './core/modules/auth/auth.module';
import { CustomAnalyticEventsService } from './core/Services/customAnalyticEvents.service';
import { UserEncodingService } from './core/Services/user-encoding.service';
import { CoModule } from './modules/common/co.module';
import { DeviceInfoService } from './modules/device/services/device-info/device-info.service';
import { EntitlementLicensingService } from './modules/licensing/services/entitlement-licensing.service';
import { ToolLicensingModule } from './modules/licensing/tool-licensing.module';

@NgModule({
  declarations: [ AppComponent ],
  imports: [
    CoModule,
    BrowserModule,
    AgGridModule,
    AngularFireModule.initializeApp(environment.firebase),
    provideFirebaseApp((): FirebaseApp => initializeApp(environment.firebase)),
    provideFirestore((): Firestore => getFirestore()),
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFirestoreModule,
    AngularFirePerformanceModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    AuthModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    ErrorModule,
    ToolLicensingModule,
    StoreModule.forRoot<G4CAppState, Action>(
      {
        appComponentState: appComponentStateReducer,
      },
      {
        metaReducers: [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot({}),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
    UserEncodingService,
    EntitlementLicensingService,
    CustomAnalyticEventsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FirebaseAuthInterceptor,
      multi: true
    },
    {
      provide: USE_EMULATOR,
      useValue: environment.useLocalFunctions ? [ 'localhost', 5001 ] :  null,
    },
    {
      provide: SETTINGS,
      useValue: environment.useLocalFirestore
        ? {
          host: 'localhost:9001',
          ssl: false,
        }
        : undefined,
    },
    { provide: FUNCTIONS_ORIGIN, useFactory: (): undefined => undefined },
    { provide: CDW_SAVE_COMPONENT_STATE_SERVICE_TOKEN, useClass: AppComponentStateService },
    DeviceInfoService,
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {}
