import { GridOptions } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs';

import { AgGridState } from '@cdw-ae/angular-ui-cdw-ag-grid';
import { ContentType, G4CContentType, ProjectName, exportGrid, getGridStateFromAgGridOptions } from '@cdw-ae/components-export-wizard';


import { DBUser } from '../../../core/modules/auth/services/auth.interfaces';
import { AuthService } from '../../../core/modules/auth/services/auth.service';
import { AuditService } from '../../../core/Services/audit.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GridExportsService {
  private readonly projectName: ProjectName = 'G4C';

  private exportEndpoint: string = environment.cloudRunInstances.exports;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private getAccessToken: () => Promise<{ access_token: string }>;

  constructor(
    private auditService: AuditService,
    private authService: AuthService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    this.getAccessToken = async (): Promise<{access_token: string}> => {
      // console.log('called getAccessToken');
      const ac: {access_token: string} = await this.authService.getAuthForScopes([ 'https://www.googleapis.com/auth/drive.file' ]);
      // console.log(ac);
      return ac;
    };
  }

  public static getGridState(gridOptions: GridOptions, uniqueColumnName: string = 'id'): AgGridState {
    return gridOptions?.api && gridOptions?.columnApi ?
      getGridStateFromAgGridOptions(gridOptions, uniqueColumnName) :
      { columnOrder: [] };
  }

  public async showExportDialog(type: G4CContentType, gridState: AgGridState): Promise<void> {
    this.authService.getActiveUser().pipe(take(1)).subscribe(async (user: DBUser): Promise<void> => {
      const googleCustomerId: string = user?.profile?.customerId;
      const primaryEmail: string = user?.profile?.email;
      let googleUserId: string | undefined;
      try {
        googleUserId = JSON.parse(user?.profile?.id);
      } catch (err: unknown) {}
      const result: {
        success?: boolean;
        message?: string;
        exportId?: string;
      } = await exportGrid(
        this.dialog,
        this.snackBar,
        this.getAccessToken,
        this.exportEndpoint,
        this.projectName,
        type as ContentType,
        gridState,
        googleCustomerId,
        primaryEmail,
        googleUserId
      );
      this.auditService.logExportCreation('export', result, user);
    });
  }
}
