import { Injectable } from '@angular/core';
import {
  CanActivate,
} from '@angular/router';

import {
  Observable,
} from 'rxjs';

import {
  AuthService,
} from '../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminRouteguardService implements CanActivate {
  constructor(
    private userAuth: AuthService,
  ) {
  }

  public canActivate(): Observable<boolean> {
    return this.userAuth.isSuperAdmin$;
  }
}
