<div class="gb-data-panel flex flex-col">
    <div class="gb-data-desc">Captured events for this device from the past 30 days (max 100)</div>
    <div *ngIf="dataSource else loading">
      <div *ngIf="!isDataSourceEmpty() else noAuditEvents" class="mat-table-panel">
        <table [dataSource]="dataSource | slice : startIndex : endIndex" class="mat-elevation-z1" mat-table data-test="chrome-audit-events-table">
          <ng-container matColumnDef="eventType">
            <th *matHeaderCellDef mat-header-cell> Event Type</th>
            <td *matCellDef="let element" class="truncated-cell" mat-cell> {{element.eventType}} </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th *matHeaderCellDef mat-header-cell> Date</th>
            <td *matCellDef="let element" class="truncated-cell" mat-cell> {{element.date | date:'short'}} </td>
          </ng-container>

          <ng-container matColumnDef="reason">
            <th *matHeaderCellDef mat-header-cell> Reason</th>
            <td *matCellDef="let element" class="truncated-cell" mat-cell> {{element.reason}} </td>
          </ng-container>

          <ng-container matColumnDef="user">
            <th *matHeaderCellDef mat-header-cell> User</th>
            <td *matCellDef="let element" [matTooltip]="element.user" mat-cell>
              <div *ngIf="element.user else notSet" class="truncated-cell"> {{element.user}} </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th *matHeaderCellDef mat-header-cell> Description</th>
            <td *matCellDef="let element" [matTooltip]="element.description" mat-cell>
              <div *ngIf="element.description else notSet"> {{element.description}} </div>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="tableHeaders;" mat-header-row></tr>
          <tr *matRowDef="let row; columns: tableHeaders;" mat-row></tr>
        </table>
        <mat-paginator (page)="handlePage($event)" [length]="dataSource.length || 0" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page">
        </mat-paginator>
      </div>

      <ng-template #noAuditEvents>
        <div class="no-data-info-panel flex flex-col gap-2 justify-center items-center">
          <p>{{noAuditEventMessage}}</p>
          <div *ngIf="retryButtonHidden">
            <button (click)="provideAdditionalScope()" [hidden]="authorizeGoogleButtonHidden" mat-raised-button color="primary">Authorize</button>
          </div>
          <button (click)="retry()" [hidden]="retryButtonHidden" mat-raised-button color="primary">Retry</button>
        </div>
      </ng-template>
    </div>


    <ng-template #loading>
      <div class="loading-panel flex flex-col justify-center items-center">
        <mat-spinner></mat-spinner>
        <p>Loading event data...</p>
      </div>
    </ng-template>
  </div>

  <ng-template #notSet>
    <div class="missing-setting">-</div>
  </ng-template>
