<div class="top">
  <span>{{ data?.error?.title || 'An error occured' }}</span>
</div>
<div class="middle" [innerHTML]="data?.error?.body || ''">

</div>
<div class="bottom">
  <button mat-raised-button mat-dialog-close *ngIf="!data.disableCloseBtn" >Ok</button>
  <button mat-raised-button color="warn" mat-dialog-close (click)="logout()" *ngIf="data.enableLogoutBtn">Logout</button>
</div>
