<div mat-dialog-content class="gap-[24px] flex flex-col justify-start items-center">

  <img src="{{dialogData.tool.logo}}" alt="{{dialogData.tool.name}} Logo" height="120px">

  <div class="license-info-panel alert-red alert-strong flex flex-col justify-start items-center">
    <div class="flex flex-row justify-center items-center gap-[12px]">
      <mat-icon class="icon-20 alert-red">warning</mat-icon>
      <div>Whoops! It looks like {{dialogData.domain}} does not have a license for {{dialogData.tool.name}}.</div>
    </div>
    <button *ngIf="dialogData.controls.showRenewButton" class="renew-button" mat-raised-button (click)="goTo('learnMore')">LEARN MORE</button>
  </div>

  <div *ngIf="dialogData.displayInfoBlock" class="license-info-block flex flex-col justify-start items-center">
    <p>Did you want a free trial? If so, press the button below to start...</p>
    <button *ngIf="dialogData.controls.showRenewButton" [ngClass]="{disabled: !(isAdmin$ | async)}" [disabled]="!(isAdmin$ | async)"  class="renew-button" mat-raised-button (click)="requestQuote()">
      <span [matTooltip]="!(isAdmin$ | async)?'This is only available to Super admins':null">FREE TRIAL INQUIRY</span>
    </button>
  </div>
</div>

<div mat-dialog-actions class="flex justify-end items-center">
  <button mat-raised-button class="grey-button" (click)="logout()">LOG OUT</button>
</div>
