import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AuthService } from './services/auth.service';
import { SuperAdminRouteguardService } from './guards/SuperAdminRouteGuard/super-admin-route-guard.service';
import { LicenseGuard } from './guards/license-guard/license.guard';
import { OfflineTokenAuthDialogComponent } from './components/offline-token-auth-dialog/offline-token-auth-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { CoModule } from '../../../modules/common/co.module';

@NgModule({
  declarations: [
    OfflineTokenAuthDialogComponent
  ],
  imports: [ CommonModule, MatDialogModule, MatButtonModule, CoModule ],
  providers: [ AuthService, SuperAdminRouteguardService, LicenseGuard ]
})
export class AuthModule {
}
