<div class="title-bar">
    <h1 mat-dialog-title>
        Confirm deprovision reason
    </h1>
</div>
<mat-dialog-content [formGroup]="selectReasonForm">
    <p>
        Please provide a reason for deprovisioning.
        The ability to transfer licenses is defined by the <a href="https://support.google.com/chrome/a/answer/2717664" target="_blank">license policy</a>.
        Before your deprovision, learn when and why to use the different <a href="https://support.google.com/chrome/a/answer/3523633" target="_blank">deprovisioning reasons</a>.
    </p>
    <mat-radio-group class="reason-radio-group" formControlName="selectedReason" aria-label="Select deprovision reason">
        <mat-radio-button value="same_model_replacement">Same model replacement</mat-radio-button>
        <mat-radio-button value="different_model_replacement">Different model replacement</mat-radio-button>
        <mat-radio-button value="retiring_device">Retiring device</mat-radio-button>
        <mat-radio-button value="upgrade_transfer">Replacing CloudReady devices with Chromebooks within a year</mat-radio-button>
      </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions flex flex-row justify-end items-center">
    <button (click)="cancelEdit()" mat-flat-button color="warn">Cancel</button>
    <button (click)="confirmReason()" mat-flat-button color="primary">OK</button>    
 </mat-dialog-actions>
