import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { versions } from '../../../environments/versions';
import { LicenseState } from '../../modules/licensing/services/licensing.interfaces';
import { LogEventProperties } from '../modules/auth/services/auth.interfaces';

declare global {
  interface Window {
    gtag: any;
  }
}

export interface AnalyticsEventProperties {
  [key: string]: string | number;
}

interface UserProperties {
  uuid: string;
  customerId: string;
  domain: string;
  encodedUser?: string;
}

interface EncodeUserResult {
  message: string;
  error: boolean;
  results: string;
}

@Injectable({
  providedIn: 'root',
})
export class CustomAnalyticEventsService implements OnDestroy {
  private userProperties: UserProperties;
  private currentScreen: string;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private httpClient: HttpClient, private router: Router) {
    

    this.router.events
      .pipe(filter((ev: Event): boolean => ev instanceof NavigationEnd))
      .subscribe((ev: NavigationEnd): void => {
        const path: string =
          ev.url.indexOf('?') < 0 ? ev.url.slice(1, ev.url.length) : ev.url.slice(1, ev.url.indexOf('?'));
        this.currentScreen = path;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        window.gtag('event', 'current_view', { view_url: this.currentScreen });
      });
  }

  public setUserProperties(userProperties: UserProperties): void {    

        this.userProperties = {
          uuid: userProperties.uuid,
          customerId: userProperties.customerId,
          domain:userProperties.domain,
          encodedUser: userProperties.encodedUser,
        };

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        window.gtag('set', 'user_id', this.userProperties.encodedUser);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        window.gtag('set', 'user_properties', {
          app_name: 'Gopher for Chrome WebApp',
          app_version: versions.version,
          ...this.userProperties,
      });
  }

  public setUserLicenseState(licenseState: LicenseState): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window.gtag('set', 'user_properties', {
      ...this.userProperties,
      licensed: licenseState.licensed,
      bundle: licenseState.bundle,
      tier: licenseState.tier,
      primarySubscription: licenseState.primarySubscription,
      hasPremium: licenseState.hasPremium
  });
  }



   public logEvent(eventString: string, eventProperties?: AnalyticsEventProperties | LogEventProperties): void {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.gtag('event', eventString, {
        ...this.userProperties,
        ...eventProperties,
        view_url: this.currentScreen,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Analytics error', e);
    }
  
  }

  public getAnalyticUuid(email: string): Observable<string> {
    const url: string = `${environment.functions_base_url}/app-encodeUser`;
    const httpOptions: { headers: HttpHeaders } = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const payload: { data: { data: string } } = { data: { data: email } };
    return this.httpClient
      .post(url, payload, httpOptions)
      .pipe(map((response: EncodeUserResult): string => response.results));
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next(null);
  }
}
