import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { AuthService  } from '../../../core/modules/auth/services/auth.service';
import {  DBUser } from '../../../core/modules/auth/services/auth.interfaces';
import { License, LicenseState, LocalOverride, LicenseType, LicenseSyncResponse } from './licensing.interfaces';
import * as moment from 'moment';
import { filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CustomerService } from '../../common/services/customer.service';
import { environment } from '../../../../environments/environment';
import { Moment } from 'moment/moment';

export const bundleMap: {[key: string]: string} = {
  'ait-atu': 'Admin Tools Unlimited',
  'ait-gop': 'Gopher Pack'
}

export interface LicenseResponse {
  success: boolean;
  response: License;
}

interface LicenseExpiration {
  licensed: boolean;
  expiresIn: number;
  details: string;
  lastTierExpiration?: {
    type: 'paid' | 'trial';
    date: Date;
  };

}

const DEFAULT_LICENSE_STATE: LicenseState = {
  licensed: false,
  loaded: false,
  hasPremium: false,
  primarySubscription: 'CG',
  bundle: null
};


@Injectable({
  providedIn: 'root',
})
export class EntitlementLicensingService {
  private license$: BehaviorSubject<LicenseState> = new BehaviorSubject<LicenseState>({ ...DEFAULT_LICENSE_STATE });
  private httpOptions: { headers: HttpHeaders };
  private overrideStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private validLicenseTypes: LicenseType[] = [
    'paid',
    'lapsed',
    'consultant',
    'trial',
    'free'
  ];

  private triggerForcedRecheck$: Subject<void> = new Subject<void>();

  private localOverride$: BehaviorSubject<LocalOverride> = new BehaviorSubject<LocalOverride>({ subscriptions: {} });
  private lastEntitlementCheck: Date;
  private licenseSub: Subscription;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private customerService: CustomerService,
    private angularFirestore: AngularFirestore
  ) {
    this.authService.getLogoutTrigger()
      .subscribe(() => {
        this.license$.next({ ...DEFAULT_LICENSE_STATE })
      })
  }

  public triggerForcedRecheck(): void {
    this.triggerForcedRecheck$.next();
  }

  public getOverrideStatus(): Observable<boolean> {
    return this.overrideStatus$.asObservable();
  }

  public removeLocalOverride(): void {
    this.overrideStatus$.next(false);
    this.localOverride$.next({ subscriptions: {} });
  }

  public setLocalOverride(tool: string, planName: string, expiry: Date): void {
    this.overrideStatus$.next(true);
    this.localOverride$.next({
      subscriptions: {
        [tool]: {
          plan: {
            name: planName
          },
          expiry
        }
      }
    });
  }

  public checkLicense(): Observable<LicenseState> {
    this.getLicense();
    return this.license$.asObservable();
  }

  public getLicense(forceRefresh: boolean = false): void {
    if (!this.licenseSub) {
      this.licenseSub =
        this.triggerEntitlementRefresh().pipe(startWith(true))
          .pipe(
            takeUntil(this.authService.getLogoutTrigger()
              .pipe(tap((): void => {
                this.licenseSub = undefined
              }))),
            filter((refresh: boolean): boolean => refresh),
            switchMap((): Observable<LicenseState> => {
              if (!forceRefresh && this.license$.value.loaded) {
                return this.license$.pipe(
                  switchMap((licenseState: LicenseState): Observable<License> => this.mergeOverride(licenseState.license)),
                  switchMap((license: License): Observable<LicenseState> => of(this.buildState(license))));
              }
              return this.triggerForcedRecheck$.asObservable().pipe(
                startWith(true),
                map((): boolean => true),
                switchMap((): Observable<LicenseState> => this.fetchLicense().pipe(
                  switchMap((response: LicenseResponse): Observable<License> => this.mergeOverride(response.response)),
                  switchMap((license: License): Observable<LicenseState> => of(this.buildState(license)))
                )));
            })
          ).subscribe((licenseState: LicenseState): void => {
          this.license$.next(licenseState);
        });
    }
  }

  private calcLicenseExpiresIn(license: License, sku: string): LicenseExpiration {
    const returnState: LicenseExpiration = {
      licensed: false,
      expiresIn: 0,
      details: 'EXPIRED'
    };
    const now: Moment = moment();
    const expires: Moment = moment(license.subscriptions[sku].expiry).endOf('d');
    let licenseDiff: number = expires.diff(now, 'd');

    // Fixes if license is less then full day expired
    if (expires.diff(now, 'h') < 0 && (expires.diff(now, 'h') > -24)) {
      licenseDiff = -1;
    }

    returnState.licensed = this.validLicenseTypes.indexOf(license.subscriptions[sku].plan.name.toLowerCase()) > -1;
    returnState.expiresIn = licenseDiff;

    if (licenseDiff >= 0) {
      returnState.details = 'ACTIVE';
    } else {
      returnState.details = 'EXPIRED';
    }

    if (license.subscriptions[sku].plan.name === 'Lapsed') {
      returnState.lastTierExpiration =
        {
          type: 'paid',
          date: license.subscriptions[sku].plan.paid_ended_at
        };
    }

    if (license.subscriptions[sku].plan.name === 'Free') {
      returnState.lastTierExpiration = (license.subscriptions[sku].plan.paid_ended_at)
        ? { date: license.subscriptions[sku].plan.paid_ended_at, type: 'paid' }
        : { date: license.subscriptions[sku].plan.trial_ended_at, type: 'trial' };
    }

    return returnState;
  }

  private buildState(licenseResponse: License): LicenseState {
    const license: License = licenseResponse;
    const state: LicenseState = {
      licensed: false,
      loaded: true,
      hasPremium: false,
      bundle: null,
      license
    };

    if (![ 'Unlicensed', 'Lapsed', undefined ].includes(license.subscriptions?.CGP?.plan?.name)) {
      state.tier = license.subscriptions.CGP.plan.name.toLowerCase();
      state.hasPremium = true;
      state.primarySubscription = 'CGP';
      state.bundle = license.subscriptions.CGP.bundle; 
    } else {
      state.tier = license.subscriptions.CG.plan.name.toLowerCase();
      state.hasPremium = false;
      state.primarySubscription = 'CG';
      state.bundle = license.subscriptions.CG.bundle; 
    }
    Object.assign(state, this.calcLicenseExpiresIn(license, state.primarySubscription));
    return state;
  }


  private triggerEntitlementRefresh(): Observable<boolean> {
    return this.authService.getActiveUser().pipe(
      takeUntil(this.authService.getLogoutTrigger()),
      filter((appUser: DBUser): boolean => appUser?.isLoaded),
      switchMap((appUser: DBUser): Observable<LicenseSyncResponse> =>
        this.angularFirestore
          .doc<LicenseSyncResponse>(`/customers/${appUser.profile.customerId}/entitlements/licenseSync`)
          .valueChanges().pipe(takeUntil(this.authService.getLogoutTrigger()))
      ),
      filter((resp: LicenseSyncResponse): boolean => !!resp),
      map((resp: LicenseSyncResponse): boolean => {
          if (resp.lastSyncRequest) {
            let needsRefresh: boolean = false;
            if (this.lastEntitlementCheck) {
              needsRefresh = this.lastEntitlementCheck?.getTime() !== resp.lastSyncRequest?.toDate()?.getTime();
            }
            this.lastEntitlementCheck = resp.lastSyncRequest?.toDate();
            if (needsRefresh) {
              this.license$.next(Object.assign(this.license$.value, { loaded: false }));
              return true;
            }
          }
          return false;
        }
      ));
  }

  private fetchLicense(): Observable<LicenseResponse> {
    return this.authService.getActiveUser().pipe(
      filter((user: DBUser): boolean => !!user?.loginDomain),
      take(1),
      switchMap((user: DBUser): Observable<LicenseResponse> =>
        this.http.post<LicenseResponse>(
          environment.urls.services.licensing,
          {
            domain: user.loginDomain,
            tools: environment.licensing.toolLicenseKeys
          },
          this.httpOptions)));
  }

  private mergeOverride(license: License): Observable<License> {
    return this.localOverride$.asObservable().pipe(map((override: LocalOverride): License => {
      Object.entries(override.subscriptions).forEach(([ tool, value ]: [string, any]): void => {
        if (license.subscriptions.hasOwnProperty(tool)) {
          license.subscriptions[tool].plan.name = value.plan.name;
          license.subscriptions[tool].expiry = value.expiry;
        }
      });
      return license;
    }));
  }


}
