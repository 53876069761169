import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { IChromeAuditEventResponse } from 'src/app/modules/common/models/chrome-audit-event.model';
import { IUpdateDeviceInfo, IUpdateDeviceInfoResponse } from 'src/app/modules/common/models/update-device-info.model';

import { AuthService } from '../../../../core/modules/auth/services/auth.service';

import { DBUser } from '../../../../core/modules/auth/services/auth.interfaces';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ApiResponse, ICdwSearchGridDataService, IGetApiDataParams, IGetSqlDataParams, SqlResponse } from '@cdw-ae/common-ui-components-search-grid';
import { ChromeOSDevice, DeviceInfo, DeviceInfoSupportResponse } from '../../../common/models/device-info.model';
import { DeviceTelemetry } from '../../../common/models/device-telemetry.model';
import { Customer } from '../../../status/status';
import {
  AppSettingsFeatures,
  BasicDeviceInfo,
  BasicResponse,
  DeprovisionReason,
  DeviceActionState,
  DeviceQueryResponse,
  DeviceQueryResponseRecord,
  DeviceTelemetryResponse,
  GBSessionResponse,
  IGetDevicesApiParams,
  IGetDevicesCacheParams,
  IQueryDevicesApiParams,
  IQueryDevicesCacheParams,
  PaginationData,
  SetStateOptions
} from '../../interfaces/deviceInfo.interfaces';

@Injectable({
  providedIn: 'root',
})
export class DeviceInfoService implements ICdwSearchGridDataService<DeviceQueryResponseRecord> {
  public selectedDevice$: BehaviorSubject<DeviceInfo> = new BehaviorSubject<DeviceInfo>(null);
  public editDevice$: BehaviorSubject<IUpdateDeviceInfo> = new BehaviorSubject<IUpdateDeviceInfo>(null);
  public viewingDevice$: Subject<void> = new Subject<void>();

  private setDeviceState$: (data: SetStateOptions) => Observable<BasicResponse>;
  private updateBasicDeviceInfo$: (data: any) => Observable<DeviceQueryResponse>;
  private getDeviceInfo$: (data: any) => Observable<any>;
  private queryDevices$: (data: IQueryDevicesApiParams | IQueryDevicesCacheParams) => Observable<DeviceQueryResponse | PaginationData<DeviceQueryResponseRecord>>;
  private queryDevicesSearchGrid$: (data: IQueryDevicesApiParams | IQueryDevicesCacheParams) => Observable<SqlResponse<DeviceQueryResponseRecord> | ApiResponse<DeviceQueryResponseRecord>>;


  constructor(
    private firebaseFunctions: AngularFireFunctions,
    private httpClient: HttpClient,
    private authService: AuthService,
    private afs: AngularFirestore,
  ) {
    this.setDeviceState$ = this.firebaseFunctions.httpsCallable<SetStateOptions, BasicResponse>(
      'deviceActions-setState'
    );
    this.updateBasicDeviceInfo$ = this.firebaseFunctions.httpsCallable<any, DeviceQueryResponse>(
      'deviceInfo-updateBasicDeviceInfo'
    );
    this.getDeviceInfo$ = this.firebaseFunctions.httpsCallable('deviceInfo-getDeviceInfo');
    this.queryDevices$ = this.firebaseFunctions.httpsCallable<IQueryDevicesCacheParams | IQueryDevicesApiParams, DeviceQueryResponse | PaginationData<DeviceQueryResponseRecord>>('deviceInfo-queryDevicesFromFilterBar');
    this.queryDevicesSearchGrid$ = this.firebaseFunctions.httpsCallable<IQueryDevicesCacheParams | IQueryDevicesApiParams, SqlResponse<DeviceQueryResponseRecord> | ApiResponse<DeviceQueryResponseRecord> >('deviceInfo-queryDevicesFromFilterBar');

  }

  public fetchSqlData(params: IGetSqlDataParams): Observable<SqlResponse<DeviceQueryResponseRecord>> {
    const { activeFilters, supplementalFilters, startRow, endRow, maxResults }: IGetSqlDataParams = params;
    return this.authService
    .getActiveUser()
    .pipe(
      filter((user: DBUser): boolean => user.isLoaded),
      switchMap(
        (user: DBUser): Observable<PaginationData<DeviceQueryResponseRecord>> =>
          this.queryDevicesSearchGrid$({
            customerId: user.profile.customerId,
            email: user.profile.email,
            userId: user.profile.id,
            activeFilters,
            supplementalFilters,
            dataSource: 'CACHE',
            startRow,
            endRow,
            maxResults
          }) as Observable<SqlResponse<DeviceQueryResponseRecord>>
      ),
    ) 
  }

  public fetchApiData(params: IGetApiDataParams): Observable<ApiResponse<DeviceQueryResponseRecord>> {
    const { activeFilters, nextPageToken, maxResults }: IGetDevicesApiParams = params;
    return this.authService
    .getActiveUser()
    .pipe(
      filter((user: DBUser): boolean => user.isLoaded),
      switchMap(
        (user: DBUser):Observable<ApiResponse<DeviceQueryResponseRecord>> =>
          this.queryDevicesSearchGrid$({
            fields: 'chromeosdevices(deviceId,notes,annotatedAssetId,serialNumber,model,orgUnitPath),nextPageToken',
            customerId: user.profile.customerId,
            email: user.profile.email,
            userId: user.profile.id,
            activeFilters,
            dataSource: 'API',
            nextPageToken,
            maxResults
          }) as Observable<ApiResponse<DeviceQueryResponseRecord>>
      ),
    ) 
  }


  public hasBulkActionsAccess(): Observable<boolean> {
    return this.authService.dbUser$.pipe(
      switchMap((user: DBUser): Observable<boolean> => 
        this.afs
          .doc(`customers/${user.profile?.customerId}`)
          .valueChanges().pipe(
            map((customer: Customer): boolean => customer?.features?.deviceSearchActions ?? false)
          )
      ),
      shareReplay()
    );
  }

  public hasDeviceCacheSearchAccess(): Observable<boolean> {
    return this.authService.dbUser$.pipe(
      switchMap((user: DBUser): Observable<boolean> => {
        const customerSettingsDeviceCacheSearch$: Observable<boolean | undefined> = this.afs
          .doc(`customers/${user.profile?.customerId}`)
          .valueChanges().pipe(
            map((customer: Customer): boolean | undefined => customer?.features?.deviceCacheSearch));

        const appSettingsDeviceCacheSearch$: Observable<boolean> = this.afs
          .doc('appSettings/features')
          .valueChanges().pipe(
            map((appSettingsFeatures: AppSettingsFeatures): boolean => appSettingsFeatures.deviceCacheSearch)); 

        return combineLatest([ customerSettingsDeviceCacheSearch$, appSettingsDeviceCacheSearch$ ]).pipe(
          map(([ customerSettingsDeviceCacheSearch, appSettingsDeviceCacheSearch ]: [boolean | undefined, boolean]): boolean => {
            if(customerSettingsDeviceCacheSearch === undefined) {
              return appSettingsDeviceCacheSearch;
            }
            return customerSettingsDeviceCacheSearch;
          })
        )
      }
    )); 
  }

  public getDevicesFromApi(params: IGetDevicesApiParams): Observable<DeviceQueryResponse> {
    const { activeFilters, nextPageToken, maxResults }: IGetDevicesApiParams = params;
    return this.authService
    .getActiveUser()
    .pipe(
      filter((user: DBUser): boolean => user.isLoaded),
      switchMap(
        (user: DBUser): Observable<DeviceQueryResponse> =>
          this.queryDevices$({
            fields: 'chromeosdevices(deviceId,notes,annotatedAssetId,serialNumber,model,orgUnitPath),nextPageToken',
            customerId: user.profile.customerId,
            email: user.profile.email,
            userId: user.profile.id,
            activeFilters,
            dataSource: 'API',
            nextPageToken,
            maxResults
          }) as Observable<DeviceQueryResponse>
      ),
    ) 
  }

  public getDevicesFromCache(params: IGetDevicesCacheParams): Observable<PaginationData<DeviceQueryResponseRecord>> {
    const { activeFilters, supplementalFilters, startRow, endRow, maxResults }: IGetDevicesCacheParams = params;
    return this.authService
    .getActiveUser()
    .pipe(
      filter((user: DBUser): boolean => user.isLoaded),
      switchMap(
        (user: DBUser): Observable<PaginationData<DeviceQueryResponseRecord>> =>
          this.queryDevices$({
            customerId: user.profile.customerId,
            email: user.profile.email,
            userId: user.profile.id,
            activeFilters,
            supplementalFilters,
            dataSource: 'CACHE',
            startRow,
            endRow,
            maxResults
          }) as Observable<PaginationData<DeviceQueryResponseRecord>>
      ),
    ) 
  }

  public setDeviceState(deviceId: string, newState: DeviceActionState, deprovisionReason?: DeprovisionReason): Observable<BasicResponse>  {
    return this.setDeviceState$({ deviceId, newState, deprovisionReason })
  }

  public getBasicDeviceInfo(deviceId: string): Observable<DeviceInfo> {
    return this.authService.getActiveUser().pipe(
      filter((user: DBUser): boolean => user.isLoaded),
      switchMap(
        (user: DBUser): Observable<any> =>
          this.getDeviceInfo$({
            customerId: user.profile.customerId,
            email: user.profile.email,
            deviceId,
          }).pipe(
            map(
              (response: { success: boolean; deviceInfo: BasicDeviceInfo }): DeviceInfo => ({
                user: null,
                deviceLookup: response.deviceInfo,
                chromeOSVersionMax: response.deviceInfo.chromeOSVersionMax,
                latestStableVersion: response.deviceInfo.latestStableVersion,
              })
            )
          )
      )
    );
  }

  public getBasicDeviceInfoFromSupportLink(queryString: string): Observable<DeviceInfoSupportResponse> {
    return this.firebaseFunctions
      .httpsCallable('deviceInfo-getDeviceInfoFromSupportLink')({
        q: queryString,
      })
      .pipe(
        map(
          (response: { success: boolean; deviceInfo: ChromeOSDevice; access: string }): DeviceInfoSupportResponse => ({
            device: response.deviceInfo,
            access: response.access,
          })
        )
      );
  }

  public updateBasicDeviceInfo(newUpdateDeviceInfo: IUpdateDeviceInfo): Observable<IUpdateDeviceInfoResponse> {
    return this.authService.getActiveUser().pipe(
      filter((user: DBUser): boolean => user.isLoaded),
      switchMap(
        (user: DBUser): Observable<any> =>
          this.updateBasicDeviceInfo$({
            projection: 'FULL',
            customerId: user.profile.customerId,
            email: user.profile.email,
            deviceId: newUpdateDeviceInfo.deviceId,
            annotatedAssetId: newUpdateDeviceInfo.annotatedAssetId,
            annotatedUser: newUpdateDeviceInfo.annotatedUser,
            orgUnitPath: newUpdateDeviceInfo.orgUnitPath,
            annotatedLocation: newUpdateDeviceInfo.annotatedLocation,
            notes: newUpdateDeviceInfo.notes,
          }).pipe(map((response: DeviceQueryResponse): DeviceQueryResponse => response))
      )
    );
  }

  public getGopherBuddySessionData(deviceId: string): Observable<GBSessionResponse> {
    return this.authService.getActiveUser().pipe(
      filter(( user : DBUser): boolean => user.isLoaded),
      switchMap(
        ( user : DBUser): Observable<GBSessionResponse> =>
          this.firebaseFunctions.httpsCallable('deviceInfo-getDeviceSessions')({
            customerId: user.profile.customerId,
            email: user.profile.email,
            deviceId,
          })
      )
    );
  }

  public getDeviceTelemetry(deviceId: string): Observable<DeviceTelemetry> {
    return this.authService.getActiveUser().pipe(
      filter((user: DBUser): boolean => user.isLoaded),
      switchMap(
        (user: DBUser): Observable<DeviceTelemetry> =>
          this.firebaseFunctions
            .httpsCallable('deviceInfo-getDeviceTelemetry')({
              fields:
                'cpuInfo,cpuStatusReport,memoryStatusReport,networkStatusReport,batteryInfo,batteryStatusReport,storageInfo,graphicsInfo,graphicsStatusReport,memoryInfo',
              customerId: user.profile.customerId,
              email: user.profile.email,
              deviceId,
              burstCache: true,
            })
            .pipe(
              map((response: DeviceTelemetryResponse): DeviceTelemetry => new DeviceTelemetry(response.deviceTelemetry))
            )
      )
    );
  }

  public getAuditLogs(deviceId: string): Observable<IChromeAuditEventResponse> {
    return this.authService.getActiveUser().pipe(
      filter((user: DBUser): boolean => user.isLoaded),
      switchMap(
        (user: DBUser): Observable<any> =>
          this.firebaseFunctions
            .httpsCallable('deviceInfo-getAuditLogs')({
              fields: 'items.id.time, items.actor.callerType,items.actor.profileId,items.events',
              customerId: user.profile.customerId,
              deviceId,
            })
            .pipe(map((response: IChromeAuditEventResponse): IChromeAuditEventResponse => response))
      )
    );
  }

  // CG-2574 mark for deletion
  private isDeviceQueryResponse(arg: DeviceQueryResponse | PaginationData<DeviceQueryResponseRecord>): arg is DeviceQueryResponse {
    return (arg as DeviceQueryResponse).success !== undefined;
  }
  // CG-2574 mark for deletion
  private isPaginationData<T>(arg: DeviceQueryResponse | PaginationData<T>): arg is PaginationData<T> {
    return (arg as PaginationData<T>).itemTotal !== undefined;
  }
}
