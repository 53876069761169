import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';

import { Observable } from 'rxjs';

import { DeviceTelemetry } from '../../../models/device-telemetry.model';

@Component({
  selector: 'g4c-telemetry-info',
  templateUrl: './telemetry-info.component.html',
  styleUrls: [ './telemetry-info.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TelemetryInfoComponent {
  @Input()
  public deviceTelemetry: Observable<DeviceTelemetry>;
}
