<div class="gb-data-panel flex flex-col">
  <div class="gb-data-desc">Captured sessions for this device from the past 30 days (max 100)</div>
  <div *ngIf="gbSessions | async as sessionData else loading">
    <div *ngIf="sessionData.deviceSessions.length > 0 else noSessionData" class="mat-table-panel">
      <table [dataSource]="sessionData.deviceSessions | slice : startIndex : endIndex" class="mat-elevation-z1" mat-table>
        <ng-container matColumnDef="user">
          <th *matHeaderCellDef mat-header-cell> User</th>
          <td *matCellDef="let element" class="truncated-cell" mat-cell> {{element.user}} </td>
        </ng-container>

        <ng-container matColumnDef="startTime">
          <th *matHeaderCellDef mat-header-cell> Session start</th>
          <td *matCellDef="let element" class="truncated-cell" mat-cell> {{element.startTime | date:'medium'}} </td>
        </ng-container>

        <ng-container matColumnDef="duration">
          <th *matHeaderCellDef mat-header-cell> Duration (mins)</th>
          <td *matCellDef="let element" mat-cell> {{element.duration}} </td>
        </ng-container>

        <ng-container matColumnDef="privateIp">
          <th *matHeaderCellDef mat-header-cell> Local IP Address</th>
          <td *matCellDef="let element" [matTooltip]="element.privateIp" mat-cell>
            <div class="truncated-cell"> {{element.privateIp}} </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="publicIp">
          <th *matHeaderCellDef mat-header-cell> Public IP Address</th>
          <td *matCellDef="let element" [matTooltip]="element.publicIp" mat-cell>
            <div class="truncated-cell"> {{element.publicIp}} </div>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="tableHeaders;" mat-header-row></tr>
        <tr *matRowDef="let row; columns: tableHeaders;" mat-row></tr>
      </table>
      <mat-paginator (page)="handlePage($event)" [length]="sessionData.deviceSessions?.length || 0" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page">
      </mat-paginator>
    </div>
    <ng-template #noSessionData>

      <div *ngIf="!sessionData.BASIC_ACCESS_ONLY" class="no-data-info-panel flex flex-row justify-center items-center">
        <p>No session data found for this device</p>
      </div>

      <div *ngIf="sessionData.BASIC_ACCESS_ONLY" class="no-data-info-panel flex flex-row justify-center items-center">
        <p>You do not have access to view device sessions.</p>
      </div>
    </ng-template>
  </div>


  <ng-template #loading>
    <div class="loading-panel flex flex-col justify-center items-center">
      <mat-spinner></mat-spinner>
      <p>Loading session data...</p>
    </div>
  </ng-template>
</div>
