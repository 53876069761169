import _ from 'lodash';



import { chromemanagement_v1 } from '@googleapis/chromemanagement';

export type TelemetryDevice = chromemanagement_v1.Schema$GoogleChromeManagementV1TelemetryDevice;
export type CpuStatusReport = chromemanagement_v1.Schema$GoogleChromeManagementV1CpuStatusReport;
export type NetworkStatusReport = chromemanagement_v1.Schema$GoogleChromeManagementV1NetworkStatusReport;
export type MemoryStatusReport = chromemanagement_v1.Schema$GoogleChromeManagementV1MemoryStatusReport;
export type BatteryStatusReport = chromemanagement_v1.Schema$GoogleChromeManagementV1BatteryStatusReport


export class DeviceTelemetry {

  public get totalRamBytesDisplay(): string {
    return  this.bytesDisplay(+this.raw?.memoryInfo.totalRamBytes);
  }

  public get ramUsagePct(): number {
    return Math.round(+this.latestMemoryStatus.systemRamFreeBytes / +this.raw?.memoryInfo.totalRamBytes * 100);
  }

  public get usedDiskBytes(): number {
    const { totalDiskBytes, availableDiskBytes }:{ totalDiskBytes: number, availableDiskBytes: number }
      = this.getDiskBytes();
    return  (+totalDiskBytes) - (+availableDiskBytes);
  }

  public get usedDiskBytesDisplay(): string {
    return  this.bytesDisplay(this.usedDiskBytes);
  }

  public get totalDiskBytesDisplay(): string {
    const { totalDiskBytes }:{ totalDiskBytes: number } = this.getDiskBytes()
    return this.bytesDisplay(totalDiskBytes)
  }

  public get diskUsagePct(): number {
    const { totalDiskBytes }:{ totalDiskBytes: number } = this.getDiskBytes()
    return  Math.round(this.usedDiskBytes / totalDiskBytes * 100);
  }

  public get systemRamFreeBytesDisplay(): string {
    return this.bytesDisplay(+this.latestMemoryStatus.systemRamFreeBytes);
  }

  public get latestMemoryStatus(): MemoryStatusReport {
    return this.firstReport(this.raw?.memoryStatusReport);
  }

  public get latestNetworkStatus(): NetworkStatusReport {
    return this.firstReport(this.raw?.networkStatusReport);
  };

  public get latestCpuTemps(): CpuStatusReport {
    return this.firstReport(
      _.filter(this.raw?.cpuStatusReport, (csr: CpuStatusReport): boolean => _.has(csr, 'cpuTemperatureInfo'))
    );
  }

  public get latestCpuUtilization(): CpuStatusReport {
    return  this.firstReport(
      _.filter(this.raw?.cpuStatusReport, (csr: CpuStatusReport): boolean => _.has(csr, 'cpuUtilizationPct'))
    );
  }

  public calculateStorageUsage(): string {
    const bytesInGb = 1073741824;
    if(this.raw?.storageInfo){
      const available = +this.raw.storageInfo.availableDiskBytes / bytesInGb;
      const total =  +this.raw.storageInfo.totalDiskBytes / bytesInGb;
      const usage = Math.floor((available/total)*100);
      return usage + "% (" + Math.floor(available) + "GB / " + Math.floor(total) + "GB)";
    }
    return "Not reported"
  }

  public get batteryHealth(): string {
    if(this.raw?.batteryStatusReport) {
    return this.batteryHealthLookup(this.firstReport(
      _.filter(this.raw?.batteryStatusReport, (bsr: BatteryStatusReport): boolean => _.has(bsr, 'batteryHealth' ))
    ))
  }
  return 'Not reported'
}

  constructor(public raw: TelemetryDevice) {}

  private getDiskBytes():{totalDiskBytes: number, availableDiskBytes: number} {
    return {
      totalDiskBytes: +this.raw?.storageInfo.totalDiskBytes,
      availableDiskBytes: +this.raw?.storageInfo.availableDiskBytes
    }
  }

  private firstReport<T extends { reportTime?: string | null }>(reportCollection: T[]): T {
    return _(reportCollection).sortBy((r: T): Date => new Date(r.reportTime)).reverse().first();
  }

  // Stolen from SO. Should probably be moved to a utilities class.
  private bytesDisplay(bytes: number, decimals: number = 2): string {
    if (bytes === 0) return '0 Bytes';

    const k: number = 1024;
    const dm: number = decimals < 0 ? 0 : decimals;
    const sizes: string[] = [ 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ];

    const i: number = Math.floor(Math.log(bytes) / Math.log(k));

    return `${ parseFloat((bytes / k**i).toFixed(dm))} ${ sizes[i] }`;
  }

  private batteryHealthLookup(bsr: BatteryStatusReport): string {

    const bsrHash:object = {
      'BATTERY_HEALTH_NORMAL': 'Normal',
      'BATTERY_REPLACE_SOON': 'Replace soon',
      'BATTERY_REPLACE_NOW': 'Replace now',
      'BATTERY_HEALTH_UNSPECIFIED': 'Not reported',
    }
    if(bsrHash.hasOwnProperty(bsr.batteryHealth)){
      return bsrHash[bsr.batteryHealth]
    }
    return 'Not reported';
  }
}
