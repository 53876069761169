<div class="flex flex-col h-full pt-6">
  <div class="title-bar">
    <h1 mat-dialog-title class="pl-8 pb-0-important">Edit Device</h1>
  </div>
  <mat-dialog-content class="dialog-body pt-0-important">
      <form (ngSubmit)="saveEdit()" [formGroup]="editForm" class="flex flex-col" #f="ngForm">

        <div>
          Device current status: {{this.data.status}}
        </div>

        <mat-form-field class="flex flex-col justify-start items-start form-field-white-dropdown low-label">
          <mat-label>Change status</mat-label>
              <mat-select formControlName="newStatus">
                <mat-option>{{this.data.status | titlecase }} (no change)</mat-option>
                <mat-option *ngFor="let status of statusTransitions[this.data.status]" [value]="status.value">{{status.label}} 
                  <span *ngIf="this.editForm.controls.newStatus.value === 'deprovision' && status.value === 'deprovision' && this.editForm.controls.deprovisionReason.value"> - {{this.editForm.controls.deprovisionReason.value}}</span>
                </mat-option>
              </mat-select>
        </mat-form-field>




        <mat-form-field class="flex flex-col justify-start items-start form-field-white">
          <div class="info-property">
            <div class="header">Annotated Asset ID</div>
            <input class="value" matInput maxlength="200" formControlName="annotatedAssetId">
          </div>
        </mat-form-field>
        <mat-form-field class="flex flex-col justify-start items-start form-field-white">
          <div class="info-property">
            <div class="header">Annotated User</div>
            <input class="value" maxlength="100" matInput formControlName="annotatedUser">
          </div>
        </mat-form-field>
        <mat-form-field class="flex flex-col justify-start items-start form-field-white">
          <div class="info-property">
            <div class="header">Annotated Location</div>
            <input class="value" matInput maxlength="200" #annotatedLocation formControlName="annotatedLocation">
          </div>
        </mat-form-field>
        <mat-form-field class="flex flex-col justify-start items-start form-field-white">
          <div class="info-property">
            <div class="header">Organizational Unit</div>
            <div class="flex flex-row">
              <input (focusout)="inOrgUnit(orgUnitPath.value)" [matAutocomplete]="auto" [readonly]="loadingOrgUnits" type="text" aria-label="Search for organizational units" matInput formControlName="orgUnitPath" #orgUnitPath required>
              <mat-spinner *ngIf="loadingOrgUnits" id="orgUnitSpinner" diameter="25"></mat-spinner>
            </div>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let orgUnit of filteredOrgUnits$ | async" [value]="orgUnit.orgUnitPath">
                {{orgUnit.orgUnitPath}}
              </mat-option>
            </mat-autocomplete>
          </div>
          <mat-error *ngIf="editForm.controls.orgUnitPath.hasError('required')">Org unit path is required</mat-error>
          <mat-error *ngIf="editForm.controls.orgUnitPath.getError('validOrgUnit') === true">Org unit path must be valid</mat-error>
        </mat-form-field>
        <mat-form-field class="flex flex-col justify-start items-start form-field-white">
        <div class="info-property">
          <div class="header">Notes</div>
          <textarea class="value" #notes matInput rows="4" maxlength="500" formControlName="notes"></textarea>
        </div>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="dialog-actions flex flex-row justify-end-important items-center pr-6-important pt-0-important mt--4-important">
    <button (click)="cancelEdit()" [disabled]="buttonsDisabled" mat-flat-button color="warn">Cancel</button>
    <button (click)="saveEdit()" [disabled]="buttonsDisabled || editForm.invalid || loadingOrgUnits" mat-flat-button color="primary">{{(!buttonsDisabled ? "Save" : "Saving...")}}</button>
    <mat-spinner *ngIf="buttonsDisabled" diameter="20"></mat-spinner>
  </mat-dialog-actions>
</div>

