<div class="override-panel">
  <div *ngIf="initialised" class="flex flex-row justify-between items-center gap-[18px]">

    <mat-form-field>
      <mat-label>Choose a date</mat-label>
      <input [(ngModel)]="license.subscriptions['CG'].expiry" (ngModelChange)="handleExpireOverride($event)" matInput [matDatepicker]="picker" readonly>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <select [(ngModel)]="license.subscriptions['CG'].plan.name"  (ngModelChange)="handleTierOverride($event)"  matNativeControl required>
        <option value="Paid">Paid</option>
        <option value="Lapsed">Lapsed</option>
        <option value="Consultant">Consultant</option>
        <option value="Trial">Trial</option>
        <option value="Free">Free</option>
        <option value="Unlicensed">Unlicensed</option>
      </select>
    </mat-form-field>

    <mat-form-field>
      <select [(ngModel)]="tool"  (ngModelChange)="handleToolOverride($event)"  matNativeControl required>
        <option value="CG">Gopher for Chrome</option>
        <option value="CGP">Gopher for Chrome Premium</option>
      </select>
    </mat-form-field>

    <button *ngIf="overrideActive" class="clickable" mat-icon-button (click)="removeLocalOverride()" matTooltip="Reset">
      <mat-icon>cancel</mat-icon>
    </button>

  </div>
</div>


