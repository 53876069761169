// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { oauthScopes } from './constants/oauthScopes';
import { Environment } from './environment.interface';

// const gopherBuddyApiBaseUrl: string =  'http://localhost:3000';
const gopherBuddyApiBaseUrl: string = 'https://gopher-buddy-dev.appspot.com';
// const cloudRunExportsUrl: string = 'http://localhost:8080';
const cloudRunExportsUrl: string = 'https://cdw-amplified-edu-export-server-6xbvwuiozq-uc.a.run.app';

export const environment: Environment = {
  production: false,
  useLocalFunctions: false,
  useLocalFirestore: false,
  showLicenseTestPanel: false,
  cloudRunInstances: {
    exports: `${cloudRunExportsUrl}/api/createExport`,
  },
  // functions_base_url: 'http://localhost:5001/chrome-gopher-dev/us-central1',
  functions_base_url: 'https://us-central1-chrome-gopher-dev.cloudfunctions.net',
  firebase: {
    apiKey: 'AIzaSyCnIovaUyK2lv4G0ViXNDDJXi1g5GdDRBg',
    authDomain: 'chrome-gopher-dev.firebaseapp.com',
    databaseURL: 'https://chrome-gopher-dev.firebaseio.com',
    projectId: 'chrome-gopher-dev',
    storageBucket: 'chrome-gopher-dev.appspot.com',
    messagingSenderId: '887254038863',
    appId: '1:887254038863:web:8cf5e2177f5d5440e1ed3a',
  },

  licensing: {
    toolLicenseKeys: [ 'ait-gcp', 'ait-gfc' ],
  },
  oauthScopes,
  urls: {
    services: {
      licensing: 'https://us-central1-labs-licensing.cloudfunctions.net/v2-checkLicense_dev',
      tokenValidation: 'https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=',
      // deviceApi: 'http://localhost:3000',
      deviceApi: 'https://saas-device-api-6xbvwuiozq-uc.a.run.app',
      customerApi: 'https://saas-customer-api-6xbvwuiozq-uc.a.run.app',
      // chromeProfileWipeApi: 'http://localhost:8080/api'
      chromeProfileWipeApi: 'https://g4c-chrome-profile-wipe-qt2rsv2pmq-uc.a.run.app/api',
      auditApi: 'https://saas-audit-log-api-6xbvwuiozq-uc.a.run.app/api',
      // auditApi: 'http://localhost:3000/api',
    },
  },
  google: {
    gapi_clientId: '887254038863-t6b7cj6i7l919gk2lktqnn2155d16ebg.apps.googleusercontent.com',
  },
  support: {
    email: 'support@amplifiedit.com',
    feedbackFormLink: 'http://bit.ly/labsfeaturerequests',
    supportLink: 'https://amplifiedlabs.zendesk.com/hc/en-us/requests/new',
    helpCenterLink: 'https://amplifiedlabs.zendesk.com/hc/en-us/categories/203616448-Gopher-for-Chrome',
  },

  links: {
    purchase: 'https://www.amplifiedit.com/chargify-quote-request/?SKU=AIT-GFC',
    upgrade: 'https://www.amplifiedit.com/chargify-quote-request/?SKU=AIT-GCP',
    renew:
      'https://mail.google.com/mail/?view=cm&source=mailto&to=accounts@amplifiedit.com&su={app_name}%20renewal%20for%20{domain}&body=I%E2%80%99d%20like%20to%20request%20a%20renewal%20quote%20for%20{app_name}%20for%20domain%2C%20{domain}.%0D%0A%0D%0AThank%20you%2C%0D%0A%0D%0A{user_name}',
  },
  gopher_buddy_admin_panel: {
    consoleProjectId: '389868568330',
    gopherExtensionId: 'iaoapmdkdklgglfoflgoclakndbpbfal',
    helpUrl: 'https://amplifiedlabs.zendesk.com/hc/en-us/articles/360002516793',
    firebase: {
      apiKey: 'AIzaSyCnFytc4BapdWSR5kQYQWORy_0PlQvMrkQ',
      authDomain: 'gopher-buddy-dev.firebaseapp.com',
      databaseURL: 'https://gopher-buddy-dev.firebaseio.com',
      projectId: 'gopher-buddy-dev',
      storageBucket: 'gopher-buddy-dev.appspot.com',
      messagingSenderId: '389868568330',
      appId: '1:389868568330:web:6dd09449b06dd9b53f84f3',
      measurementId: 'G-YRQ0NRE86M',
    },
  },
  gopher_buddy_api: {
    apiKey: 'dsalj009mnv-8sdjhsa8jhd',
    routes: {
      deviceInfo: `${gopherBuddyApiBaseUrl}/device/:id?apiToken=:apiToken&domain=:domain&user=:userEmail`,
      deviceTelemetry: `${gopherBuddyApiBaseUrl}/device/telemetry/:id?apiToken=:apiToken&domain=:domain&user=:userEmail`,
      sessionInfo: `${gopherBuddyApiBaseUrl}/sessions/:domain/:id?apiToken=:apiToken&userEmail=:userEmail`,
      deleteCustomerCache: `${gopherBuddyApiBaseUrl}/customer/:customerId/cache?apiToken=:apiToken`,
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
