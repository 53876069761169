<ng-container *ngIf="{ selectedDevice: selectedDevice, telemetry: deviceTelemetry | async } as device else loading">
  <div *ngIf="device.selectedDevice && device.telemetry else loading" class="additional-info-content">
    <mat-card appearance="outlined" *ngIf="!selectedDevice?.deviceLookup?.lastSync">
      <mat-card-content >
        <div class="flex flex-col gap-[14px]">
          <ng-container>
            <strong><i>Device has not checked in yet, please try again later</i></strong>
          </ng-container>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined">
      <mat-card-title class="pt-4 pl-4">Hardware Diagnostics and Telemetry Data</mat-card-title>
      <mat-card-content>
        <div class="flex flex-col gap-[14px]">
          <div class="flex flex-row gap-[32px] justify-start">
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Boot Mode</div>
              <div *ngIf="device.selectedDevice?.deviceLookup?.bootMode else notSet" class="value">{{device.selectedDevice.deviceLookup.bootMode}}</div>
            </div>
  
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Platform Version</div>
              <div *ngIf="device?.selectedDevice?.deviceLookup?.platformVersion else notSet" class="value">{{device.selectedDevice.deviceLookup.platformVersion}}</div>
            </div>
  
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">TPM Firmware Version</div>
              <div *ngIf="device?.selectedDevice?.deviceLookup?.tpmVersionInfo?.firmwareVersion else notSet" class="value">{{device.selectedDevice.deviceLookup.tpmVersionInfo.firmwareVersion}}</div>
            </div>
          </div>
  
          <div class="flex flex-row gap-[32px] justify-start">
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Memory Usage</div>
              <div *ngIf="device?.selectedDevice?.deviceLookup?.systemRamFreeReports else notSet" class="value"> {{device.selectedDevice.deviceLookup.systemRamFreeReports[0].systemRamFreeInfo[0]}}</div>
            </div>
  
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">CPU Utilization</div>
              <div *ngIf="device?.selectedDevice?.deviceLookup?.cpuStatusReports else notSet" class="value">{{device.selectedDevice.deviceLookup.cpuStatusReports[0].cpuUtilizationPercentageInfo}}</div>
            </div>
  
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Disk Space Usage</div>
              <div *ngIf="device?.telemetry?.raw?.storageInfo else notSet" class="value">{{device.telemetry.calculateStorageUsage()}}</div>
            </div>
          </div>
  
          <div class="flex flex-row gap-[32px] justify-start">
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Battery Type</div>
              <div *ngIf="device?.telemetry?.raw?.batteryInfo else notSet" class="value">{{device.telemetry.raw.batteryInfo[0].technology}}</div>
            </div>
  
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Battery Health</div>
              <div *ngIf="device?.telemetry?.batteryHealth else notSet" class="value">{{device.telemetry.batteryHealth}}</div>
            </div>
  
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Last Reported Battery Usage</div>
              <div *ngIf="device?.telemetry?.raw?.batteryStatusReport else notSet" class="value">{{validateDate(device.telemetry.raw.batteryStatusReport[0].reportTime) ? (device.telemetry.raw.batteryStatusReport[0].reportTime | date: 'short') : '-'}}</div>
            </div>
          </div>
  
          <div class="flex flex-row gap-[32px] justify-start">
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Graphics Adaptor</div>
              <div *ngIf="device?.telemetry?.raw?.graphicsInfo?.adapterInfo else notSet" class="value">{{device.telemetry.raw.graphicsInfo.adapterInfo.adapter}}</div>
            </div>
  
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Displays</div>
              <div *ngIf="device?.telemetry?.raw?.graphicsStatusReport else notSet" class="value">{{device.telemetry.raw.graphicsStatusReport[0].displays[0].resolutionHeight}} x {{device.telemetry.raw.graphicsStatusReport[0].displays[0].resolutionWidth}}</div>
            </div>

            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Design Capacity</div>
              <div *ngIf="device?.telemetry?.raw?.batteryInfo && device?.telemetry?.raw?.batteryInfo[0]?.designCapacity else notSet" class="value">{{device?.telemetry?.raw?.batteryInfo[0]?.designCapacity}}</div>
            </div>
          </div>
          <div class="flex flex-row gap-[32px] justify-start">
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
            </div>
  
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              
            </div>

            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Full Charge Capacity</div>
              <div *ngIf="device?.telemetry?.raw?.batteryStatusReport && device?.telemetry?.raw?.batteryStatusReport[0]?.fullChargeCapacity else notSet" class="value">{{device?.telemetry?.raw?.batteryStatusReport[0]?.fullChargeCapacity}}</div>
            </div>
          </div>
        </div>
        

      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined">
      <mat-card-title class="pl-4 pt-4">Networking</mat-card-title>
      <mat-card-content>
        <div class="flex flex-col gap-[14px]">
          <div class="flex flex-row gap-[32px] justify-start">
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Mac Address</div>
              <div *ngIf="device?.selectedDevice?.deviceLookup?.macAddress else notSet" class="value">{{device.selectedDevice.deviceLookup.macAddress}}</div>
            </div>
  
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Ethernet Mac Address</div>
              <div *ngIf="device?.selectedDevice?.deviceLookup?.ethernetMacAddress else notSet" class="value">{{device.selectedDevice.deviceLookup.ethernetMacAddress}}</div>
            </div>
  
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Wifi Signal Strength</div>
              <div *ngIf="device?.telemetry?.raw?.networkStatusReport else notSet" class="value">{{device.telemetry.raw.networkStatusReport[0].signalStrengthDbm}} dbm</div>
            </div>
          </div>
  
          <div class="flex flex-row gap-[32px] justify-start">
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Last Known IP Address</div>
              <div *ngIf="device?.selectedDevice?.deviceLookup?.lastKnownNetwork else notSet" class="value">{{device.selectedDevice.deviceLookup.lastKnownNetwork[0].ipAddress}}</div>
            </div>
          </div>
        </div>

      </mat-card-content>
    </mat-card>
  </div>
</ng-container>

<ng-template #loading>
  <div class="loading-panel flex justify-center items-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #notSet>
  <div class="missing-setting">-</div>
</ng-template>
