<ng-container *ngIf="selectedDevice else loading">
  <div class="basic-info-content">
    <mat-card appearance="outlined" *ngIf="!selectedDevice?.deviceLookup?.lastSync">
      <mat-card-content>
        <div class="flex flex-col gap-[14px]">
          <ng-container>
            <strong><i>Device has not checked in yet, please try again later</i></strong>
          </ng-container>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined">
      <mat-card-title class="pl-4 pt-4">Hardware Details</mat-card-title>
      <mat-card-content>
        <div class="flex flex-col gap-[14px]">
          <div class="flex flex-row justify-start gap-[32px]">
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Serial number</div>
              <div *ngIf="selectedDevice?.deviceLookup?.serialNumber else notSet" class="value">{{selectedDevice.deviceLookup.serialNumber}}</div>
            </div>
  
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Device Org Unit</div>
              <div *ngIf="selectedDevice?.deviceLookup?.orgUnitPath else notSet" class="value">{{selectedDevice.deviceLookup.orgUnitPath}}</div>
            </div>
  
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Device ID</div>
              <div *ngIf="selectedDevice?.deviceLookup?.deviceId else notSet" class="value">{{selectedDevice.deviceLookup.deviceId}}</div>
            </div>
          </div>
  
          <div class="flex flex-row gap-[32px] justify-start">
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Model</div>
              <div *ngIf="selectedDevice?.deviceLookup?.model else notSet" class="value">{{selectedDevice.deviceLookup.model}}</div>
            </div>
  
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">CPU Model</div>
              <div *ngIf="selectedDevice?.deviceLookup?.cpuInfo else notSet" class="value">{{selectedDevice.deviceLookup.cpuInfo[0].model}}</div>
            </div>
          </div>
        </div>
       
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined">
      <mat-card-title class="pl-4 pt-4">OS / Chrome</mat-card-title>
      <mat-card-content>
        <div class="flex flex-col gap-[14px]">
          <div class="flex flex-row gap-[32px] justify-start">
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Chrome version</div>
              <div *ngIf="selectedDevice?.deviceLookup?.osVersion else notSet" class="value">{{selectedDevice.deviceLookup.osVersion}}</div>
            </div>
  
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Latest stable Chrome version for model</div>
              <div *ngIf="selectedDevice?.latestStableVersion else notSet" class="value">{{selectedDevice.latestStableVersion}}</div>
            </div>
  
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Max domain allowed Chrome version</div>
              <div *ngIf="selectedDevice?.chromeOSVersionMax; else notSet" class="value">{{selectedDevice.chromeOSVersionMax}}</div>
            </div>
          </div>
  
          <div class="flex flex-row gap-[32px] justify-start">
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Last OS Update Check</div>
              <div *ngIf="selectedDevice?.deviceLookup?.osUpdateStatus?.updateCheckTime; else notSet" class="value">{{validateDate(selectedDevice.deviceLookup.osUpdateStatus.updateCheckTime) ? (selectedDevice.deviceLookup.osUpdateStatus.updateCheckTime | date: 'short') : '-'}}</div>
            </div>
  
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Last OS Update</div>
              <div *ngIf="selectedDevice?.deviceLookup?.osUpdateStatus?.updateTime; else notSet" class="value">{{validateDate(selectedDevice.deviceLookup.osUpdateStatus.updateTime) ? (selectedDevice.deviceLookup.osUpdateStatus.updateTime | date: 'short') : '-'}}</div>
            </div>
  
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Last Reboot Time</div>
              <div *ngIf="selectedDevice?.deviceLookup?.osUpdateStatus?.rebootTime; else notSet" class="value">{{validateDate(selectedDevice.deviceLookup.osUpdateStatus.rebootTime ? (selectedDevice.deviceLookup.osUpdateStatus.rebootTime | date: 'short') : '-')}}</div>
            </div>
          </div>
  
          <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
            <div class="header">Last Sync</div>
            <div *ngIf="selectedDevice?.deviceLookup?.lastSync else notSet" class="value">{{validateDate(selectedDevice.deviceLookup.lastSync) ? (selectedDevice.deviceLookup.lastSync | date: 'short') : '-'}}</div>
          </div>
        </div>
        
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined">
      <div class="flex flex-row">
        <mat-card-title class="pl-4 pt-4">Editable Fields</mat-card-title>        
      </div>
      <mat-card-content >
        <div class="flex flex-col gap-[14px]">
          <div class="flex flex-row gap-[32px] justify-start">
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Annotated Asset ID</div>
              <div *ngIf="selectedDevice?.deviceLookup?.annotatedAssetId else notSet" class="value">{{selectedDevice.deviceLookup.annotatedAssetId}}</div>
            </div>
  
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Annotated User</div>
              <div *ngIf="selectedDevice?.deviceLookup?.annotatedUser else notSet" class="value">{{selectedDevice.deviceLookup.annotatedUser}}</div>
            </div>
  
            <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
              <div class="header">Annotated Location</div>
              <div *ngIf="selectedDevice?.deviceLookup?.annotatedLocation else notSet" class="value">{{selectedDevice.deviceLookup.annotatedLocation}}</div>
            </div>
          </div>
  
          <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)] flex-1 max-w-full h-full w-full">
            <div class="header">Notes</div>
            <div *ngIf="selectedDevice?.deviceLookup?.notes else notSet" class="value">{{selectedDevice.deviceLookup.notes}}</div>
          </div>
        </div>
        
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>


<ng-template #loading>
  <div class="loading-panel flex justify-center items-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #notSet>
  <div class="missing-setting">-</div>
</ng-template>
