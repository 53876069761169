import { Component, Input } from '@angular/core';
import { Observable, map } from 'rxjs';
import { CustomAnalyticEventsService } from '../../../../core/Services/customAnalyticEvents.service';
import { StatusService } from '../../../status/services/status.service';
import { Customer, Status } from '../../../status/status';

@Component({
  selector: 'g4c-refresh-cache',
  templateUrl: './refresh-cache.component.html',
  styleUrls: [ './refresh-cache.component.scss' ],
})
export class RefreshCacheComponent {
  @Input() public isAdmin: boolean = false;

  public lastUpdated$: Observable<Date>; 

  public get refreshCacheRunning(): boolean {
    return this.statusService.cacheState?.status === Status.RUNNING || this.statusService.telemetryState?.status === Status.RUNNING;
  }

  public get refreshCacheDisabled(): boolean {
    return this.statusService.cacheState?.status === Status.DISABLED
  } 

  public get refreshCacheTooltip(): string {
    return this.statusService.cacheState?.status === Status.RUNNING ? 'Sync in progress... ' : ''
  }

  public get deviceCount(): number {
    return this.statusService.cacheState?.deviceCount || 0;
  }
  
  constructor(
    private analyticsService: CustomAnalyticEventsService,
    private statusService: StatusService,
  ) {

    this.lastUpdated$ = this.statusService.cacheStatus$.pipe(
      map((customer: Customer): Date => customer.cacheState?.lastUpdated.toDate())
    )

  }

  public refreshCache(): void {
    this.statusService.deviceStates$.next([]);
    this.analyticsService.logEvent('webapp_force_scan_cache', this.statusService?.userAuth?.getLogEventProperties());
    this.statusService.triggerSync();
  }
}
