import {
  Component, Input
} from '@angular/core';

import { Observable } from 'rxjs';
import { GBSessionResponse } from '../../../models/device-info.model';



@Component({
  selector: 'g4c-gb-device-session-data',
  templateUrl: './gb-session-data.component.html',
  styleUrls: [ './gb-session-data.component.scss' ]
})
export class GbSessionDataComponent {

  @Input()
  public gbSessions: Observable<GBSessionResponse>;
  public tableHeaders: Array<string> = [ 'user', 'startTime', 'duration', 'privateIp', 'publicIp' ];

  public currentPage: number = 0;
  public pageSize: number = 10;
  public dataSource: GBSessionResponse [];
  public startIndex: number = 0;
  public endIndex: number = this.pageSize;

  public handlePage(e: any): void {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;

    this.endIndex = (this.currentPage + 1) * this.pageSize;
    this.startIndex = this.currentPage * this.pageSize;
  }
}
