import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ErrorDialogComponent } from './error-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(private dialog: MatDialog) {}

  public throwError(error: { title: string; body: string }): void {
    this.dialog.open(ErrorDialogComponent, {
      data: { error },
      panelClass: 'ait-error',
      maxHeight: '100%',
      width: '540px',
      maxWidth: '100%',
      disableClose: true,
      hasBackdrop: true
    });
  }
}
