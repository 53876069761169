import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../../../../environments/environment';
import { LocalStorageService } from '../../../../../../core/Services/local-storage.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Observable, Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { AuthService } from '../../../../../../core/modules/auth/services/auth.service';
import { DBUser } from 'src/app/core/modules/auth/services/auth.interfaces';
import { LicensingRenewalDialogComponent } from '../../licensing-renewal-dialog.component';
import { DialogData, LicenseState } from '../../../../services/licensing.interfaces';
import * as moment from 'moment';
import { CustomAnalyticEventsService } from '../../../../../../core/Services/customAnalyticEvents.service';
import { EntitlementLicensingService, bundleMap } from '../../../../services/entitlement-licensing.service';
import { Moment } from 'moment';
import { take } from 'rxjs/operators';


export interface ToolOptions {
  name: string;
  logo: string;
}
export interface ToolList {
  [key: string]: ToolOptions
}

@Component({
  selector: 'app-licensing-base-dialog',
  templateUrl: './licensing-base-dialog.component.html',
  styleUrls: []
})
export class LicensingBaseDialogComponent implements OnInit, OnDestroy {

  public muteNotification: boolean;
  public dialogData: DialogData;
  public RENEW_DIALOG_KEY_30DAYS: string = 'mute_renew_dialog_30DAYS';
  public RENEW_DIALOG_KEY_14DAYS: string = 'mute_renew_dialog_14DAYS'
  public MUTE_FREE_DIALOG_PROMPT: string = 'mute_free_dialog_prompt';
  public learnMoreLink: string = 'https://amplifiedlabs.zendesk.com/hc/en-us/categories/203616448-Gopher-for-Chrome';
  public isAdmin$: Observable<boolean>;

  @Input()
  public data: {
    licenseState: LicenseState,
    dialogRef: MatDialogRef<LicensingRenewalDialogComponent>
  }


  private tools: ToolList = {
    CG: {
      name: 'Gopher for Chrome',
      logo: './../assets/images/GopherForChrome-01_v2022.png'
    },
    CGP: {
      name: 'Gopher for Chrome Premium',
      logo: './../assets/images/Gopher_for_Chrome_PREMIUM-01_v2022.png'
    }
  }

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor( public _firebaseAnalytics: CustomAnalyticEventsService,
               public _authSvc: AuthService,
               public licensingService: EntitlementLicensingService,
               public localStorageService: LocalStorageService,
               public _datePipe: DatePipe) {

                this.isAdmin$ = this._authSvc.isSuperAdmin$;

                }

  public ngOnInit(): void {
    if (this.data.licenseState.expiresIn <= 7) {
      this.muteNotification = false;
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  public getExpiryDate(license: LicenseState): string{
    return this._datePipe.transform( license.license.subscriptions[license.primarySubscription].expiry, 'MM/dd/yyyy')
  }

  public updateExpiryMessage(licenseState: LicenseState, expiryPrefix?: string): string {

    if (!expiryPrefix) {
      expiryPrefix = `Your license for ${this.data.licenseState.license.domain}`;
    }

    let expiryMessage: string = '';

    if (this.data.licenseState.expiresIn < 0 ) {
      expiryMessage = `${expiryPrefix} expired on ${this.getExpiryDate(licenseState)}`
    } else if (this.data.licenseState.expiresIn === 0) {
      expiryMessage = `${expiryPrefix} expires today`
    } else if (this.data.licenseState.expiresIn === 1) {
      expiryMessage = `${expiryPrefix} expires in 1 day`
    } else if (this.data.licenseState.expiresIn > 1) {
      expiryMessage = `${expiryPrefix} expires in ${this.data.licenseState.expiresIn} days`
    }

    if(this.data.licenseState.bundle === null) {
      expiryMessage += '.';
    } else {
      expiryMessage += ` as part of your <b>${bundleMap[licenseState.bundle]}</b> subscription.`;
    }
    return expiryMessage;
  }

  public recheckLicense(): void {
    this.licensingService.triggerForcedRecheck()
  }

  public requestQuote(): void {
    this._firebaseAnalytics.logEvent('Quote request', { category: 'App', label: 'Quote' });
    window.open(environment.links.purchase);
  }

  public requestUpgrade(): void {
    this._firebaseAnalytics.logEvent('Upgrade request', { category: 'App', label: 'Upgrade' });
    window.open(environment.links.upgrade);
  }

  public requestRenewal(): void {
    this._firebaseAnalytics.logEvent('Upgrade request', { category: 'App', label: 'Renewal' });
    this._authSvc.getActiveUser().pipe(take(1))
      .subscribe((user: DBUser): void =>{
      const url: string = environment.links.renew
        .replace(/{app_name}/g, this.getTool(this.data.licenseState.primarySubscription).name)
        .replace(/{customerId}/g, user.profile.customerId)
        .replace(/{domain}/g, this.data.licenseState.license.domain)
        .replace(/{user_name}/g, user.profile.name);
      window.open(url);
    })
  }



  public getAlertLevel(licenseState: LicenseState): number {
    if(licenseState.expiresIn > 30) {
      return 0;
    }
    return (licenseState.expiresIn > 7 && licenseState.expiresIn <= 30) ? 1 : 2;
  }

  public requestSupport(domain: string): void {
    const url: string = `https://mail.google.com/mail/?view=cm&source=mailto&to=${environment.support.email}&su=Gopher for Chrome license renewal support request for ${domain}.`;
    window.open(url);
  }

  public contactAccounts(domain: string, subject: string = 'Gopher for Chrome - Quote follow up'): void {
    const url: string = `https://mail.google.com/mail/?view=cm&source=mailto&to=accounts@amplifiedit.com&su=${subject} (${domain})`;
    window.open(url);
  }

  public goTo(linkAlias: string): void {
    switch(linkAlias) {
      case 'learnMore':
        window.open(this.learnMoreLink);
        break;
    default:
      console.log(`${linkAlias} not found`);
      break;
    }
  }

  public toggleReminder(e: MatCheckboxChange): void {
    if (this.data.licenseState.tier !== 'free') {
      if (this.dialogData.expireDays >= 8 && this.dialogData.expireDays < 15) {
        this.localStorageService.set(this.RENEW_DIALOG_KEY_14DAYS, String(e.checked))
      }

      if (this.dialogData.expireDays >= 15 && this.dialogData.expireDays <= 30) {
        this.localStorageService.set(this.RENEW_DIALOG_KEY_30DAYS, String(e.checked))
      }
    } else if (e.checked) {
        const muteUntil: Moment = moment().add(14, 'days');
        this.localStorageService.set(this.MUTE_FREE_DIALOG_PROMPT, muteUntil.format('YYYY-MM-DD HH:mm:ss'));
      } else {
        this.localStorageService.remove(this.MUTE_FREE_DIALOG_PROMPT);
      }
  }


  public closeDialog(): void {
    this.data.dialogRef.close()
  }

  public logout(): void {
    this.data.dialogRef.close();
    this._authSvc.logout().catch(error => console.error(error))
  }

  public checkMuteStatus(): void {
    const { tier }: { tier?: string;} = this.data.licenseState

    if (tier === 'free') {
      // Handle the 14 day delay for the notifications via localStorage
      const muteUntil: string = this.localStorageService.get(this.MUTE_FREE_DIALOG_PROMPT);

      if (muteUntil) {
        const now: Moment = moment();
        const muteUntilMoment: Moment = moment(muteUntil);
        this.muteNotification = now.isBefore(muteUntilMoment)
      }
    } else {
      if (this.data.licenseState.expiresIn >= 8 && this.data.licenseState.expiresIn < 15) {
        this.muteNotification = this.localStorageService.get(this.RENEW_DIALOG_KEY_14DAYS) === 'true';
      }
      if (this.data.licenseState.expiresIn >= 15 && this.data.licenseState.expiresIn <= 30) {
        this.muteNotification = this.localStorageService.get(this.RENEW_DIALOG_KEY_30DAYS) === 'true';
      }
    }
  }

  public getTool(key: string): ToolOptions  {
    return this.tools[key];
  }

}
