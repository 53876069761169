<div mat-dialog-content class="gap-[24px] flex flex-col justify-start items-center">
  <h2 *ngIf="!(isAdmin$ | async)">YOU ARE LOGGED IN WITH DELEGATED ADMIN ACCOUNT!</h2>

  <img src="{{dialogData.tool.logo}}" alt="{{dialogData.tool.name}} Logo" height="120px">

  <div class="license-info-panel flex flex-col justify-start items-center" [ngClass]="{'alert-red' : dialogData.alertLevel === 2, 'alert-strong' : dialogData.alertLevel === 2}">
    <div [ngClass]="{'alert-strong' : dialogData.alertLevel === 2}" class="flex flex-row justify-center items-center gap-[12px]">
      <mat-icon *ngIf="dialogData.showIcon" class="icon-20" [ngClass]="{'alert-red' : dialogData.alertLevel === 2}" >warning</mat-icon>
      <div [innerHTML]="dialogData?.expiryMessage | safeHtml" class="text-align-center mb-4"></div>
    </div>
    <div *ngIf="dialogData.displayDate">{{dialogData?.expireDate}}</div>
  </div>

  <div  *ngIf="dialogData.displayInfoBlock" class="license-info-block flex flex-col justify-start items-center" [ngClass]="{'alert-red' : dialogData.alertLevel > 0}">
    <div class="flex flex-row justify-start items-center gap-[12px]">
      <mat-icon *ngIf="!dialogData.showIcon" class="icon-20 alert-red">warning</mat-icon>
      <span class="mb8">If this license is not renewed prior to the above expiration date, it will revert to a FREE Gopher for Chrome license.</span>
    </div>
  </div>

  <div class="button-bar flex flex-row justify-around">
    <button *ngIf="dialogData.controls.showSupportButton" class="renew-button" mat-raised-button (click)="requestSupport(dialogData.domain)">CONTACT SUPPORT</button>
    <button *ngIf="dialogData.controls.showRecheckLicenseButton" class="renew-button" mat-raised-button (click)="forceLicenseRecheck()">RE-CHECK LICENSE</button>
  </div>
</div>

<div [class]="dialogData.showFooterLeft ? 'flex justify-between items-center' : 'flex justify-end items-center'" mat-dialog-actions>
  <mat-checkbox *ngIf="dialogData.controls.showReminderCheckbox" (change)="toggleReminder($event)" [(ngModel)]="muteNotification">Do not show me this again</mat-checkbox>
  <div *ngIf="dialogData.showDataDeletionMessage">
    <span class="small-strong">** Please note, data is deleted 90 days after the license expiration date.</span>
  </div>
  <button *ngIf="!dialogData.controls.showLogout" mat-raised-button class="grey-button" (click)="closeDialog()">{{muteNotification ? 'SAVE & CLOSE' : 'CLOSE'}}</button>
  <button *ngIf="dialogData.controls.showLogout" mat-raised-button class="grey-button" (click)="logout()">LOG OUT</button>
</div>
