import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import { AuthService } from 'src/app/core/modules/auth/services/auth.service';
import { DeviceInfoService } from 'src/app/modules/device/services/device-info/device-info.service';

import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { CustomAnalyticEventsService } from '../../../../../core/Services/customAnalyticEvents.service';
import { StatusService } from '../../../../status/services/status.service';
import { DeviceInfo } from '../../../models/device-info.model';
import { IUpdateDeviceInfo } from '../../../models/update-device-info.model';

@Component({
  selector: 'g4c-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: [ './basic-info.component.scss' ]
})
export class BasicInfoComponent implements OnInit, OnDestroy {
  @Input()
  public canEdit: boolean;

  public selectedDevice: DeviceInfo;
  public currentDate: Date;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthService,
    private deviceInfoService: DeviceInfoService,
    public analytics: AngularFireAnalytics,
    private customAnalyticEvents: CustomAnalyticEventsService,
    public statusService: StatusService
  ) { }

    public ngOnDestroy(): void {
      this.deviceInfoService.editDevice$.next(null);
      this.onDestroy$.next();
    }

  public ngOnInit(): void {
    this.currentDate = new Date();
    this.deviceInfoService.selectedDevice$.pipe(takeUntil(this.onDestroy$)).subscribe((selectedDevice: DeviceInfo) => {
      if(selectedDevice) {
        this.selectedDevice = { ...this.selectedDevice, ...selectedDevice };
      }
    });
    // if can edit is not initialized/true, we check if user is super admin
    if (!this.canEdit) {
      this.authService.isSuperAdmin$.pipe(take(1)).subscribe((isSuperAdmin: boolean): void => {
        if (isSuperAdmin) {
          this.canEdit = true;
        }
      });
    }
  }

  public openChromePolicy(): boolean {
    document.dispatchEvent(new CustomEvent('RW759_connectExtension', {
      detail: {
        req: 'sync'
      }
    }));
    return true;
  }

  public validateDate(date : string) : string {
    if (date === '1970-01-01T00:00:00.000Z') {
      return null;
    }
    return date;
  }

  public editFields(): void {
    const deviceToEdit : IUpdateDeviceInfo = {
      deviceId: this.selectedDevice.deviceLookup.deviceId,
      annotatedAssetId: this.selectedDevice.deviceLookup.annotatedAssetId,
      annotatedLocation: this.selectedDevice.deviceLookup.annotatedLocation,
      orgUnitPath: this.selectedDevice.deviceLookup.orgUnitPath,
      annotatedUser: this.selectedDevice.deviceLookup.annotatedUser,
      notes: this.selectedDevice.deviceLookup.notes,
      status: this.selectedDevice.deviceLookup.status,
      deprovisonReason: this.selectedDevice.deviceLookup.deprovisionReason ?? undefined
    }
    this.customAnalyticEvents.logEvent('Edit Device', this.statusService?.userAuth?.getLogEventProperties());
    this.deviceInfoService.editDevice$.next(deviceToEdit);
  }
}
