
/**
 * Utility function
 * Takes a url string such as http://localhost:3000/device/:id?apiToken=:apiToken&domain=:domain&user=:userEmail
 * and a mapObj such as {id:"1",apiToken:"xyz123",domain:"gafetest.com",userEmail:"user@gafetest.com"}
 * and returns a url string such as http://localhost:3000/device/1?apiToken=xyz123&domain=gafetest.com&user=user@gafetest.com
 *
 * @param {string} url - url with ":key1", ":key2" strings to be replaced
 * @param {string} mapObj - object with {key1:value1,key2:value2} pairs to replace into the url string
 * @returns {string} processed url
 */
export const replaceValues = (url: string, mapObj: object):string => {
  const re: RegExp = new RegExp(Object.keys(mapObj).map((key: string): string => `:${key}`).join('|'), 'gi');
  const matches: RegExpMatchArray[] = [ ...url.matchAll(re) ];
  return matches.reduce((retUrl: string, matchKey: RegExpMatchArray): string=>{
    retUrl = retUrl.replace(matchKey[0], mapObj[matchKey[0].replace(':','')]);
    return retUrl
  }, url)

};
