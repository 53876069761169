import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import {
  filter,
  map,
  switchMap,
} from 'rxjs/operators';

import { EntitlementLicensingService } from '../../../../../modules/licensing/services/entitlement-licensing.service';
import { LicenseState } from '../../../../../modules/licensing/services/licensing.interfaces';
import { ErrorService } from '../../../../error/error.service';
import {
  AuthService,
} from '../../services/auth.service';
import { DBUser } from '../../services/auth.interfaces';

@Injectable({
  providedIn: 'root'
})
export class LicenseGuard implements CanActivate {

  constructor(
    private userAuth: AuthService,
    private errorService: ErrorService,
    private licenseService: EntitlementLicensingService) {
  }

  public canActivate(): Observable<boolean> {
    return this.userAuth.getActiveUser().pipe(
      filter((user: DBUser): boolean => user.isLoaded),
      switchMap((user: DBUser): Observable<boolean> =>
        this.licenseService.checkLicense()
          .pipe(
            filter((license: LicenseState): boolean => license.loaded),
            map((license: LicenseState): boolean => {
                if (license.details === 'EXPIRED') {
                  this.userAuth.logout('log out license expired').then(null, null);
                  this.errorService.throwError({
                    title: 'Your license has expired',
                    body: 'Please contact support'
                  });
                  return false;
                }
                return true;
              }
            )
          )
      )
    );
  }
}
