import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable()
export class DateUtils {
  public formatDate(originalDate: Date): string {
    const date = originalDate?.toLocaleDateString('en-US');
    const time = originalDate?.toLocaleTimeString('en-US');
    if (date && time) {
      return `${date} ${time}`;
    }
    return '';
  }

  public roundToDefaultTime(date: Date): Date {
    let difference = 60;
    let coeff = 1000 * 60 * 5;
    date.setMinutes(date.getMinutes() + difference);
    return new Date(Math.ceil(date.getTime() / coeff) * coeff);
  }

  public getNextDefaultTimeString(): string {
    return DateTime.fromJSDate(this.roundToDefaultTime(new Date())).toFormat('H:m a');
  }
}
