import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../../core/modules/material/material.module';
import { CoModule } from '../common/co.module';
import { LicensingBarComponent } from './components/licensing-bar/licensing-bar.component';
import { LicensingTierDirective } from './components/licensing-renewal-dialog/directive/licensing-tier.directive';
import { LicensingRenewalDialogComponent } from './components/licensing-renewal-dialog/licensing-renewal-dialog.component';
import { LicensingBaseDialogComponent } from './components/licensing-renewal-dialog/tiers/base/licensing-base-dialog.component';
import { LicensingConsultantDialogComponent } from './components/licensing-renewal-dialog/tiers/consultant/licensing-consultant-dialog.component';
import { LicensingFreeDialogComponent } from './components/licensing-renewal-dialog/tiers/free/licensing-free-dialog.component';
import { LicensingLapsedDialogComponent } from './components/licensing-renewal-dialog/tiers/lapsed/licensing-lapsed-dialog.component';
import { LicensingPaidDialogComponent } from './components/licensing-renewal-dialog/tiers/paid/licensing-paid-dialog.component';
import { LicensingTrialDialogComponent } from './components/licensing-renewal-dialog/tiers/trial/licensing-trial-dialog.component';
import { LicensingUnlicensedDialogComponent } from './components/licensing-renewal-dialog/tiers/unlicensed/licensing-unlicensed-dialog.component';
import { LicensingTesterComponent } from './components/licensing-tester/licensing-tester.component';


@NgModule({
    imports: [
        RouterModule,
        CoModule,
        MaterialModule,
        FormsModule,
    ],
  declarations: [
    LicensingBarComponent,
    LicensingRenewalDialogComponent,
    LicensingTesterComponent,
    LicensingBaseDialogComponent,
    LicensingPaidDialogComponent,
    LicensingConsultantDialogComponent,
    LicensingFreeDialogComponent,
    LicensingLapsedDialogComponent,
    LicensingUnlicensedDialogComponent,
    LicensingTrialDialogComponent,
    LicensingTierDirective
  ],
  exports: [
    LicensingBarComponent,
    LicensingBaseDialogComponent,
    LicensingPaidDialogComponent,
    LicensingConsultantDialogComponent,
    LicensingFreeDialogComponent,
    LicensingLapsedDialogComponent,
    LicensingUnlicensedDialogComponent,
    LicensingTrialDialogComponent,
  ],
  // entryComponents: [],
  providers: [
    DatePipe
  ]
})
export class ToolLicensingModule { }
