import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';

import { LicenseGuard } from './core/modules/auth/guards/license-guard/license.guard';

const routes: Routes = [
  { path: '',
    loadChildren: (): Promise<any> => import('./layouts/external/external.module')
    .then((m: any): any => m.ExternalModule) },
  {
    path: 'home',
    loadChildren: (): Promise<any> => import('./layouts/internal/internal.module')
      .then((m: any): any => m.InternalModule),
    canActivate: [ LicenseGuard ]
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule{}
