import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as firebase from 'firebase/compat';
import { environment } from 'src/environments/environment';

import { AuditLogMessage } from '@cdw-ae/common-data';
import { DBUser } from '../modules/auth/services/auth.interfaces';

type ProductCode = 'ao' | 'g4c' | 'gb';
type Action = 'login' | 'log out' | 'poor battery health' | 'export' | 'bulk edit job' | 'sync device with user ou';
type ActionId = 1 | 2;
type Type = 'authentication' | 'reports' | 'export' | 'jobs';
type TypeId = 1 | 2 | 3 | 4;

interface LogMessage {
  type: Type,
  action: Action,
  data: string,
  customerId: string | null,
  userId: string,
  actionId: ActionId,
  typeId: TypeId,
  productCode: ProductCode,
  created_on: string
}

   // data prototype
  // user is admin, sucessful login
  // {success:true,deviceCacheReady:true}
  // user is not admin, sucessful login
  // {success:true,deviceCacheReady:false}
  // user is not found in firestore
  // {success:false,deviceCacheReady:false}
  // log out
 // {success:true}

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  private productCode: ProductCode = 'g4c';

  private typeMap: {[key in Type]: TypeId } = {
    authentication: 1,
    reports: 2,
    export: 3,
    jobs: 4,
  };

  private actionMap: {[key in Action]: ActionId } = {
    'log out': 1,
    login: 2,
    'poor battery health': 1,
    export: 1,
    'bulk edit job': 1,
    'sync device with user ou': 2,
  };

  constructor(
    private http: HttpClient
  ) { }

  public logAuth(
    action:Action,
    data:any,
    user:DBUser | firebase.default.User
  ): void {

    const type: Type = 'authentication';
    const { userId, customerId }: { userId: string; customerId: string; } = this._getUserInformation(user);

    const auditLogDate: Date = new Date();

    const logMessage : LogMessage = {
      type,
      action,
      data: JSON.stringify(data),
      customerId,
      userId,
      actionId: this.actionMap[action],
      typeId: this.typeMap[type],
      productCode: this.productCode,
      created_on: auditLogDate.toISOString().slice(0, 19).replace('T', ' ')
    };

    this.log(logMessage, auditLogDate);
  }

  public logReportCreation(
    action: Action,
    data: any,
    user: DBUser | firebase.default.User,
  ): void {
    const type: Type = 'reports';
    const { userId, customerId }: { userId: string; customerId: string; } = this._getUserInformation(user);
    const auditLogDate: Date = new Date();

    const logMessage : LogMessage = {
      type,
      action,
      data: JSON.stringify(data),
      customerId,
      userId,
      actionId: this.actionMap[action],
      typeId: this.typeMap[type],
      productCode: this.productCode,
      created_on: auditLogDate.toISOString().slice(0, 19).replace('T', ' ')
    };

    this.log(logMessage, auditLogDate);
  }

  public logExportCreation(
    action: Action,
    data: any,
    user: DBUser | firebase.default.User,
  ): void {
    const type: Type = 'export';
    const { userId, customerId }: { userId: string; customerId: string; } = this._getUserInformation(user);

    const auditLogDate: Date = new Date();

    const logMessage : LogMessage = {
      type,
      action,
      data: JSON.stringify(data),
      customerId,
      userId,
      actionId: this.actionMap[action],
      typeId: this.typeMap[type],
      productCode: this.productCode,
      created_on: auditLogDate.toISOString().slice(0, 19).replace('T', ' ')
    };

    this.log(logMessage, auditLogDate);
  }

  public logJobExecution(
    action:Action,
    data:any,
    user:DBUser | firebase.default.User
  ): void {

    const type: Type = 'jobs';
    const { userId, customerId }: { userId: string; customerId: string; } = this._getUserInformation(user);

    const auditLogDate: Date = new Date();

    const logMessage : LogMessage = {
      type,
      action,
      data: JSON.stringify(data),
      customerId,
      userId,
      actionId: this.actionMap[action],
      typeId: this.typeMap[type],
      productCode: this.productCode,
      created_on: auditLogDate.toISOString().slice(0, 19).replace('T', ' ')
    };

    this.log(logMessage, auditLogDate);
  }

  private log(logMessage: LogMessage, auditLogDate: Date) : void {
    const url: string = `${environment.urls.services.auditApi}/audit-log/create`;

    const httpOptions: { headers: HttpHeaders } = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    const auditLogApiLogMessage: AuditLogMessage = {
      dt: auditLogDate,
      userId: logMessage.userId,
      category: logMessage.type,
      typeId: logMessage.typeId,
      area: logMessage.productCode,
      action: logMessage.action,
      actionId: logMessage.actionId,
      actionData: logMessage.data,
    }

    this.http.post(url, auditLogApiLogMessage, httpOptions)
      // need this subscribe or else this.http.post will not execute
      .subscribe();
  }

  private _getUserInformation(user: DBUser | firebase.default.User): { userId: string; customerId: string; } {
    let userId: string | null = null;
    let customerId: string | null = null;


    if(user) {
    if ('profile' in user){
      customerId = user.profile?.customerId;
      userId = user.profile?.email;
    } else if ('email' in user) {
      userId = user.email;
    }
  }

    return { userId, customerId };
  }
}

// SQL schema for audits table:
// id: int, pk
// created_on: timestamp
// type: varchar(256) (Authentication)
// action: varchar(256) (log out, login)
// data: longtext (json information about the event, for example { success: true })
// customerId: int (the customers gId) -> updated to VARCHAR(256) B.G. 12-10-21
// userId: int (the user’s email) -> updated to VARCHAR(256) B.G. 12-10-21
// actionId: int (1 for log out, 2 for login)
// typeId: int (1 for Authentication)
// productCode: enum('g4c', ‘ao’, 'gb')

// ao → Add on (for the future)
// gb → Gopher Buddy (inc. setup webapp)
// g4c → Gopher for Chrome webapp
