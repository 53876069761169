import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  Observable,
  throwError
} from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ErrorService } from '../error/error.service';
import {environment} from '../../../environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorService: ErrorService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(
        (error: HttpErrorResponse): Observable<HttpEvent<any>> => {
          let errorMsg: string = '';
          if(error.url.startsWith(environment.urls.services.tokenValidation)){
            return throwError(errorMsg);
          }
          if (error.error instanceof ErrorEvent) {
            // client side
            errorMsg = `Error: ${error.error.message}`;
          } else {
            // server side
            errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
          }
          this.errorService.throwError({ title: 'An error occured', body: errorMsg });
          return throwError(errorMsg);
        })
    );
  }
}
