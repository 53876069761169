import { Injectable } from '@angular/core';
import { CdwSearchGridComponentState, ICdwSaveComponentStateService } from '@cdw-ae/common-ui-components-search-grid';
import { Store } from '@ngrx/store';
import { DeviceQueryResponseRecord } from '../../../modules/device/interfaces/deviceInfo.interfaces';
import { G4CAppState } from '../../app-state.interface';
import { saveComponentState } from '../app-component-state.actions';
import { AppComponentState } from '../app-component-state.reducer';
import { selectAppComponentState } from '../app-component-state.selectors';
import { ComponentId } from '../interface/component-id.interface';

@Injectable({
  providedIn: 'root'
})
export class AppComponentStateService implements ICdwSaveComponentStateService<CdwSearchGridComponentState<DeviceQueryResponseRecord>> {

  private appComponentStateSlice: AppComponentState;

  constructor(private appState: Store<G4CAppState>) { 
    this.appState.select(selectAppComponentState).subscribe((appComponentStateSlice: AppComponentState): void => {
      this.appComponentStateSlice = appComponentStateSlice;
    });
  }

  public saveComponentState(componentId: ComponentId, state: CdwSearchGridComponentState<DeviceQueryResponseRecord>): void {
    this.appState.dispatch(saveComponentState({ componentId, componentState: state }));
  }

  public retrieveComponentState(componentId: ComponentId): CdwSearchGridComponentState<DeviceQueryResponseRecord> {
    return this.appComponentStateSlice[componentId];
  }

}
