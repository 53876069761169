<div mat-dialog-content class="gap-[24px] flex flex-col justify-start items-center">

  <img src="{{dialogData.tool.logo}}" alt="{{dialogData.tool.name}} Logo" height="120px">

  <div class="license-info-panel flex flex-col justify-start items-center" [ngClass]="{'alert-red' : dialogData.alertLevel === 2, 'alert-strong' : dialogData.alertLevel === 2}">
    <p>{{dialogData.expiryMessage}}</p>
    <p>You are currently using Gopher for Chrome FREE</p>
  </div>

  <div *ngIf="dialogData.displayInfoBlock" class="license-info-block flex flex-col justify-start items-center">
    <p>Did you want to unlock the full potential of {{dialogData.tool.name}}? If so, upgrade to a paid {{dialogData.tool.name}} license by requesting a quote below.</p>

    <ul>
      <li>Get UNLIMITED actions</li>
      <li>Start making bulk updates to your devices</li>
    </ul>

    <button *ngIf="dialogData.controls.showRenewButton" [ngClass]="{disabled: !(isAdmin$ | async)}" class="renew-button" mat-raised-button [disabled]="!(isAdmin$ | async)" (click)="requestQuote()">
      <span [matTooltip]="!(isAdmin$ | async)?'This is only available to Super admins':null">UPGRADE LICENSE</span>
    </button>
  </div>
</div>

<div [class]="dialogData.showFooterLeft ? 'flex justify-between items-center' : 'flex justify-end items-center'" mat-dialog-actions>
  <mat-checkbox *ngIf="dialogData.controls.showReminderCheckbox" (change)="toggleReminder($event)" [(ngModel)]="muteNotification">Dismiss and remind me in 14 days</mat-checkbox>
  <button *ngIf="!dialogData.controls.showLogout" mat-raised-button class="grey-button" (click)="closeDialog()">{{muteNotification ? 'SAVE & CLOSE' : 'CLOSE'}}</button>
</div>
