import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject, merge, switchMap, take, takeUntil } from 'rxjs';
import { CustomAnalyticEventsService } from 'src/app/core/Services/customAnalyticEvents.service';
import { AuthService } from 'src/app/core/modules/auth/services/auth.service';
import { DeviceInfoService } from 'src/app/modules/device/services/device-info/device-info.service';
import { environment } from 'src/environments/environment';
import { IChromeAuditEventResponse, IChromeAuditLog } from '../../../models/chrome-audit-event.model';
import { DeviceInfo } from '../../../models/device-info.model';

declare let google: any;

@Component({
  selector: 'g4c-chrome-audit-events',
  templateUrl: './chrome-audit-events.component.html',
  styleUrls: [ './chrome-audit-events.component.scss' ]
})
export class ChromeAuditEventsComponent implements OnInit, OnDestroy {

  @Input() private tabActivated$: Observable<void>;

  public retry$: Subject<void> = new Subject();
  public tableHeaders: Array<string> = [ 'eventType', 'date', 'reason', 'user', 'description' ];
  public currentPage: number = 0;
  public pageSize: number = 10;
  public dataSource: IChromeAuditLog [];
  public startIndex: number = 0;
  public endIndex: number = this.pageSize;
  public selectedDevice : DeviceInfo;
  public isSuperAdmin = false;
  public retryButtonHidden = true;
  public noAuditEventMessage: string;
  public authorizeGoogleButtonHidden = true;
  private onDestroy$: Subject<void> = new Subject<void>();
  private REQUIRED_SCOPES: string = 'https://www.googleapis.com/auth/admin.reports.audit.readonly';

  constructor(
    private deviceInfoService: DeviceInfoService,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private ngZone: NgZone,
    private errorDialog: MatDialog,
    private analytics: CustomAnalyticEventsService,) {}



  public ngOnInit(): void {
    this.authService.isSuperAdmin$.pipe(take(1)).subscribe((isSuperAdmin: boolean): void => {
      if (isSuperAdmin) {
        this.isSuperAdmin = true;
      }
    });

    merge(this.tabActivated$.pipe(take(1)), this.retry$).pipe(
      switchMap((): Observable<DeviceInfo> => this.deviceInfoService.selectedDevice$),
      switchMap((deviceInfo: DeviceInfo): Observable<IChromeAuditEventResponse> => this.deviceInfoService.getAuditLogs(deviceInfo.deviceLookup.deviceId)),
      takeUntil(this.onDestroy$)
    ).subscribe((res: IChromeAuditEventResponse): void => {
      this.handleAuditLogsResponse(res);
    });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }



  public handlePage(e: any): void {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;

    this.endIndex = (this.currentPage + 1) * this.pageSize;
    this.startIndex = this.currentPage * this.pageSize;
  }

  public isDataSourceEmpty(): boolean {
    if (this.dataSource) {
      if (Object.keys(this.dataSource).length === 0) {
        if (!this.noAuditEventMessage) {
          this.noAuditEventMessage = 'No event data found for this device';
        }
        return true;
      }
    }
    return false;
  }

  public async provideAdditionalScope(): Promise<void> {
    const isCustomerRefreshTokenValid: boolean = await this.authService.verifyRefreshToken(false);
    if(!isCustomerRefreshTokenValid) {
      await this.authService.launchCustomerTokenDialog(this.isSuperAdmin,true);
    }
  }

  public retry(): void {
    this.noAuditEventMessage = '';
    this.dataSource = undefined;
    this.retryButtonHidden = true;
    this.authorizeGoogleButtonHidden = true;
    this.retry$.next();
  }

  private handleAuditLogsResponse(res : IChromeAuditEventResponse): void {
      if (res.success === false) {
        if (this.isSuperAdmin) {
          this.noAuditEventMessage = 'Unable to load chrome device audit logs. An additional API scope is required, please click the button below to provide the additional scope.'
          this.authorizeGoogleButtonHidden = false;
        }
        // not super admin and one of the two following errors
        else if(res.message === 'Error: Insufficient Permission' || res.message === 'Authorization Failed' || res.message === 'Error: Access denied. You are not authorized to read activity records.') {
            this.noAuditEventMessage = 'Unable to load chrome device audit logs. Please ask a Google Workspace super admin to re-auth Gopher for Chrome...';
        }
        // otherwise should be a glitch and show edit button
        else {
          this.retryButtonHidden = false;
          this.noAuditEventMessage = 'Please try again';
        }
        this.dataSource = [];
      }
      else {
        this.dataSource = Array.isArray(res.auditLogs) ? res.auditLogs : [];
      }
  }

}
