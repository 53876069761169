import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml', pure: false })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  public transform(content: string):  SafeHtml  {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
}
