import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { Observable, Subject, takeUntil } from 'rxjs';
import { DeviceInfoService } from 'src/app/modules/device/services/device-info/device-info.service';

import { DeviceInfo } from '../../../models/device-info.model';
import { DeviceTelemetry } from '../../../models/device-telemetry.model';

@Component({
  selector: 'g4c-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: [ './additional-info.component.scss' ]
})
export class AdditionalInfoComponent implements OnInit {
  @Input()
  public deviceTelemetry: Observable<DeviceTelemetry>;
  public selectedDevice : DeviceInfo;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private deviceInfoService: DeviceInfoService) {}

  public ngOnInit(): void {
    this.deviceInfoService.selectedDevice$.pipe(takeUntil(this.onDestroy$)).subscribe((device) => {
      if (device) {
        this.selectedDevice = device;
       }
    })
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  public milsToMins(activeTime: number): number {
    return Math.floor(activeTime / (60*1000));
  }

  public validateDate(date : string) : string {
    if (date === '1970-01-01T00:00:00.000Z') {
      return null;
    }
      return date;
  } 
}
