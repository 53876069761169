import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode, NgZone, OnDestroy } from '@angular/core';
import { FirebaseApp, initializeApp } from '@angular/fire/app';
import {
  Auth,
  AuthProvider,
  getAuth,
  GoogleAuthProvider,
  signInWithCredential,
  signInWithPopup,
  UserCredential,
} from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  Action,
  AngularFirestore,
  DocumentChangeAction,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Query,
} from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import _ from 'lodash';
import { BehaviorSubject, firstValueFrom, from, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { ErrorDialogComponent } from 'src/app/core/error/error-dialog.component';
import { ErrorService } from 'src/app/core/error/error.service';
import { AuditService } from 'src/app/core/Services/audit.service';
import { CustomAnalyticEventsService } from 'src/app/core/Services/customAnalyticEvents.service';
import { LocalStorageService } from 'src/app/core/Services/local-storage.service';
import { UserEncodingService } from 'src/app/core/Services/user-encoding.service';
import { LoadingService } from 'src/app/modules/common/services/loading.service';
import { Customer, Features } from 'src/app/modules/status/status';
import { environment } from 'src/environments/environment';
// TODO: This appears to be a circular dependency that needs to be fixed
// eslint-disable-next-line import/no-cycle
import { OfflineTokenAuthDialogComponent } from '../components/offline-token-auth-dialog/offline-token-auth-dialog.component';
import {
  ClaimsMap,
  CustomerIDLookupResponse,
  DBUser,
  DBUserProfile,
  LogEventProperties,
  LoginData,
  LogoutMessage,
  OAuthResponse,
} from './auth.interfaces';

const PUBLIC_ROUTES: string[] = [ '/welcome-to-gopher-buddy', '/support' ];

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  public user: firebase.User | null;
  public userFirebaseIdToken: string;
  public dbUser$: ReplaySubject<DBUser> = new ReplaySubject<DBUser>(1);

  public ssoLogin: boolean = false;
  public isSuperAdmin$: Observable<boolean> = this.dbUser$
    .asObservable()
    .pipe(map((user: DBUser): boolean => user?.profile?.isAdmin));
  public userIsLoaded$: Observable<boolean> = this.dbUser$
    .asObservable()
    .pipe(map((user: DBUser): boolean => user?.isLoaded));

  private cgUserCreds$: BehaviorSubject<UserCredential> = new BehaviorSubject<UserCredential>(null);
  private gbUserCreds$: BehaviorSubject<UserCredential> = new BehaviorSubject<UserCredential>(null);
  private followOnUrlPath: string;
  private followOnUrlParams: any;
  private tosZendeskUrl: string = 'https://amplifiedlabs.zendesk.com/hc/en-us/categories/203616448-Gopher-for-Chrome-';
  private domain: string;
  private uid: string;
  private uuid: string;
  private workspaceId: string;
  private onLogout$: Subject<void> = new Subject<void>();
  private TOKEN_VERIFICATION_URL: string = environment.urls.services.tokenValidation;
  private onDestroy$: Subject<void> = new Subject();
  // private CHROME_PROFILE_WIPE_SCOPE: string = 'https://www.googleapis.com/auth/admin.reports.audit.readonly';

  constructor(
    private ngZone: NgZone,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private afCF: AngularFireFunctions,
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient,
    private errorService: ErrorService,
    private auditService: AuditService,
    private analytics: CustomAnalyticEventsService,
    private matDialog: MatDialog,
    private loadingService: LoadingService,
    private localStorage: LocalStorageService,
    private userEncodingService: UserEncodingService,
  ) {

    this.route.queryParams.subscribe(async (params: Params): Promise<void> => {
      if ('q' in params && !this.isPublicRoute(this.router.url)) {
        try {
          this.ssoLogin = true;
          this.followOnUrlPath = params.followon;
          const authReq: { token: string } = JSON.parse(window.atob(params.q as string));
          await this.afAuth.setPersistence('session');
          await this.afAuth.signInWithCustomToken(authReq.token).catch((): void => {
            this.ssoLogin = false;
            this.errorService.throwError({
              title: 'Sorry but...',
              body: 'Your session has expired. Please log in.',
            });
          });
        } catch (error) {
          this.ssoLogin = false;
          this.errorService.throwError({
            title: 'Sorry but...',
            body: 'You session has expired. Please log in.',
          });
        }
      }
    });

    const devDomains: Array<string> = [
      'easton-consulting.com',
      'gafetest.com',
      'gedu.demo.amplifiedit.com',
      'jefferson.kyschools.us',
      'amaisd.org',
      'C04cu9ga4',
      'peterlee.dev',
      'slrsd.org',
      'anexampleschool.com'
    ];

    // when a user signs in we need to get their user info from firebase docs
    this.afAuth.authState.pipe(takeUntil(this.onDestroy$)).subscribe(async (user: firebase.User): Promise<any> => {
      // No need to try to log the user in if they are on the support page
      if (this.isPublicRoute(this.router.url)) {
        return null;
      }

      this.user = user;
      if (user && user.providerData[0]?.providerId === 'google.com') {
        from(this.user.getIdToken()).subscribe((firebaseIdToken: string): void => {
          this.userFirebaseIdToken = firebaseIdToken;
        })
        const userDomain: string = user?.email?.split('@')[1];
        if (user && isDevMode() && !devDomains.includes(userDomain)) {
          this.auditService.logAuth('login', { success: false, deviceCacheReady: false }, user);
          this.errorService.throwError({
            title: 'Unable to access Gopher for Chrome',
            body: 'You are unable to access the Gopher for Chrome device cache application at this time...',
          });
          return this.logout('log out account not found');
        }
        return this.getUserRecordFromGoogleLogin(user);
      }

      if (user && !user.providerData.length) {
        return this.getUserRecordFromCustomLogin(user);
      }

      this.user = null;
      await this.router.navigate([ '/' ], { queryParamsHandling: 'preserve' });
      return this.dbUser$.next({
        ref: null,
        profile: null,
        loginDomain: null,
        isLoaded: false,
      });
    });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  public getLogEventProperties(): LogEventProperties {
    return {
      uid: this.uuid,
      cd1: this.domain,
      uuid: this.uuid,
      workspaceId: this.workspaceId,
    };
  }

  public async getUserRecordFromCustomLogin(afUser: firebase.User): Promise<void> {
    const token: firebase.auth.IdTokenResult = await afUser.getIdTokenResult(true);
    this.afs
      .doc<DBUser>(`customers/${token.claims.customerId as string}/users/${token.claims.uid as string}`)
      .snapshotChanges()
      .pipe(
        take(1),
        map(
          (
            userChange: Action<DocumentSnapshot<DBUser>>,
          ): {
            loginSuccess?: boolean;
            ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
            profile: DBUserProfile;
          } => {
            if (!userChange.payload.exists) {
              this.auditService.logAuth('login', { success: false, deviceCacheReady: false }, this.user);

              this.errorService.throwError({
                title: 'Unable to access Gopher for Chrome',
                body: 'Your user account wasn\'t found. Please access the Gopher for Chrome Add-on and try again.',
              });
              return {
                loginSuccess: false,
                ref: null,
                profile: null,
              };
            }
            return {
              ref: userChange.payload.ref,
              profile: userChange.payload.data().profile,
            };
          },
        ),
        switchMap(
          (dbUser: DBUser): Observable<any> =>
            this.afs
              .doc(`customers/${dbUser.profile.customerId}`)
              .get()
              .pipe(
                map((customerDataResp: DocumentSnapshot<unknown>): LoginData => {
                  const customerData: any = customerDataResp.data();
                  dbUser.loginDomain = customerData.domain;
                  return {
                    dbUser,
                    TOS_isSigned: customerData.TOS === 'accepted',
                  };
                }),
              ),
        ),
      )
      .subscribe(async (loginData: LoginData): Promise<void> => {
        if (!loginData.TOS_isSigned) {
          this.auditService.logAuth('login', { success: true, deviceCacheReady: false }, loginData.dbUser);

          this.errorService.throwError({
            title: 'Unable to access Gopher for Chrome',
            // eslint-disable-next-line max-len
            body: `<div><p>Before you can access this site, a super admin from your domain must enable the device cache in the companion Google Sheet Add-on.</p>
                  <a target="_blank" href="${this.tosZendeskUrl}">Please visit our helpdesk for more information</a>
                  </div>`,
          });
          return this.logout('log out device cache not set');
        }

        if (loginData.dbUser.loginSuccess === false) {
          this.auditService.logAuth('login', { success: false }, this.user);
          this.ssoLogin = false;
          return this.logout('log out account not found');
        }
        this.auditService.logAuth('login', { success: true, deviceCacheReady: true }, loginData.dbUser);
        loginData.dbUser.isLoaded = true;
        this.dbUser$.next(loginData.dbUser);
        this.initialiseAnalytics(loginData);

        if (this.followOnUrlPath) {
          await this.router.navigate([ this.followOnUrlPath ], { queryParams: this.followOnUrlParams });
          this.ssoLogin = false;
          this.followOnUrlPath = null;
          this.followOnUrlParams = null;
        } else if (window.location.pathname === '/') {
          await this.router.navigate([ '/home' ], {
            queryParams: {
              q: null,
              testLogin: null,
            },
            queryParamsHandling: 'merge',
          });
          this.ssoLogin = false;
        }
        return null;
      });
  }

  public getUserRecordFromLogin(snapshotChanges$: Observable<DocumentChangeAction<DBUser>[]>): void {
    snapshotChanges$
      .pipe(
        take(1),
        map(
          (
            docs: Array<DocumentChangeAction<DBUser>>,
          ): {
            ref: DocumentReference<DocumentData>;
            profile: DBUserProfile;
          } => {
            if (!docs.length || docs.length === 0) {
              return null;
            }
            return docs.map(
              (
                doc: DocumentChangeAction<DBUser>,
              ): {
                ref: DocumentReference<DocumentData>;
                profile: DBUserProfile;
              } => ({
                ref: doc.payload.doc.ref,
                profile: doc.payload.doc.data().profile,
              }),
            )[0];
          },
        ),
        switchMap(async (dbUser: DBUser): Promise<DBUser> => {
          // Add customerId to user claims
          try {
            await this.hasClaims({ customerId: dbUser?.profile.customerId }, true);
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Unable to set required claims on user', error);
          }
          return dbUser;
        }),
        switchMap((dbUser: DBUser): Observable<LoginData> => {
          if (!dbUser) {
            return of({
              TOS_isSigned: false,
              dbUser: null,
            });
          }
          return this.afs
            .doc(`customers/${dbUser.profile.customerId}`)
            .get()
            .pipe(
              map((customerDataResp: DocumentSnapshot<unknown>): LoginData => {
                const customerData: any = customerDataResp.data();
                dbUser.loginDomain = customerData.domain;
                return {
                  dbUser,
                  TOS_isSigned: customerData.TOS === 'accepted',
                };
              }),
            );
        }),
      )
      .subscribe(async (loginData: LoginData): Promise<any> => {
        if (!loginData.dbUser) {
          // TODO:  This is where you would add the new customer onboarding!

          this.errorService.throwError({
            title: 'Unable to access Gopher for Chrome',
            // eslint-disable-next-line max-len
            body: `<div><p>Before you can access this site, a super admin from your domain must enable the device cache in the companion Google Sheet Add-on.</p>
                 <a target="_blank" href="${this.tosZendeskUrl}">Please visit our helpdesk for more information</a>
                 </div>`,
          });
          return this.logout('log out device cache not set');
        }

        if (!loginData.TOS_isSigned) {
          this.auditService.logAuth('login', { success: true, deviceCacheReady: false }, loginData.dbUser);

          this.errorService.throwError({
            title: 'Unable to access Gopher for Chrome',
            // eslint-disable-next-line max-len
            body: `<div><p>Before you can access this site, a super admin from your domain must accept the Terms of Service in the companion Google Sheet Add-on.</p>
                 <a target="_blank" href="${this.tosZendeskUrl}">Please visit our helpdesk for more information</a>
                 </div>`,
          });
          return this.logout('log out device cache not set');
        }

        this.auditService.logAuth('login', { success: true, deviceCacheReady: true }, loginData.dbUser);
        loginData.dbUser.isLoaded = true;
        this.dbUser$.next(loginData.dbUser);
        if (this.followOnUrlPath) {
          await this.router.navigate([ this.followOnUrlPath ], { queryParams: this.followOnUrlParams });
          this.followOnUrlPath = null;
          this.followOnUrlParams = null;
        } else if (window.location.pathname === '/') {
          await this.router.navigate([ '/home' ], {
            queryParams: {
              q: null,
              testLogin: null,
            },
            queryParamsHandling: 'merge',
          });
        }
        this.initialiseAnalytics(loginData);

        // At login validate the customer has a valid refresh token.
        const isCustomerRefreshTokenValid: boolean = await this.verifyRefreshToken(false);
        const isUserRefreshTokenValid: boolean = await this.verifyRefreshToken(true);

        this.isSuperAdmin$
          .pipe(filter((isSuperAdmin: boolean): boolean => !Object.is(isSuperAdmin, null)))
          .pipe(take(1))
          .subscribe(async (isSuperAdmin: boolean): Promise<void> => {

            if (!isCustomerRefreshTokenValid) {
              await this.launchCustomerTokenDialog(isSuperAdmin, isUserRefreshTokenValid); // only show logout if the user token is valid
              if(isSuperAdmin){
                return; // when the customer token is update the update user token is also
              }
            }

            if (!isUserRefreshTokenValid) {
                await this.launchUserTokenDialog();
             }
          });
        return null;
      });
  }

  public getUserRecordFromGoogleLogin(afUser: firebase.User): void {
    const userInfo: firebase.UserInfo = afUser.providerData[0];
    const snapshotChanges$: Observable<DocumentChangeAction<DBUser>[]> = this.afs
      .collectionGroup<DBUser>(
        'users',
        (ref: Query<DBUser>): Query<DBUser> => ref.where('profile.id', '==', userInfo.uid).limit(1),
      )
      .snapshotChanges();

    this.getUserRecordFromLogin(snapshotChanges$);
  }

  public getUserRecordFromPasswordLogin(afUser: firebase.User): void {
    const userInfo: firebase.UserInfo = afUser.providerData[0];
    const snapshotChanges$: Observable<DocumentChangeAction<DBUser>[]> = this.afs
      .collectionGroup<DBUser>(
        'users',
        (ref: Query<DBUser>): Query<DBUser> => ref.where('profile.email', '==', userInfo.email).limit(1),
      )
      .snapshotChanges();

    this.getUserRecordFromLogin(snapshotChanges$);
  }

  public initialiseAnalytics(loginData: LoginData): void {
    if (loginData.dbUser && loginData.TOS_isSigned) {
      const { profile }: DBUser = loginData.dbUser;
      const { id, email, customerId }: { id: string; email: string; customerId: string } = profile;
      this.workspaceId = customerId;
      this.uid = id;
      this.domain = email.split('@').pop();
      this.userEncodingService
        .encodeUser(email)
        .pipe(take(1))
        .subscribe((encodedUser: string): void => {
          this.uuid = encodedUser;
          this.analytics.setUserProperties({
            customerId,
            domain: this.domain,
            uuid: id,
            encodedUser: this.uuid, // this is the encoded user
          });
        });
    }
  }

  public getLogoutTrigger(): Observable<void> {
    return this.onLogout$.asObservable();
  }

  public async logout(
    logoutMessage: LogoutMessage = 'log out',
    path: string = null,
    pathParams: Array<string> = null,
    bypassNav: boolean = false,
  ): Promise<any> {
    this.followOnUrlPath = path;
    this.followOnUrlParams = pathParams;
    this.analytics.logEvent('webapp_logout', this.getLogEventProperties());
    this.dbUser$.pipe(take(1)).subscribe((dbUser: DBUser): void => {
      if (dbUser?.profile) {
        this.auditService.logAuth('log out', { success: true, message: logoutMessage }, dbUser);
      } else {
        this.auditService.logAuth('log out', { success: true, message: logoutMessage }, this.user);
      }
    });

    await this.ngZone.run(async (): Promise<void> => {
      this.user = null;
      this.userFirebaseIdToken = undefined;
      this.dbUser$.next(undefined);
      this.onLogout$.next();
      await this.afAuth.signOut();
      if (!bypassNav) {
        await this.router.navigate([ '/' ]);
      }
    });
  }

  public async login(testLogin: boolean = false): Promise<void> {
    const cgFirebaseApp: FirebaseApp = initializeApp(environment.firebase);
    const auth: Auth = getAuth(cgFirebaseApp);

    if (testLogin) {
      const testUser = JSON.parse(this.localStorage.get('testUser'));
      // this.localStorage.remove('testUser');
      const credential = firebase.auth.GoogleAuthProvider.credential(testUser.idToken, testUser.accessToken);
      await signInWithCredential(auth, credential);
    } else {
      const initialLoginProvider: GoogleAuthProvider = new GoogleAuthProvider();
      // login will prompt for only identity scope
      await signInWithPopup(auth, initialLoginProvider);
    }
  }

  public async launchCustomerTokenDialog(isSuperAdmin: boolean = false, showLogout: boolean = false ): Promise<void> {

    if (isSuperAdmin) {
      const customerFeatures: string[] = await firstValueFrom(this.getCustomerFeaturesScopes())
      this.matDialog
        .open(OfflineTokenAuthDialogComponent, {
          data: {
            // eslint-disable-next-line max-len
            message:
              '<p>The authentication token for your <strong>domain</strong> is invalid or expired. <br />Do you want to update it?</p>',
            logEventProperties: this.getLogEventProperties(),
            additionalScopes: customerFeatures,
          },
          panelClass: 'ait-confirmation',
          maxHeight: '100%',
          width: '540px',
          maxWidth: '100%',
          disableClose: true,
          hasBackdrop: true,
        })
        .afterClosed()
        .subscribe(async (authCode: string): Promise<void> => {
          if (authCode) {
            // i should have a authcode after returning from dialog and pass it down to the updatecustomer refresh token method
            const result: boolean = await this.updateOfflineToken(authCode, true);
            if (result) {
              this.analytics.logEvent('customer_token_refreshed', this.getLogEventProperties());
            } else {
              this.analytics.logEvent('customer_token_refresh_failed', this.getLogEventProperties());
            }
          }
        });
    } else {
      // Display dialog informing user to have a super admin login and update refresh token
      this.matDialog.open(ErrorDialogComponent, {
        data: {
          // eslint-disable-next-line max-len
          error: {
            title: 'Looks like something went wrong...',
            body: `<p>The authentication token for your <strong>domain</strong> is invalid or expired.<br />
                   Please login with a Super Admin account and update it.<br />
               <a target="_blank" href="${this.tosZendeskUrl}">Please visit our helpdesk for more information.</a></p>`,
          },
          disableCloseBtn: true, // TODO CHANGE THIS TO enableCloseBtn: false
          enableLogoutBtn: showLogout,
        },
        panelClass: 'ait-confirmation',
        maxHeight: '100%',
        width: '540px',
        maxWidth: '100%',
        disableClose: true,
        hasBackdrop: true,
      });
    }


  }


  public async launchUserTokenDialog(): Promise<void> {
    // if logged in user is a super admin, we allow them to update the refresh token

      this.matDialog
        .open(OfflineTokenAuthDialogComponent, {
          data: {
            // eslint-disable-next-line max-len
            message:
              '<p>Your <strong>user</strong> authentication token is invalid or expired. <br />Do you want to update it?</p>',
            logEventProperties: this.getLogEventProperties(),
            additionalScopes: [],
            isDomain: false,
          },
          panelClass: 'ait-confirmation',
          maxHeight: '100%',
          width: '540px',
          maxWidth: '100%',
          disableClose: true,
          hasBackdrop: true,
        })
        .afterClosed()
        .subscribe(async (authCode: string): Promise<void> => {
          if (authCode) {
            // i should have a authcode after returning from dialog and pass it down to the updatecustomer refresh token method
            const result: boolean = await this.updateOfflineToken(authCode, false);
            if (result) {
              this.analytics.logEvent('user_token_refreshed', this.getLogEventProperties());
            } else {
              this.analytics.logEvent('user_token_refresh_failed', this.getLogEventProperties());
            }
          }
        });
  }

  public getCustomerRefreshToken(
    scopes: string = 'https://www.googleapis.com/auth/admin.directory.orgunit.readonly',
  ): Observable<string> {
    const cgFirebaseApp: FirebaseApp = initializeApp(environment.firebase);
    const auth: Auth = getAuth(cgFirebaseApp);
    const googleProvider: GoogleAuthProvider = new GoogleAuthProvider();
    googleProvider.addScope(scopes);
    googleProvider.setCustomParameters({ grantType: 'offline' });

    return this.handleTokenCheck([ auth, googleProvider ], this.cgUserCreds$);
  }

  public async updateOfflineToken(authCode: string, isCustomerToken: boolean): Promise<boolean> {
    this.loadingService.loading$.next(true);
    try {
      const result = await this.afCF
        .httpsCallable('auth-updateOfflineToken')({
          customerId: this.workspaceId,
          authCode,
          tokenTag: '',
          isCustomerToken,
          email: this.user.email,
        })
        .toPromise();
      this.loadingService.loading$.next(false);
      if (result) {
        return true;
      }
      return false;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Unable to update customer refresh token');
    }
  }

  public async verifyRefreshToken(verifyUser: boolean): Promise<boolean> {
    this.loadingService.loading$.next(true);
    try {
      const validRefreshToken = await this.afCF
        .httpsCallable('auth-verifyRefreshToken')({
          customerId: this.workspaceId,
          verifyUser,
        })
        .toPromise();

      this.loadingService.loading$.next(false);
      if (validRefreshToken.success === true) {
        return true;
      }

      return false;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Unable to verify refresh token');
    }
  }

  public getCurrentUserIdToken(): Promise<string> {
    return this.afAuth.currentUser.then((user: firebase.User): Promise<string> => user.getIdToken(true));
  }

  public async getAuthForScopes(scopes: string[]): Promise<{access_token: string}> {
    const userCredentials: firebase.auth.UserCredential | UserCredential | undefined = this.cgUserCreds$.value;
    if (userCredentials && userCredentials.hasOwnProperty('additionalUserInfo')) {
      const { additionalUserInfo, credential }: { additionalUserInfo?: firebase.auth.AdditionalUserInfo; credential: firebase.auth.AuthCredential } = userCredentials as unknown as firebase.auth.UserCredential;
      const currentScopes: string[] = (additionalUserInfo as { profile?: { granted_scopes?: string } })?.profile?.granted_scopes?.split(' ');
      if(_.isEqual(scopes, currentScopes)){
        return { access_token: (credential as { accessToken?: string })?.accessToken };
      }
    }
    const provider: firebase.auth.GoogleAuthProvider = new firebase.auth.GoogleAuthProvider();
    scopes.forEach((scope: string): AuthProvider => provider.addScope(scope));
    provider.setCustomParameters({
      login_hint: this.user.email
    });
    const newUserCredential: firebase.auth.UserCredential = await this.afAuth.signInWithPopup(provider);
    this.cgUserCreds$.next(newUserCredential as unknown as UserCredential);
    return { access_token: (newUserCredential.credential as { accessToken?: string })?.accessToken };
  }

  public getCGAdminToken(
    scopes: string = 'https://www.googleapis.com/auth/admin.directory.orgunit.readonly',
  ): Observable<string> {
    const cgFirebaseApp: FirebaseApp = initializeApp(environment.firebase);
    const auth: Auth = getAuth(cgFirebaseApp);
    const googleProvider: GoogleAuthProvider = new GoogleAuthProvider();
    googleProvider.addScope(scopes);
    return this.handleTokenCheck([ auth, googleProvider ], this.cgUserCreds$);
  }

  public getGBAdminToken(
    scopes: string = 'https://www.googleapis.com/auth/admin.directory.domain.readonly',
  ): Observable<string> {
    const gbFirebaseApp: FirebaseApp = initializeApp(environment.gopher_buddy_admin_panel.firebase, 'gb_app');
    const auth: Auth = getAuth(gbFirebaseApp);
    const googleProvider: GoogleAuthProvider = new GoogleAuthProvider();
    googleProvider.addScope(scopes);
    googleProvider.setCustomParameters({ prompt: 'consent' });
    return this.handleTokenCheck([ auth, googleProvider ], this.gbUserCreds$);
  }

  public async hasClaims(claims: ClaimsMap, addMissingClaims: boolean = false): Promise<boolean> {
    const lockedClaims: string[] = [ 'aud', 'auth_time', 'exp', 'iat', 'iss', 'sub', 'firebase' ];
    const userClaimToken: firebase.auth.IdTokenResult = await this.user.getIdTokenResult();
    const missingClaims: ClaimsMap = Object.keys(claims).reduce((missingAcc: ClaimsMap, key: string): ClaimsMap => {
      if (!userClaimToken.claims.hasOwnProperty(key) || userClaimToken.claims[key] == null /* == will also check for undefined */) {
        missingAcc[key] = claims[key];
      }
      return missingAcc;
    }, {});

    // Get all existing claims so that we can upsert the new
    const existingCustomClaims: ClaimsMap = Object.keys(userClaimToken.claims).reduce(
      (customAcc: ClaimsMap, key: string): ClaimsMap => {
        if (!lockedClaims.includes(key)) {
          customAcc[key] = userClaimToken.claims[key];
        }
        return customAcc;
      },
      {},
    );

    if (Object.keys(missingClaims).length > 0 && addMissingClaims) {
      try {
        await this.afCF
          .httpsCallable('auth-addClaims')({
            claims: Object.assign(existingCustomClaims, missingClaims),
          })
          .toPromise();

        // force refresh so claims can be used in this session
        await this.user.getIdTokenResult(true);
        return true;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Unable to add custom token claims');
        return false;
      }
    } else if (Object.keys(missingClaims).length === 0) {
      return true;
    } else {
      return false;
    }
  }

  public getActiveUser(): Observable<DBUser> {
    return this.dbUser$.asObservable();
  }

  public getCustomerFeaturesScopes(): Observable<string[]> {
      return this.getCustomerFeatures().pipe(map((features: Features): string[] => Object.keys(features)
      .reduce((acc: string[], key: string): string[] => {
          if (features[key]) {
            if (typeof features[key] === 'boolean' && environment.oauthScopes.featureScopes[key]) {
              acc.push(...environment.oauthScopes.featureScopes[key] as string[]);
            }

            if(typeof features[key] === 'object'){
              Object.keys(features[key] as Record<string, string[]>).forEach((subKey: string): void => {
                if (features[key][subKey] && environment.oauthScopes.featureScopes[key][subKey]) {
                  acc.push(...environment.oauthScopes.featureScopes[key][subKey] as string[]);
                }
              })
            }

          }
          return acc;
        }, [])
        ))
  }

  public getCustomerFeatures(): Observable<Features> {
    return this.getActiveUser().pipe(switchMap((user: DBUser): Observable<Features> => {
      const { customerId }: { customerId?: string } = user?.profile ?? {};
      if (customerId) {
        return this.afs.doc(`customers/${user.profile?.customerId}`).valueChanges().pipe(map((customerDoc: Customer): Features => customerDoc.features));
      }
      return of({});
    }))
  }

  public isPublicRoute = (route:string): boolean => PUBLIC_ROUTES.filter((thisRoute: string): boolean => route.includes(thisRoute)).length > 0;

  // I'll leave this in for now, but it is unused ATM. Might need it in the future.
  // Points to a small service running in AIT domain to do a Reseller API lookup on a domain to get a customerID
  private getCustomerIdFromDomain(): Observable<CustomerIDLookupResponse> {
    // EDIT LINK FOR THIS SERVICE
    // https://script.google.com/home/projects/1zlSSWo6MaDzyF4X-cQ8NHSX-puDxgp4emeCw49VDahg6zXqpzDsvdEZF/edit
    const lookupURL: string =
      'https://script.google.com/macros/s/AKfycbyjnynAy3RNXgU2nSIk5alRFjsSTUYYX6YCbtOwy2xquj0Df2XLhsla6QC6VXQtz6_x/exec';
    return this.httpClient.get<CustomerIDLookupResponse>(lookupURL);
  }

  private oauthTokenDetails$ = (token: string): Observable<OAuthResponse> =>
    this.httpClient.get<OAuthResponse>(`${this.TOKEN_VERIFICATION_URL}${token}`);

  private handleTokenCheck(
    [ fbAuth, googleProvider ]: [Auth, GoogleAuthProvider],
    tokenCache$: BehaviorSubject<UserCredential>,
  ): Observable<string> {
    const signIn$ = () =>
      from(signInWithPopup(fbAuth, googleProvider)).pipe(
        tap((userCreds: UserCredential): void => tokenCache$.next(userCreds)),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        map((userCreds: UserCredential): string => userCreds._tokenResponse.oauthAccessToken),
      );
    return tokenCache$.pipe(
      tap((creds: UserCredential): void => {
        if (!creds) {
          throw new Error('token not set');
        }
      }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      switchMap(
        (creds: UserCredential): Observable<string> =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.oauthTokenDetails$(creds?._tokenResponse?.oauthAccessToken).pipe(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            map((): string => creds._tokenResponse.oauthAccessToken),
            catchError((): Observable<string> => signIn$()),
          ),
      ),
      catchError((): Observable<string> => signIn$()),
    );
  }

}
