import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';

import { ExportWizardModule } from '@cdw-ae/components-export-wizard';

import { AnimatedEllipsisComponent } from '../../core/components/animated-elipsis/animated-ellipsis.component';
import { MaterialModule } from '../../core/modules/material/material.module';
import { AnchorTargetPipe } from './anchor-target.pipe';
import { AdditionalInfoComponent } from './components/deviceInfo/additional-info/additional-info.component';
import { BasicInfoComponent } from './components/deviceInfo/basic-info/basic-info.component';
import { ChromeAuditEventsComponent } from './components/deviceInfo/chrome-audit-events/chrome-audit-events.component';
import { ConfirmDeprovisionDialogComponent } from './components/deviceInfo/confirm-deprovision-dialog/confirm-deprovision-dialog.component';
import { DeviceUsageComponent } from './components/deviceInfo/device-usage/device-usage.component';
import { EditBasicInfoDialogComponent } from './components/deviceInfo/edit-basic-info-dialog/edit-basic-info-dialog.component';
import { GbSessionDataComponent } from './components/deviceInfo/gb-session-data/gb-session-data.component';
import { TelemetryInfoComponent } from './components/deviceInfo/telemetry-info/telemetry-info.component';
import { ErrorSnackbarComponent } from './components/error-snackbar/error-snackbar.component';
import { ExportsButtonComponent } from './components/exports-button/exports-button.component';
import { IconSnackbarComponent } from './components/icon-snackbar/icon-snackbar.component';
import { LoadingComponent } from './components/loading/loading.component';
import { NotificationSnackbarComponent } from './components/notification-snackbar/notification-snackbar.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { CustomerService } from './services/customer.service';
import { GridExportsService } from './services/gridExports.service';
import { NumberUtils } from './services/numberUtils.service';
import { DateUtils } from './services/dateUtils.service';
import { RefreshCacheComponent } from './components/refresh-cache/refresh-cache.component';
import { JobStatusService } from './services/job-status.service';

@NgModule({
  imports: [ RouterModule, CommonModule, MaterialModule, MatFormFieldModule, MatInputModule, ExportWizardModule ],
  declarations: [
    AnimatedEllipsisComponent,
    ErrorSnackbarComponent,
    IconSnackbarComponent,
    NotificationSnackbarComponent,
    AnchorTargetPipe,
    BasicInfoComponent,
    AdditionalInfoComponent,
    TelemetryInfoComponent,
    GbSessionDataComponent,
    DeviceUsageComponent,
    EditBasicInfoDialogComponent,
    ChromeAuditEventsComponent,
    LoadingComponent,
    SafeHtmlPipe,
    ConfirmDeprovisionDialogComponent,
    RefreshCacheComponent,
    ExportsButtonComponent,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    AnimatedEllipsisComponent,
    ErrorSnackbarComponent,
    IconSnackbarComponent,
    NotificationSnackbarComponent,
    AnchorTargetPipe,
    BasicInfoComponent,
    AdditionalInfoComponent,
    TelemetryInfoComponent,
    GbSessionDataComponent,
    DeviceUsageComponent,
    MatFormFieldModule,
    MatInputModule,
    EditBasicInfoDialogComponent,
    ChromeAuditEventsComponent,
    LoadingComponent,
    SafeHtmlPipe,
    RefreshCacheComponent,
    ExportWizardModule,
    ExportsButtonComponent,
  ],
  providers: [ CustomerService, JobStatusService, DateUtils, GridExportsService, NumberUtils, ],
})
export class CoModule {}
