import { Injectable } from '@angular/core';

@Injectable()
export class NumberUtils {

  public formatNumber(num: number): string {
    if (num < 1000) {
      return `${num}`;
    }
    if (num < 1000000) {
      return `${this.round(num / 1000, 1)}k`;
    }
    if (num < 1000000000) {
      return `${this.round(num / 1000000, 1)}m`;
    }
    return `${this.round(num / 1000000000, 1)}b`;
  }

  public round(value: number, precision: number): number {
    const multiplier: number = 10 ** (precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  public numberToStringWithCommas(x: number): string {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  }

}
