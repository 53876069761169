import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LocalStorageService } from '../../../../../../core/Services/local-storage.service';
import { DatePipe } from '@angular/common';
import { AuthService } from '../../../../../../core/modules/auth/services/auth.service';
import { LicensingBaseDialogComponent } from '../base/licensing-base-dialog.component';
import {DialogData, License, LicenseState} from '../../../../services/licensing.interfaces';
import { LicensingRenewalDialogComponent } from '../../licensing-renewal-dialog.component';
import { CustomAnalyticEventsService } from '../../../../../../core/Services/customAnalyticEvents.service';
import {EntitlementLicensingService} from '../../../../services/entitlement-licensing.service';

@Component({
  selector: 'app-licensing-unlicensed-dialog',
  templateUrl: './licensing-unlicensed-dialog.component.html',
  styleUrls: [ '../base/licensing-base-dialog.component.scss' ]
})
export class LicensingUnlicensedDialogComponent extends LicensingBaseDialogComponent implements OnInit, OnDestroy {

  constructor( public _firebaseAnalytics: CustomAnalyticEventsService,
    public _authSvc: AuthService,
    public licensingService: EntitlementLicensingService,
    public localStorageService: LocalStorageService,
    public _datePipe: DatePipe)  {
    super(_firebaseAnalytics,  _authSvc, licensingService, localStorageService,  _datePipe);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.checkMuteStatus();
    this.buildTemplateData(this.data.licenseState);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private buildTemplateData(licenseState: LicenseState): void {

    this.dialogData = {
      domain : licenseState.license.domain,
      expireDays : licenseState.expiresIn,
      expireDate : this.getExpiryDate(licenseState),
      displayDate: true,
      showIcon: true,
      controls: {
        showSupportButton: false,
        showRenewButton: true,
        showReminderCheckbox: false,
        showRecheckLicenseButton: false,
        showLogout: false
      },
      displayInfoBlock: true,
      alertLevel: 2,
      tool: this.getTool(licenseState.primarySubscription),
    };
  }
}
