import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'animated-ellipsis',
  templateUrl: './animated-ellipsis.component.html',
  styleUrls: [ './animated-ellipsis.component.scss' ]
})

export class AnimatedEllipsisComponent implements OnInit {

  constructor() {}

  ngOnInit() {}

}
