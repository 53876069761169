import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LocalStorageService } from '../../../../../../core/Services/local-storage.service';
import { DatePipe } from '@angular/common';

import { AuthService } from '../../../../../../core/modules/auth/services/auth.service';
import { LicensingBaseDialogComponent } from '../base/licensing-base-dialog.component';
import { DialogData, License, LicenseState } from '../../../../services/licensing.interfaces';
import { LicensingRenewalDialogComponent } from '../../licensing-renewal-dialog.component';
import * as moment from 'moment';
import { CustomAnalyticEventsService } from '../../../../../../core/Services/customAnalyticEvents.service';
import { EntitlementLicensingService, bundleMap } from '../../../../services/entitlement-licensing.service';
import { Moment } from 'moment';

@Component({
  selector: 'app-licensing-lapsed-dialog',
  templateUrl: './licensing-lapsed-dialog.component.html',
  styleUrls: [ '../base/licensing-base-dialog.component.scss' ]
})
export class LicensingLapsedDialogComponent extends LicensingBaseDialogComponent implements OnInit, OnDestroy {
  public bundleMap: {[key:string]:string} = bundleMap; 


  constructor( public _firebaseAnalytics: CustomAnalyticEventsService,
    public _authSvc: AuthService,
    public licensingService: EntitlementLicensingService,
    public localStorageService: LocalStorageService,
    public _datePipe: DatePipe)  {
    super(_firebaseAnalytics,  _authSvc, licensingService, localStorageService,  _datePipe);
  }

  public forceLicenseRecheck(): void {
    this.recheckLicense();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.checkMuteStatus();
    this.buildTemplateData(this.data.licenseState);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }


  private buildTemplateData(licenseState: LicenseState): void {

    const tool: string = licenseState.primarySubscription

    this.dialogData = {
      domain: licenseState.license.domain,
      expireDays: licenseState.expiresIn,
      expireDate: this._datePipe.transform( licenseState.lastTierExpiration.date, 'MM/dd/yyyy'),
      renewalDate: this.getExpiryDate(licenseState),
      displayDate: true,
      controls: {
        showSupportButton: false,
        showRenewButton: true,
        showReminderCheckbox: false,
        showRecheckLicenseButton: true,
        showLogout: false
      },
      tool: this.getTool(tool),
      displayInfoBlock: true
    };
  }
}
