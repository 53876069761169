<ng-container *ngIf="deviceTelemetry | async as deviceTelemetry; else loading">
  <div class="telemetry-info-content flex flex-col gap-[30px]">
    <mat-card appearance="outlined">
      <mat-card-title>Resources</mat-card-title>
      <mat-card-content>
        <div class="flex flex-col gap-[30px]">
          <div class="flex flex-row">
            <div class="info-panel">
              <div class="header">CPU Model</div>
              <ng-container *ngIf="deviceTelemetry?.raw?.cpuInfo; else notSet">
                <div *ngFor="let cpuInfo of deviceTelemetry.raw.cpuInfo" class="value">{{ cpuInfo.model }}</div>
              </ng-container>
            </div>

            <div class="info-panel">
              <div class="header">CPU Utilization</div>
              <ng-container *ngIf="deviceTelemetry?.latestCpuUtilization as cpuUtilization; else notSet">
                <div class="value">{{ cpuUtilization.cpuUtilizationPct }}%</div>
              </ng-container>
            </div>
          </div>

          <div class="flex flex-row">
            <div class="info-panel">
              <div class="header">CPU Temp(s)</div>
              <ng-container *ngIf="deviceTelemetry?.latestCpuTemps as cpuTemps; else notSet">
                <div *ngFor="let tempInfo of cpuTemps.cpuTemperatureInfo" class="value">
                  {{ tempInfo.temperatureCelsius }}° ({{ tempInfo.label.trim() }})
                </div>
              </ng-container>
            </div>

            <div *ngIf="deviceTelemetry?.latestMemoryStatus as memoryStatus" class="flex flex-col gap-[30px]">
              <div class="info-panel">
                <div class="header">Memory</div>
                <div class="value">
                  {{ deviceTelemetry.ramUsagePct }}% ({{ deviceTelemetry.systemRamFreeBytesDisplay }} /
                  {{ deviceTelemetry.totalRamBytesDisplay }})
                </div>
              </div>

              <div class="info-panel">
                <div class="header">Storage</div>
                <div class="value">
                  {{ deviceTelemetry.diskUsagePct }}% ({{ deviceTelemetry.usedDiskBytesDisplay }} /
                  {{ deviceTelemetry.totalDiskBytesDisplay }})
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" *ngIf="deviceTelemetry?.latestNetworkStatus as networkStatus">
      <mat-card-title>Network Info</mat-card-title>
      <mat-card-content>
        <div class="flex flex-col gap-[30px]">
          <div class="flex flex-row">
            <div class="info-panel">
              <div class="header">Wi-Fi Signal Strength</div>
              <div class="value">{{ networkStatus.signalStrengthDbm }} dBm</div>
            </div>
  
            <div class="info-panel">
              <div class="header">IP Addresses</div>
              <div class="value">{{ networkStatus.lanIpAddress || '-' }} (LAN)</div>
              <div class="value">{{ networkStatus.gatewayIpAddress || '-' }} (Gateway)</div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined">
      <mat-card-title>Battery Info</mat-card-title>
      <mat-card-content>
        <div class="flex flex-col gap-[30px]">
          <div class="flex flex-row">
            <div class="info-panel">
              <div class="header">Type</div>
              <ng-container *ngIf="deviceTelemetry?.raw?.batteryInfo; else notSet">
                <div *ngFor="let info of deviceTelemetry.raw.batteryInfo" class="value">{{ info.technology }}</div>
              </ng-container>
            </div>
            <div class="info-panel">
              <div class="header">Health</div>
              <div class="value">{{ deviceTelemetry.batteryHealth || '-' }}</div>
            </div>
          </div>
  
          <div class="flex flex-row">
            <div class="info-panel">
              <div class="header">Manufacturer</div>
              <ng-container *ngIf="deviceTelemetry?.raw?.batteryInfo; else notSet">
                <div *ngFor="let info of deviceTelemetry.raw.batteryInfo" class="value">{{ info.manufacturer }} dBm</div>
              </ng-container>
            </div>
            <div class="info-panel">
              <div class="header">Status</div>
              <ng-container *ngIf="deviceTelemetry?.raw?.batteryStatusReport; else notSet">
                <div *ngFor="let info of deviceTelemetry.raw.batteryStatusReport" class="value">
                  {{ info?.sample[0].status }}
                </div>
              </ng-container>
            </div>
          </div>
          <div class="flex flex-row">
            <div class="info-panel">
              <div class="header">Serial Number</div>
              <ng-container *ngIf="deviceTelemetry?.raw?.batteryInfo; else notSet">
                <div *ngFor="let info of deviceTelemetry.raw.batteryInfo" class="value">{{ info.serialNumber }}</div>
              </ng-container>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined">
      <mat-card-title>Graphics Info</mat-card-title>
      <mat-card-content>
        <div class="flex flex-col gap-[30px]">
          <div class="flex flex-row">
            <div class="info-panel">
              <div class="header">Adapter</div>
              <div class="value">{{ deviceTelemetry?.raw?.graphicsInfo?.adapterInfo?.adapter || '-' }}</div>
            </div>
          </div>
          <div class="flex flex-row">
            <div class="info-panel">
              <div class="header">Displays</div>
              <ng-container *ngIf="deviceTelemetry?.raw?.graphicsStatusReport; else notSet">
                <div *ngFor="let info of deviceTelemetry.raw.graphicsStatusReport" class="value">
                  <div *ngFor="let display of info.displays">
                    {{ display.resolutionWidth }} x {{ display.resolutionHeight }} ({{
                      display.isInternal ? 'Internal' : 'External'
                    }})
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>

<ng-template #loading>
  <div class="loading-panel flex justify-center items-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #notSet>
  <div class="missing-setting">-</div>
</ng-template>
