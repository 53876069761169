import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { catchError, map, Observable, of, startWith, Subject, switchMap, take, takeUntil } from 'rxjs';
import { DeviceInfoService } from 'src/app/modules/device/services/device-info/device-info.service';

import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import {
  BasicResponse,
  DeprovisionReason,
  DeviceActionState,
} from 'src/app/modules/device/interfaces/deviceInfo.interfaces';
import { CustomAnalyticEventsService } from '../../../../../core/Services/customAnalyticEvents.service';
import { StatusService } from '../../../../status/services/status.service';
import { IOrganizationUnit } from '../../../models/organizational-unit.model';
import { IUpdateDeviceInfo, IUpdateDeviceInfoResponse } from '../../../models/update-device-info.model';
import { CustomerService } from '../../../services/customer.service';
import { ErrorSnackbarComponent } from '../../error-snackbar/error-snackbar.component';
import { NotificationSnackbarComponent } from '../../notification-snackbar/notification-snackbar.component';
import { ConfirmDeprovisionDialogComponent } from '../confirm-deprovision-dialog/confirm-deprovision-dialog.component';

@Component({
  selector: 'g4c-edit-basic-info-dialog',
  templateUrl: './edit-basic-info-dialog.component.html',
  styleUrls: [ './edit-basic-info-dialog.component.scss' ],
})
export class EditBasicInfoDialogComponent implements OnInit, OnDestroy {
  public editForm = new FormGroup({
    newStatus: new FormControl(''),
    deprovisionReason: new FormControl(''),
    annotatedAssetId: new FormControl(''),
    annotatedUser: new FormControl(''),
    orgUnitPath: new FormControl(''),
    annotatedLocation: new FormControl(''),
    notes: new FormControl(''),
  });

  public loadingOrgUnits: boolean = false;
  public buttonsDisabled: boolean = false;
  public orgUnits: IOrganizationUnit[];
  public filteredOrgUnits$: Observable<IOrganizationUnit[]>;

  public actionToStateLookup: any = {
    'disable': 'DISABLED',
    'reenable': 'ACTIVE',
    'deprovision': 'DEPROVISIONED',
    'pre_provisioned_disable': 'DISABLED', 
    'pre_provisioned_reenable': 'PRE_PROVISIONED' // NO CASE FOR THIS IN SELECTED DEVICE
  }
  public statusTransitions: any = {
    ACTIVE: [
      { value: 'disable', label: 'Disable' },
      { value: 'deprovision', label: 'Deprovision' },
    ],
    DELINQUENT: [],
    PRE_PROVISIONED: [
      { value: 'pre_provisioned_disable', label: 'Disable Zero-Touch enrollment' },
      { value: 'pre_provisioned_reenable', label: 'Enable Zero-Touch enrollment' },
      { value: 'deprovision', label: 'Deprovision' },
    ],
    DEPROVISIONED: [],
    DISABLED: [
      { value: 'reenable', label: 'Reenable' },
      { value: 'deprovision', label: 'Deprovision' },
    ],
    INACTIVE: [],
    RETURN_ARRIVED: [],
    RETURN_REQUESTED: [],
    UNKNOWN: [],
  };

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private deviceInfoService: DeviceInfoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditBasicInfoDialogComponent>,
    private snackBar: MatSnackBar,
    private customerService: CustomerService,
    public analytics: AngularFireAnalytics,
    private customAnalyticEvents: CustomAnalyticEventsService,
    public statusService: StatusService,
    private matDialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.setupForm();

    this.editForm.controls.newStatus.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((newStatus: string): void => {
        if (newStatus === 'deprovision') {
          const dialogRef: MatDialogRef<ConfirmDeprovisionDialogComponent, string | null> = this.matDialog.open(
            ConfirmDeprovisionDialogComponent,
            {
              panelClass: 'reset-gb-customer-modal',
              width: '650px',
              height: '400px',
            }
          );

          dialogRef.afterClosed().subscribe((deprovisionReason: string | null): void => {
            if (!deprovisionReason) {
              this.editForm.controls.newStatus.setValue('');
            }
            this.editForm.controls.deprovisionReason.setValue(deprovisionReason);
          });
        } else {
          this.editForm.controls.deprovisionReason.setValue(null);
        }
      });

    if (this.customerService.customerOUs$.value === null) {
      this.loadingOrgUnits = true;
      this.customerService
        .getAllCustomerOUs()
        .pipe(take(1))
        .subscribe((ous) => {
          this.orgUnits = ous.orgUnits;
          this.filterOrgUnits();
          this.loadingOrgUnits = false;
        });
    } else {
      this.orgUnits = this.customerService.customerOUs$.value;
      this.filterOrgUnits();
    }
  }

  public inOrgUnit(input: string): void {
    const result = this.orgUnits.findIndex((element) => element.orgUnitPath == input);
    if (result == -1) {
      this.editForm.controls.orgUnitPath.setErrors({ validOrgUnit: true });
    } else {
      this.editForm.controls.orgUnitPath.setErrors(null);
    }
  }

  public ngOnDestroy(): void {
    // clear out the edited device
    this.onDestroy$.next(null);
    this.deviceInfoService.editDevice$.next(null);
  }

  public cancelEdit(): void {
    this.dialogRef.close();
    this.customAnalyticEvents.logEvent('Close Edit Device', this.statusService?.userAuth?.getLogEventProperties());
  }

  public setupForm(): void {
    this.editForm.setValue({
      newStatus: null,
      deprovisionReason: this.data?.deprovisionReason,
      annotatedAssetId: this.data.annotatedAssetId,
      annotatedUser: this.data?.annotatedUser,
      orgUnitPath: this.data.orgUnitPath,
      annotatedLocation: this.data.annotatedLocation,
      notes: this.data.notes,
    });
  }

  public validateNotesLength(text: string): number {
    return text.length;
  }

  public async saveEdit(): Promise<void> {
    if (this.editForm.dirty) {
      const newAnnotatedAssetId: string = this.editForm.controls.annotatedAssetId.getRawValue();
      const newAnnotatedUser: string = this.editForm.controls.annotatedUser.getRawValue();
      const newAnnotatedLocation: string = this.editForm.controls.annotatedLocation.getRawValue();
      const newNotes: string = this.editForm.controls.notes.getRawValue();
      const newOrgUnitPath: string = this.editForm.controls.orgUnitPath.getRawValue();
      const newStatus: string = this.editForm.controls.newStatus.getRawValue();
      const deprovisionReason: string = this.editForm.controls.deprovisionReason.getRawValue();

      const newUpdateDeviceInfo: IUpdateDeviceInfo = {
        deviceId: this.data.deviceId,
        annotatedAssetId: newAnnotatedAssetId,
        annotatedUser: newAnnotatedUser,
        orgUnitPath: newOrgUnitPath,
        annotatedLocation: newAnnotatedLocation,
        notes: newNotes,
        status: newStatus,
        deprovisonReason: deprovisionReason,
      };

      this.buttonsDisabled = true;

      this.customAnalyticEvents.logEvent('Save Device', this.statusService?.userAuth?.getLogEventProperties());

      this.deviceInfoService
        .updateBasicDeviceInfo(newUpdateDeviceInfo)
        .pipe(
          take(1),
          switchMap((updateResponse: IUpdateDeviceInfoResponse): Observable<IUpdateDeviceInfoResponse> => {
            if (this.editForm.controls.newStatus) {
              return this.deviceInfoService
                .setDeviceState(
                  newUpdateDeviceInfo.deviceId,
                  newUpdateDeviceInfo.status as DeviceActionState,
                  newUpdateDeviceInfo.deprovisonReason as DeprovisionReason
                )
                .pipe(
                  map((response: BasicResponse): IUpdateDeviceInfoResponse => {
                    if (response.success) {
                      updateResponse.deviceInfo.status = newUpdateDeviceInfo.status in this.actionToStateLookup 
                      ? this.actionToStateLookup[newUpdateDeviceInfo.status] 
                      : 'UNKNOWN';
                    }
                    return updateResponse;
                  })
                );
            }
            return of(updateResponse);
          }),
          catchError((error: Error): Observable<object> => {
            console.error(error.message);
            this.snackBar.openFromComponent(ErrorSnackbarComponent, {
              data: 'An error occurred trying to update the device',
            });
            this.customAnalyticEvents.logEvent(
              'Device Save Error',
              this.statusService?.userAuth?.getLogEventProperties()
            );
            return of({ success: false });
          })
        )
        .subscribe((res: IUpdateDeviceInfoResponse): void => {
          this.buttonsDisabled = false;
          if (res.success) {
            const wrapper: object = {
              deviceLookup: res.deviceInfo,
            };
            this.dialogRef.close(wrapper);
            this.snackBar.openFromComponent(NotificationSnackbarComponent, {
              data: { message: 'Device updated successfully', count: 1, index: 0, className: 'success' },
            });
            this.customAnalyticEvents.logEvent('Device Saved', this.statusService?.userAuth?.getLogEventProperties());
          }
        });
    }
  }

  private filterOrgUnits(): void {
    this.filteredOrgUnits$ = this.editForm.controls.orgUnitPath.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
  }

  private _filter(value: string): IOrganizationUnit[] {
    const filterValue: string = value.toLowerCase();
    return this.orgUnits.filter((option) => option.orgUnitPath.toLowerCase().includes(filterValue));
  }
}
