import {
  Component,
  Inject
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../modules/auth/services/auth.service';

@Component({
  selector: 'g4c-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: [ './error-dialog.component.scss' ]
})
export class ErrorDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { error: { title: string; body: string }, disableCloseBtn: boolean, enableLogoutBtn: boolean },
    private authService: AuthService,
  ) {}


  public async logout(): Promise<void> {
    await this.authService.logout();

  }
}
