
<div class="flex mr-8 justify-between min-w-85">
  <span class="refresh-cache-message">Device cache last updated: {{ lastUpdated$ | async | date: 'short' }}</span>
  <span [matTooltip]="isAdmin? null : 'Cache refresh is only available to super admins.' ">
  <button
    *ngIf="!refreshCacheDisabled && !refreshCacheRunning"
    class="refresh-cache-button"
    mat-stroked-button
    (click)="refreshCache()"
    [disabled]="!isAdmin"
    [ngClass]="{'refresh-cache-button-disabled': !isAdmin}"
  >
    Refresh cache
  </button>
</span>
  <div
    class="flex flex-1 h-10"
    *ngIf="refreshCacheRunning"
    [matTooltip]="refreshCacheTooltip + (deviceCount | number: '1.0-0') + ' devices cached'"
    class="column icon"
  >
    <div class="flex" class="spinner-container">
      <mat-icon class="refreshing-icon">computer</mat-icon>
      <mat-spinner class="refreshing-spinner" *ngIf="refreshCacheRunning" diameter="40"></mat-spinner>
    </div>
    <span class="cache-count">{{ deviceCount | number: '1.0-0' }}</span>
  </div>
</div>
