import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/modules/auth/services/auth.service';
  
  import { Router } from '@angular/router';
import {
  from,
  map,
  Observable,
  switchMap,
} from 'rxjs';
  
  @Injectable()
  export class FirebaseAuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private router: Router) {}
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let { headers }: HttpRequest<any> = request;
      if (this.authService.isPublicRoute(this.router.url) ) {
        return next.handle(request);
      }
      return from(this.authService.getCurrentUserIdToken()).pipe(
        map((idToken: string): HttpRequest<any> => {
          headers = headers.append('Authorization', `Bearer ${idToken}`);
          headers = headers.append('x-cdw-fbauth-token', `Bearer ${idToken}`);
          // CG-2791 TODO - 'G4C' is hard coded, this should probably be exported from the monorepo
            headers = headers.set('x-cdw-app-name','G4C');
          return request.clone({ headers })
        }),
        switchMap((modifiedRequest: HttpRequest<any>): Observable<HttpEvent<any>> => next.handle(modifiedRequest) ));
    }
  }
  