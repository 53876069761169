<div class="flex basis-full h-full">
  <div class="flex flex-row">
    <ng-container *ngIf="license$ | async as license">
      <ng-container *ngIf="license.licenseState.loaded">
        <div *ngIf="license.licenseState.details === 'EXPIRED'" class="flex flex-row justify-center items-center">
          <div [ngClass]="{'alert-red': alertLevel === 2}" class="flex justify-center items-center flex-row gap-[6px]">
            <mat-icon class="icon-20"
                      [ngClass]="{'alert-amber': iconColor === 'amber', 'alert-red': iconColor === 'red'}">warning
            </mat-icon>
            <p>Gopher for Chrome license for <strong>{{license.licenseState.license.domain}}</strong> expired
              on {{getExpiryDate(license.licenseState)}}.</p>
            <button mat-flat-button (click)="openLicensingRenewalDialog()">Renew</button>
          </div>
        </div>
        <div *ngIf="license.licenseState.details === 'ACTIVE'" class="flex flex-row justify-center items-center gap-[8px]">
          <div *ngIf="iconColor !== 'none'" class="flex justify-center items-center flex-row">
            <mat-icon class="icon-20"
                      [ngClass]="{'alert-amber': iconColor === 'amber', 'alert-red': iconColor === 'red'}">warning
            </mat-icon>
          </div>
          <div class="flex justify-start items-center flex-row gap-[6px]">
            <div *ngIf="license.isSuperAdmin; else standardUser" [ngClass]="{'alert-red': alertLevel === 2}" class="flex flex-row gap-[6px] justify-start items-center">
              <div *ngIf="!showRenew; else renewPanel ">
                <p class="renew-link" (click)="openLicensingRenewalDialog()">
                  {{expiryMessage}}
                </p>
              </div>
              <ng-template #renewPanel>
                <p>{{expiryMessage}}</p>
                <button *ngIf="license.isSuperAdmin && showRenew" (click)="openLicensingRenewalDialog()" mat-flat-button>Renew
                </button>
              </ng-template>
            </div>
            <ng-template #standardUser>{{expiryMessage}}</ng-template>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <app-licensing-tester *ngIf="showLicenseTestPanel"></app-licensing-tester>
  </div>
</div>
