import {
  Component,
  ComponentRef,
  Inject, NgZone,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../../environments/environment';
import { LocalStorageService } from '../../../../core/Services/local-storage.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import { DatePipe } from '@angular/common';
import { AuthService } from '../../../../core/modules/auth/services/auth.service';
import { LicensingPaidDialogComponent } from './tiers/paid/licensing-paid-dialog.component';
import { LicensingTierDirective } from './directive/licensing-tier.directive';
import { LicensingLapsedDialogComponent } from './tiers/lapsed/licensing-lapsed-dialog.component';
import { LicensingFreeDialogComponent } from './tiers/free/licensing-free-dialog.component';
import { LicensingConsultantDialogComponent } from './tiers/consultant/licensing-consultant-dialog.component';
import { LicensingUnlicensedDialogComponent } from './tiers/unlicensed/licensing-unlicensed-dialog.component';
import { LicensingTrialDialogComponent } from './tiers/trial/licensing-trial-dialog.component';
import { CustomAnalyticEventsService } from '../../../../core/Services/customAnalyticEvents.service';
import { EntitlementLicensingService } from '../../services/entitlement-licensing.service';
import { LicenseState } from '../../services/licensing.interfaces';
import {ToolOptions} from './tiers/base/licensing-base-dialog.component';


@Component({
  selector: 'app-licensing-renewal-dialog',
  templateUrl: './licensing-renewal-dialog.component.html',
  styleUrls: [ './licensing-renewal-dialog.component.css' ]
})
export class LicensingRenewalDialogComponent implements OnInit, OnDestroy {


  @ViewChild(LicensingTierDirective, { static: true })
  public licenseTierInsert: LicensingTierDirective;

  private tiers: any = {
    paid: LicensingPaidDialogComponent,
    lapsed: LicensingLapsedDialogComponent,
    free: LicensingFreeDialogComponent,
    consultant: LicensingConsultantDialogComponent,
    unlicensed: LicensingUnlicensedDialogComponent,
    trial: LicensingTrialDialogComponent
  };

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor( public dialogRef: MatDialogRef<LicensingRenewalDialogComponent>,
               private licensingService: EntitlementLicensingService,
               @Inject(MAT_DIALOG_DATA) public data: any)  {
    dialogRef.disableClose = true;
  }

  public ngOnInit(): void {
      this.licensingService.checkLicense().pipe(
        takeUntil(this.onDestroy$),
        filter((license: LicenseState): boolean => license.loaded),
      ).subscribe((license: LicenseState): void => {
        const tierComponent: Type<any> = this.tiers[license.tier];
        const { viewContainerRef }: { viewContainerRef: ViewContainerRef } = this.licenseTierInsert;
        viewContainerRef.clear();
        const componentRef: ComponentRef<any> = viewContainerRef.createComponent(tierComponent);
        componentRef.instance.data = {
          licenseState: license,
          dialogRef: this.dialogRef
        };
      })
  }
  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

}
