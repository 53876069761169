import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/core/components/confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';
import { LogEventProperties } from '../../services/auth.interfaces';
import { CustomAnalyticEventsService } from 'src/app/core/Services/customAnalyticEvents.service';
import { ErrorDialogComponent } from 'src/app/core/error/error-dialog.component';

declare let google: any;

@Component({
  selector: 'g4c-offline-token-auth-dialog',
  templateUrl: './offline-token-auth-dialog.component.html',
  styleUrls: [ './offline-token-auth-dialog.component.scss' ],
})
export class OfflineTokenAuthDialogComponent extends ConfirmationDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<OfflineTokenAuthDialogComponent>,
    private errorDialog: MatDialog,
    private ngZone: NgZone,
    private authService: AuthService,
    private analytics: CustomAnalyticEventsService,
    @Inject(MAT_DIALOG_DATA)
    public data: { message: string; logEventProperties: LogEventProperties; additionalScopes: string[] },
  ) {
    super(dialogRef, data);
  }

  public login(): void {
    const desiredScopes: string[] = this.data?.additionalScopes?.length
      ? environment.oauthScopes.requiredScopes.concat(this.data.additionalScopes)
      : environment.oauthScopes.requiredScopes;
    const config: Record<string, any> = {
      client_id: environment.google.gapi_clientId,
      scope: desiredScopes.join(' '),
      ux_mode: 'popup',
      access_type: 'offline',
      callback: (credential: { scope: string; code: string }): void => {
        this.ngZone.run((): void => {
          const missingScopes: string[] = this.validateScopes(desiredScopes, credential?.scope?.split(' ') ?? []);
          if (missingScopes.length > 0) {
            // todo telemetry
            // if we have not authorized all scopes from login popup, then we display an error dialog
            this.errorDialog.open(ErrorDialogComponent, {
              data: {
                error: {
                  title: 'All scopes are required',
                  body: `<div>The following scopes were not authorized:</div>
                <div>
                <ul>
                <li>
                   ${missingScopes.join('</li><li>')}
                </li>
                </ul>
                </div>
                 `,
                },
              },
            });
            return;
          }
          this.returnGoogleAuthCode(credential.code).catch((error: Error): void => console.error(error.message));
        });
      },
    };

    const gAccounts = google.accounts.oauth2.initCodeClient(config);
    gAccounts.requestCode();
  }

  public async logOut(): Promise<void> {
    await this.authService.logout();
    this.dialogRef.close();
  }

  /**
   *
   * @param expectedScopes array of OAuth Scopes required
   * @param foundScopes array of OAuth found on a token
   * @return {array} Array of scopes missing from the given token
   */
  private validateScopes(expectedScopes, foundScopes) {
    return expectedScopes.reduce((acc, curr) => {
      if (foundScopes.filter((item) => item === curr).length > 0) return acc;
      acc.push(curr);
      return acc;
    }, []);
  }



  private async returnGoogleAuthCode(authCode: string): Promise<void> {
    this.dialogRef.close(authCode);
  }

}
