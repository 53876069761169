import {
  Component,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

interface NotificationSnackbarData {
  message: string;
  count: number;
  index: number;
  className?: string;
}

@Component({
  selector: 'app-notification-snackbar',
  templateUrl: 'notification-snackbar.component.html',
  styleUrls: [ 'notification-snackbar.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class NotificationSnackbarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<NotificationSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: NotificationSnackbarData) {
  }
}
