
<ng-container *ngIf="selectedDevice else loading">
    <div class="additional-info-content">

      <mat-card appearance="outlined" *ngIf="selectedDevice?.deviceLookup?.activeTimeRanges || selectedDevice?.deviceLookup?.recentUsers">
          <mat-card-content >
            <div class="flex flex-col gap-[30px]">
              <div class="flex flex-row gap-[32px] justify-start">
                <div *ngIf="selectedDevice?.deviceLookup?.activeTimeRanges?.length > 0" class="flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)] gap-[14px]">
                    <mat-card-title>Active Times*</mat-card-title>
                    <div class="table-container">
                      <table [dataSource]="selectedDevice.deviceLookup.activeTimeRanges" mat-table>
                        <tr *matHeaderRowDef="activeTimeColumns; sticky: true" mat-header-row></tr>
                        <tr *matRowDef="let row; columns: activeTimeColumns;" mat-row></tr>
                        <ng-container matColumnDef="date">
                            <th *matHeaderCellDef mat-header-cell>Date</th>
                            <td *matCellDef="let element" mat-cell>{{validateDate(element.date) ? (element.date | date: 'short') : '-'}}</td>
                        </ng-container>
                        <ng-container matColumnDef="activeTime">
                            <th *matHeaderCellDef mat-header-cell>Active Time (mins)</th>
                            <td *matCellDef="let element" mat-cell>{{getActiveTime(element.activeTime)}}</td>
                        </ng-container>
                      </table>
                    </div>
                </div>

                <div *ngIf="selectedDevice?.deviceLookup?.recentUsers?.length > 0" class="flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)] gap-[14px]">
                    <mat-card-title>Most Recent Users*</mat-card-title>
                    <div class="table-container">
                      <table [dataSource]="selectedDevice.deviceLookup.recentUsers" mat-table>
                          <tr *matHeaderRowDef="recentUserColumns; sticky: true" mat-header-row></tr>
                          <tr *matRowDef="let row; columns: recentUserColumns;" mat-row></tr>

                          <ng-container matColumnDef="type">
                              <th *matHeaderCellDef mat-header-cell>Usage order</th>
                              <td *matCellDef="let element; let i = index;" mat-cell>{{i + 1}}</td>
                          </ng-container>
                          <ng-container matColumnDef="email">
                              <th *matHeaderCellDef mat-header-cell>Email</th>
                              <td *matCellDef="let element" mat-cell>{{element.email || 'Unmanaged user'}}</td>
                          </ng-container>
                      </table>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedDevice?.deviceLookup?.activeTimeRanges?.length > 0" class="flex flex-row">
                <span>* reported from Workspace Admin Console</span>
            </div>
            </div>
              
          </mat-card-content>
      </mat-card>

      <mat-card appearance="outlined">
        <mat-card-content>
          <div class="flex flex-col gap-[14px]">
            <div class="flex flex-row gap-[32px] justify-start">

              <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
                <div class="header">Manufacture Date</div>
                <div *ngIf="selectedDevice?.deviceLookup?.manufactureDate; else notSet" class="value">{{validateDate(selectedDevice.deviceLookup.manufactureDate) ? (selectedDevice.deviceLookup.manufactureDate | date: 'short') : '-'}}</div>
              </div>
  
              <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
                <div class="header">First Enrollment Time</div>
                <div *ngIf="selectedDevice?.deviceLookup?.firstEnrollmentTime; else notSet" class="value">{{validateDate(selectedDevice.deviceLookup.firstEnrollmentTime) ? (selectedDevice.deviceLookup.firstEnrollmentTime | date: 'short') : '-'}}</div>
              </div>
  
              <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
                <div class="header">Last Enrollment Time</div>
                <div *ngIf="selectedDevice?.deviceLookup?.lastEnrollmentTime else notSet" class="value">{{validateDate(selectedDevice.deviceLookup.lastEnrollmentTime) ? (selectedDevice.deviceLookup.lastEnrollmentTime | date: 'short') : '-'}}</div>
              </div>
  
              
              
              
              
  
            </div>
  
            <div class="flex flex-row gap-[32px] justify-start">
  
              <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
                <div class="header">Last Reboot Time</div>
                <div *ngIf="selectedDevice?.deviceLookup?.osUpdateStatus?.rebootTime else notSet" class="value">{{validateDate(selectedDevice.deviceLookup.osUpdateStatus.rebootTime) ? (selectedDevice.deviceLookup.osUpdateStatus.rebootTime | date: 'short') : '-'}}</div>
              </div>
  
              <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
                <div class="header">Last OS Update</div>
                <div *ngIf="selectedDevice?.deviceLookup?.osUpdateStatus?.updateTime else notSet" class="value">{{validateDate(selectedDevice.deviceLookup.osUpdateStatus.updateTime) ? (selectedDevice.deviceLookup.osUpdateStatus.updateTime | date: 'short') : '-'}}</div>
              </div>
  
              <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
                <div class="header">Last OS Update Check</div>
                <div *ngIf="selectedDevice?.deviceLookup?.osUpdateStatus?.updateCheckTime else notSet" class="value">{{validateDate(selectedDevice.deviceLookup.osUpdateStatus.updateCheckTime) ? (selectedDevice.deviceLookup.osUpdateStatus.updateCheckTime | date: 'short') : '-'}}</div>
              </div>
  
            </div>
  
            <div class="flex flex-row gap-[32px] justify-start">
  
              <div class="info-property flex flex-col flex-grow-0 flex-shrink-1 basis-[calc(33.3%-32px)]">
                <div class="header">Last Sync</div>
                <div *ngIf="selectedDevice?.deviceLookup?.lastSync else notSet" class="value">{{validateDate(selectedDevice.deviceLookup.lastSync) ? (selectedDevice?.deviceLookup.lastSync | date: 'short') : '-'}}</div>
              </div>
  
            </div>
          </div>
          
        </mat-card-content>
      </mat-card>

    </div>
</ng-container>

<ng-template #notSet>
    <div class="missing-setting">-</div>
  </ng-template>

<ng-template #loading>
    <div class="loading-panel flex justify-center items-center">
      <mat-spinner></mat-spinner>
    </div>
</ng-template>
