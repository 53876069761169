<div class="gap-[24px] flex flex-col justify-start items-center" mat-dialog-content>

  <img src="{{dialogData.tool.logo}}" alt="{{dialogData.tool.name}} Logo" height="120px">

  <div class="alert-strong alert-red flex flex-col justify-center items-center gap-[12px]">
    <p> The {{dialogData.tool.name}} license for {{dialogData.domain}} expired on {{dialogData?.expireDate}}<span *ngIf="data?.licenseState.bundle"> as part of your {{bundleMap[data?.licenseState.bundle]}} subscription</span>.</p>
    <p>
    If the license is not renewed by {{dialogData.renewalDate}} it will move to a FREE Gopher for Chrome license.
      <a href="{{learnMoreLink}}" target="_blank">LEARN MORE</a>
    </p>
  </div>

  <div class="license-info-block flex flex-col justify-start items-center">
    <p>
      ** Please note, after 90 days from the license expiration date, device cache data will be deleted.
      If you would like to delete your data sooner, please contact support.
    </p>
    <div class="flex flex-row justify-center items-center">
      <button class="renew-button" [ngClass]="{disabled: !(isAdmin$ | async)}" [disabled]="!(isAdmin$ | async)" mat-raised-button (click)="requestSupport(dialogData.domain)">
        <span [matTooltip]="!(isAdmin$ | async)?'This is only available to Super admins':null">CONTACT SUPPORT</span>
      </button>
    </div>
  </div>

  <p><strong>With your license expired, you have lost access to the full feature set of {{dialogData.tool.name}} and will only be able to perform a limited number of actions.</strong></p>

</div>

<div mat-dialog-actions class="flex justify-end items-center">
  <button class="renew-button"  [ngClass]="{disabled: !(isAdmin$ | async)}" [disabled]="!(isAdmin$ | async)" mat-raised-button (click)="forceLicenseRecheck()">
    <span [matTooltip]="!(isAdmin$ | async)?'This is only available to Super admins':null">RE-CHECK LICENSE</span>
  </button>
  <button class="renew-button"  [ngClass]="{disabled: !(isAdmin$ | async)}" [disabled]="!(isAdmin$ | async)" mat-raised-button (click)="requestRenewal()">
    <span [matTooltip]="!(isAdmin$ | async)?'This is only available to Super admins':null">START YOUR RENEWAL</span>
  </button>
  <button mat-raised-button class="grey-button" (click)="closeDialog()">{{muteNotification ? 'SAVE & CLOSE' : 'CLOSE'}}</button>
</div>
