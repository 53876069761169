import { Component, OnDestroy, OnInit } from '@angular/core';
import { License, LicenseState } from '../../services/licensing.interfaces';
import { LocalStorageService } from '../../../../core/Services/local-storage.service';
import { EntitlementLicensingService } from '../../services/entitlement-licensing.service';
import { Subject } from 'rxjs';
import {filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-licensing-tester',
  templateUrl: './licensing-tester.component.html',
  styleUrls: [ './licensing-tester.component.scss' ]
})
export class LicensingTesterComponent implements OnInit, OnDestroy {

  public license: License;
  public state: LicenseState;
  public initialised: boolean = false;
  public overrideActive: boolean = false;
  public tool: 'CG' | 'CGP' = 'CG';

  public RENEW_DIALOG_KEY_30DAYS: string = 'mute_renew_dialog_30DAYS';
  public RENEW_DIALOG_KEY_14DAYS: string = 'mute_renew_dialog_14DAYS';
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private _licensingSvc: EntitlementLicensingService,
    public localStorageService: LocalStorageService) {

    this._licensingSvc.getOverrideStatus()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((status: boolean): void => {
      this.overrideActive = status;
    });
  }

  public ngOnInit(): void {
    this._licensingSvc.checkLicense()
      .pipe(
        takeUntil(this.onDestroy$),
        filter((licenseState: LicenseState): boolean => !!licenseState.license)
      ).subscribe((licenseState: LicenseState): void => {
      if (licenseState.license.domain) {
        this.license = licenseState.license;
        this.state = licenseState;
        this.initialised = true;
      }
    })
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  public handleExpireOverride(expireDate: Date): void  {
    this._licensingSvc.setLocalOverride(this.tool, this.license.subscriptions[this.tool].plan.name, expireDate)
  }

  public handleTierOverride(tierName: string):void {
    this._licensingSvc.setLocalOverride(this.tool, tierName, new Date(this.license.subscriptions[this.tool].expiry))
  }

  public handleToolOverride(tool: 'CG' | 'CGP'): void {
    this._licensingSvc.setLocalOverride(tool, this.license.subscriptions[this.tool].plan.name, new Date(this.license.subscriptions[this.tool].expiry))
  }

  public removeLocalOverride(): void {
    this._licensingSvc.removeLocalOverride();
    this.tool = 'CG';
    this.localStorageService.remove(this.RENEW_DIALOG_KEY_14DAYS);
    this.localStorageService.remove(this.RENEW_DIALOG_KEY_30DAYS);
  }
}
