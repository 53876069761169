import { Component, Input, OnInit } from '@angular/core';

import { AgGridState } from '@cdw-ae/angular-ui-cdw-ag-grid';
import { G4CContentType } from '@cdw-ae/components-export-wizard';

import { GridExportsService } from '../../services/gridExports.service';

@Component({
  selector: 'g4c-exports-button',
  templateUrl: './exports-button.component.html',
  // styleUrls: ['./exports-button.component.scss'],
})
export class ExportsButtonComponent implements OnInit {
  @Input() private type!: G4CContentType;
  
  @Input() private gridState!: AgGridState;

  constructor(private exportsService: GridExportsService) {}

  public ngOnInit(): void {}

  protected async handleClick(): Promise<void> {
    await this.exportsService.showExportDialog(this.type, this.gridState);
  }
}